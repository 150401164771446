import React, { useState } from "react";
import Logo from "../assets/images/logo.svg";
import Menu from "../assets/icons/menu.svg";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import AppBtn from "../components/AppBtn/AppBtn";
import Close from "../assets/icons/close.svg";

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const closeModal = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div>
        {/* Navbar starts here */}
        <nav className="flex bg-black justify-between max-w-[1700px] mx-auto  item-center md:px-14 px-8 py-9 ">
          <div className="flex">
            <div className="flex item-center z-[3000] md:mr-10">
              {/* Logo Here */}
              <Link to={"/"} className="flex">
                <img src={Logo} alt="" className="logo" />
              </Link>
            </div>

            <div
              className="md:flex item-center gap-x-8 hidden"
              style={{ alignItems: "center" }}
            >
              {/* <Link to={"/learn-with-omayoza"} className="btn-link">
                Learn with us
              </Link> */}
              <Link to={"/about"} className="btn-link">
                About us
              </Link>
              <Link to={"/quests"} className="btn-link">
                Quests
              </Link>
            </div>
          </div>

          <div className="flex flex-row z-[3000]">
            <Link to={"/login"}>
              <AppBtn title="Log in" className="btn-secondary " />
            </Link>

            <Link to={"/signup"}>
              <AppBtn title="Sign Up" className="mobile-btn ml-1" />
            </Link>
          </div>
        </nav>

        {toggle ? (
          <button
            className="ml-6 humbugerMenu md:hidden z-[8000] "
            onClick={() => closeModal()}
          >
            <img src={Close} alt="" width={20} height={20} />
          </button>
        ) : (
          <button
            className="ml-6 humbugerMenu md:hidden z-[8000] "
            onClick={() => setToggle(true)}
          >
            <img src={Menu} alt="" width={30} height={30} />
          </button>
        )}
      </div>

      <Navbar toggle={toggle} closeModal={closeModal} />
    </>
  );
};

export default Header;
