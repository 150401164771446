import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const BANK_DETAILS = "authentication:BANK_DETAILS";

export const paymentSignUpAction = createAsyncThunk(
  BANK_DETAILS,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("register/fill-2", args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
