import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const GET_USER_STATS = "user:GET_USER_STATS";

export const getAllStatsDataAction = createAsyncThunk(
  GET_USER_STATS,
  async (args) => {
    const response = await axiosClient().get("stats");
    return response.data;
  }
);
