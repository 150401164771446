import axios from "axios";
import { APP_CONFIG } from "../config/settings";
import { getData, getToken } from "../redux/storage";
import axiosRetry from "axios-retry";

const axiosClient = () => {
  axios.defaults.baseURL = APP_CONFIG.BASE_URL;
  const _token = getData();
  const regToken = getToken();
  const generalToken = _token || regToken;

  axiosRetry(axios, { retries: 1 });
  // add inteceptor to logic
  axios.interceptors.request.use(config => {
    if (generalToken) {
      config.headers.Authorization = `Bearer ${generalToken}`;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
    config.headers["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
    config.headers["Access-Control-Allow-Credentials"] = true;
    
    config.withCredentials = false; 
    return config;
  });
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    
    // check if user should log out
    if(error?.response?.status == 403){
      // clear storage
      localStorage.clear();
      window.location.href = "/";
    }

    // console.log(error, "error");
    return Promise.reject(error);
  });

  return axios;
};

export default axiosClient;
