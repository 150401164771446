import React, { useContext, useEffect, useState } from "react";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { signUpAction } from "../../redux/actions/authenticationAction";
import AppModal from "../../components/AppModal/AppModal";
import AppBtn from "../../components/AppBtn/AppBtn";

const ConfirmOtpForgotPassword = () => {
  const [loading, setIsLoading] = useState();
  const [otp, setOtp] = useState("");
  const { getOtp } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleFinalSubmit = () => {
    if (getOtp.otp !== otp) {
      toast.error("Invalid OTP");
      return;
    }
    navigate("/changePassword");
  };

  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex flex-col   max-w-[1700px]   mx-auto   md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
            <img
              src="r101.png"
              alt=""
              className="w-[100%] h-full object-cover"
            />
          </div>

          <div className="flex max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
            <div className=" ">
              <div className="bg-[#85714d37] rounded-md p-5 pt-10 flex flex-col justify-center items-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  Verify OTP
                </p>

                <p className="text-center mt-5">
                  A verification code has been sent to your email, confirm and
                  change your password
                </p>
                <div className="mt-10">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span style={{ width: 18 }}> </span>}
                    renderInput={(props) => (
                      <input {...props} style={{}} className="otp-field" />
                    )}
                  />
                </div>

                <AppBtn
                  title="Submit"
                  className="mobile-btn ml-3 mt-10"
                  onClick={handleFinalSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfirmOtpForgotPassword;
