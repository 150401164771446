import { createSlice } from "@reduxjs/toolkit";
import { getAllCommonDataAction } from "../actions/commonActions";
const initialState = {
  commonStatus: "idle",
  commonSuccess: "",
  commonError: "",

  commonData: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonStatus(state) {
      state.commonStatus = "idle";
      state.commonSuccess = "";
      state.commonError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(getAllCommonDataAction.pending, (state) => {
        state.commonStatus = "loading";
      })
      .addCase(getAllCommonDataAction.fulfilled, (state, action) => {
        state.commonStatus = "completed";
        // update redux state
        state.commonData = action?.payload.payload;
      })
      .addCase(getAllCommonDataAction.rejected, (state, { payload }) => {
        state.commonStatus = "failed";
        state.commonError = payload;
      });
  },
});

export const { clearCommonStatus } = commonSlice.actions;

export default commonSlice.reducer;
