import React, { useState } from "react";
import AboutBg from "../assets/images/about-bg.svg";
import line from "../assets/images/line.png";
import AppBtn from "../components/AppBtn/AppBtn";
import { useNavigate } from "react-router-dom";
const About = () => {
  const [active, setActive] = useState(0);

  const navigate = useNavigate();

  return (
    <>
      <section className="home-bg-image">
        <div className=" container mx-auto pt-24 md:pt-0 px-0 md:px-4">
          <div className="px-10">
            {/* bread crumb goes here */}
            <div className="mb-4 px-4 flex md:px-0 pt-16 cursor-pointer">
              <div className="flex flex-col" onClick={() => setActive(0)}>
                <span> About us</span>
                {active === 0 && <img src={line} alt="" />}
              </div>

              <span className="mx-2">/</span>
              <div
                className="flex flex-col cursor-pointer"
                onClick={() => setActive(1)}
              >
                <span> About</span>
                {active === 1 && <img src={line} alt="" />}
              </div>
            </div>

            {active === 0 ? (
              <div style={{ backgroundColor: "#3E3D3D" }}>
                <p className="">
                  Welcome to Omayoza, a cutting-edge automated investment
                  platform designed to empower individuals on their financial
                  journey. Our mission is to provide a secure and accessible
                  avenue for users to invest their funds and earn risk-free
                  returns.
                </p>

                <p className="base-text px-4 md:px-0 mb-4 leading-8 font-regular">
                  <p className="mt-5">Why Choose Omayoza</p>
                  <p className="text-base mb-4">
                    1. Secure Investments: We prioritize the security of your
                    investments. Our platform implements advanced security
                    measures and adheres to regulatory standards to ensure the
                    safety of your funds.
                  </p>
                  <p className="text-base mb-4">
                    2. Risk-Free Returns: Our unique investment quests offer
                    risk-free returns over specified periods, allowing you to
                    earn while minimizing risk. Choose from our selection of
                    quests, each with its own duration and growth potential,
                    tailored to match your investment goals.
                  </p>
                  <p className="text-base mb-4">
                    3. Personalized Investment Portfolios: We understand that
                    each individual has unique financial aspirations. With
                    Omayoza, you can create a personalized investment portfolio
                    based on your preferences, risk tolerance, and time horizon.
                    Tailor your investment strategy to align with your goals and
                    watch your wealth grow..
                  </p>
                  <p className="text-base mb-4">
                    4. Real-Time Insights and Reporting: Stay informed and make
                    informed investment decisions with our real-time insights
                    and reporting features. Our automated trading algorithm
                    provides valuable investment data, enabling you to track
                    your performance and make educated choices..
                  </p>
                  <p className="text-base mb-4">
                    5. Multiple Payment Options: We offer a range of payment
                    options to provide flexibility and convenience. Choose from
                    various methods, including bank transfers, credit/debit card
                    payments, and digital wallets, to manage your investments
                    seamlessly.
                  </p>
                  <p className="text-base mb-4">
                    6. Dedicated Customer Support: Our friendly and
                    knowledgeable customer support team is here to assist you at
                    every step of your investment journey. Whether you have
                    questions about the platform, need assistance with your
                    account, or require guidance on investment strategies, we're
                    just a message or call away..
                  </p>
                  <p className="text-base mb-4">
                    7. Compliance and Transparency: Omayoza is committed to
                    compliance with applicable laws and regulations governing
                    investment activities. We have implemented internal
                    controls, risk management procedures, and follow strict
                    anti-money laundering (AML) and know your customer (KYC)
                    requirements to ensure transparency and prevent fraud.
                  </p>
                </p>

                <AppBtn
                  title="Begin your Journey"
                  className="mb-10"
                  onClick={() => navigate("/signup")}
                />
              </div>
            ) : (
              <p>No careers available</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
