import { createSlice } from "@reduxjs/toolkit";
import { preContractAction } from "../actions/preContract";
const initialState = {
  preContractStatus: "idle",
  preContractSuccess: "",
  preContractError: "",

  preContractData: null,
};

const preContractSlice = createSlice({
  name: "preContract",
  initialState,
  reducers: {
    clearPreContrctStatus(state) {
      state.preContractStatus = "idle";
      state.preContractSuccess = "";
      state.preContractError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(preContractAction.pending, (state) => {
        state.preContractStatus = "loading";
      })
      .addCase(preContractAction.fulfilled, (state, action) => {
        state.preContractStatus = "completed";
        // update redux state
        state.preContractData = action?.payload.payload;
      })
      .addCase(preContractAction.rejected, (state, { payload }) => {
        state.preContractStatus = "failed";
        state.preContractError = payload;
      });
  },
});

export const { clearPreContrctStatus } = preContractSlice.actions;

export default preContractSlice.reducer;
