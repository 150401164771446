import { createSlice } from "@reduxjs/toolkit";
import { paymentSignUpAction } from "../actions/paymentDetailsAction";
const initialState = {
  bankDetailSignUpStatus: "idle",
  bankDetailSignUpSuccess: "",
  bankDetailSignUpError: "",
};

const bankDetailsAuthenticationSlice = createSlice({
  name: "bankDetailsAuthentication",
  initialState,
  reducers: {
    clearBankignUpStatus(state) {
      state.bankDetailSignUpStatus = "idle";
      state.bankDetailSignUpSuccess = "";
      state.bankDetailSignUpError = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(paymentSignUpAction.pending, (state) => {
        state.bankDetailSignUpStatus = "loading";
      })
      .addCase(paymentSignUpAction.fulfilled, (state) => {
        state.bankDetailSignUpStatus = "completed";
        // console.log("GGGGGG");
      })
      .addCase(paymentSignUpAction.rejected, (state, { payload }) => {
        state.bankDetailSignUpStatus = "failed";

        state.bankDetailSignUpError = payload;
      });
  },
});

export const { clearBankignUpStatus } = bankDetailsAuthenticationSlice.actions;

export default bankDetailsAuthenticationSlice.reducer;
