import React, { useContext, useEffect, useState } from "react";
import { SignupInput } from "../../components";
import logoSvg from "../../assets/svg/logoSvg.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import {
  clearOtpData,
  clearRequestOtpStatus,
} from "../../redux/reducers/otpReducer";
import AppModal from "../AppModal/AppModal";
import TermsOfServiceModal from "../AppModal/TermsOfServiceModal";
import { socialSignUpUpAction } from "../../redux/actions/authenticationAction";
import google from "../../assets/images/googleLogin.png";
import facebook from "../../assets/images/facebooklogin.png";
import { clearSocialSignUpStatus } from "../../redux/reducers/authentication";
import authImage from "../../assets/images/authImage.png";

const SignupComp = () => {
  const { formDataInfo, setFormDataInfo, setAuthState } =
    useContext(AuthContext);
  const [loading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [state, setState] = useState({});
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("invalide email").required("email is required"),
    password: yup
      .string()
      .trim()
      .min(8, "min of 8 characters")
      .required("password is required"),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const otpSelector = useSelector((state) => state.otpSlice);
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "completed") {
      setIsLoading(false);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    dispatch(clearOtpData());
  }, []);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${otpSelector.requestOtpError}`);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  // SOCIAL MEDIA lOGIN USEEffECT

  useEffect(() => {
    if (authSelector.SocialSignUpStatus == "loading") {
      setIsLoading(true);
      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "completed") {
      setIsLoading(false);
      setAuthState(true);
      // authSelector.socialData.account_status.toLowerCase() == "pending";
      navigate("/");
      // window.location.href = '/';

      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "failed") {
      setIsLoading(false);
      dispatch(clearSocialSignUpStatus());
      return;
    }
  }, [authSelector.SocialSignUpStatus]);

  const handleFirstSubmit = (values) => {
    navigate("/introduce-yourself");
    setFormDataInfo({
      ...formDataInfo,
      email: values.email,
      password: values.password,
    });
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const result = tokenResponse.access_token;

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${result}`
        )
        .then((response) => {
          dispatch(socialSignUpUpAction(response.data.email));
        })
        .catch((e) => {
          setIsLoading(false);
        });
    },
    onError: () => {
      // console.log('Login Failed');

      setIsLoading(false);
    },
    onabort: () => {
      // console.log('Login Aborted');

      setIsLoading(false);
    },
    onNonOAuthError: (err) => {
      // console.log(err);

      setIsLoading(false);
    },
  });

  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex max-w-[1700px]   mx-auto  flex-col md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full  md:w-1/2 md:min-h-[100vh] hidden md:block">
            <img
              src={authImage}
              alt=""
              className="w-[100%] h-full object-cover"
            />

            <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFirstSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <div className="flex relative max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                  <Link
                    to="/"
                    className="absolute top-5 left-12 cursor-pointer"
                  >
                    <img src={logoSvg} />
                  </Link>

                  <div className="bg-[#85714d37] p-5 pt-14 md:pt-5 rounded-md mt-10">
                    <p className="font-bold text-center lg:text-left text-2xl uppercase">
                      Sign Up
                    </p>

                    <p className="text-sm  mt-5 text-center">
                      Welcome to Omazoya: Join the Adventure and Unlock Your
                      Investment Potential!
                    </p>
                    <div className="w-full">
                      <div>
                        <SignupInput
                          type="email"
                          name="email"
                          on_change={handleChange("email")}
                          onBlur={handleBlur("email")}
                          placeholder="Email"
                        />
                        {touched.email && errors.email ? (
                          <p className="errorText">
                            {touched.email && errors.email ? errors.email : ""}
                          </p>
                        ) : null}

                        <SignupInput
                          type="password"
                          name="password"
                          on_change={handleChange("password")}
                          onBlur={handleBlur("password")}
                          placeholder="password"
                        />

                        {touched.password && errors.password ? (
                          <p className="errorText">
                            {touched.password && errors.password
                              ? errors.password
                              : ""}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <p className="text-[9px] text-center md:text-sm">
                        By creating an account, you acknowledge and agree to be
                        bound by Omayoza's Terms of Service. These terms govern
                        your use of our platform and services, and outline the
                        rights, obligations, and responsibilities of both users
                        and Omayoza. It is important to carefully read and
                        understand these terms before proceeding with the
                        account creation process. If you do not agree to the
                        <span
                          onClick={() => setModal(true)}
                          className="text-[#85714D] inline-block ml-1 md:ml-1 mr-1 cursor-pointer text-[9px] md:text-sm"
                        >
                          Terms of Service.
                        </span>
                        please refrain from creating an account with Omayoza.
                      </p>
                    </div>

                    <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                      <button
                        className="text-gray-300 w-32 self-center rounded p-3 bg-[#85714D] px-8"
                        onClick={handleSubmit}
                      >
                        Sign Up
                      </button>

                      <div className="font-bold mt-2">Or</div>
                      <div className="flex flex-col gap-1 mt-2">
                        <div
                          onClick={() => {
                            setIsLoading(true);
                            login();
                          }}
                          className=" flex flex-row items-center cursor-pointer"
                        >
                          <img
                            src={google}
                            alt=""
                            style={{ width: "17", height: 17 }}
                          />
                          <button className="text-gray-300 ml-3 ">
                            Signup with Google
                          </button>
                        </div>
                      </div>

                      <p className="mt-3 text-xs ">
                        if you already have an account, please 
                        <span
                          onClick={() => navigate("/login")}
                          style={{
                            color: "#85714D",
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                        >
                          login {' '}
                        </span>
                        using your credentials
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
      <TermsOfServiceModal modal={modal} setModal={setModal} />
    </>
  );
};

export default SignupComp;
