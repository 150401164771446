import React, { useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../context/AuthContext";
import AppModal from "../../components/AppModal/AppModal";
import { clearRequestOtpStatus } from "../../redux/reducers/authentication";
import { useNavigate } from "react-router-dom";
import { RequestOtpForgotPasswordAction } from "../../redux/actions/authenticationAction";

const ForgotPasswordPage = () => {
  const [loading, setIsLoading] = useState();
  const { setGetOtp, getOtp, setEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("invalide email").required("email is required"),
  });

  useEffect(() => {
    if (authSelector.requestOtpForgotPasswordStatus == "loading") {
      setIsLoading(true);
      return;
    }
    if (authSelector.requestOtpForgotPasswordStatus == "completed") {
      setIsLoading(false);
      setGetOtp(authSelector.otpForgot);
      navigate("/confirm-otp-forgot");
      dispatch(clearRequestOtpStatus());
      return;
    }
    if (authSelector.requestOtpForgotPasswordStatus == "failed") {
      toast.error(`${authSelector.requestOtpForgotPasswordError}`);
      setIsLoading(false);
      return;
    }
  }, [authSelector.requestOtpForgotPasswordStatus]);

  const handleOtpRequest = (values) => {
    dispatch(
      RequestOtpForgotPasswordAction({
        email: values.email,
      })
    );

    setEmail(values.email);
  };

  return (
    <>
      <AppModal modal={loading} />
      <section className="home-bg-image">
        <div className="flex max-w-7xl   mx-auto  flex-col md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] relative ">
            <img
              src="r101.png"
              alt=""
              className="w-[100%] h-full object-cover"
            />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOtpRequest}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <div className="flex max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                  <h3 className="font-bold text-center lg:text-left text-2xl uppercase">
                    FORGOT PASSWORD
                  </h3>
                  <p className="text-center w-[85%]">
                    Lets try this again, make sure your password is one you will
                    always remember!
                  </p>
                  <div className="w-full">
                    <div>
                      <SignupInput
                        type="email"
                        name="email"
                        on_change={handleChange("email")}
                        onBlur={handleBlur("email")}
                        placeholder="Email"
                      />
                      {touched.email && errors.email ? (
                        <p className="errorText">
                          {touched.email && errors.email ? errors.email : ""}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                    <button
                      className="text-gray-300 w-56 self-center rounded p-3 bg-[#85714D] px-8"
                      onClick={handleSubmit}
                    >
                      Request OTP
                    </button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordPage;
