import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommonDataAction } from "../redux/actions/commonActions";
import { FaAlignJustify } from "react-icons/fa";
import logo from "../assets/images/logo.svg";
import "../assets/css/userDashboard.css";
import ValidateMessage from "../components/ValidateMessage/ValidateMessage";

function UserLayout() {
  const authSelector = useSelector((state) => state.authenticationSlice);
  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCommonDataAction());
  }, []);

  // console.log(dataSelector?.account_status, "check........");
  const [state, setState] = useState({
    loading: false,
    username: "Full Name",
    _links: [
      {
        name: "Dashboard",
        link: "/",
      },
      {
        name: "Help",
        link: "/dashboard/help",
      },
      // {
      //   name: "Learn with Omayoza",
      //   link: "/dashboard/learn",
      // },
      {
        name: "Settings",
        link: "/dashboard/settings",
      },
    ],
    // Add more state variables as needed
  });

  return (
    <section className="">
      <section className="userDashboardContainer">
        <div className="mobile px-5">
          <div className="name-container">
            <div>
              <Link to={"/"} className="flex">
                <img src={logo} alt="" className="w-[100px] h-[20px] md:w-[200px] md:h-[100px]" />
                </Link>
              <FaAlignJustify
                className="mt-5"
                size={25}
                onClick={() => setExpanded(!expanded)}
              />
            </div>

            <h5 className="font-bold">
              {dataSelector?.first_name} {dataSelector?.last_name}
            </h5>
          </div>
        </div>
        <div>
          <Sidebar
            urls={state._links}
            username={state.username}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </div>
        <div className="mainContentWrapper">
          <div className="dashboard-img"></div>
          <ValidateMessage />
          <Outlet />
        </div>
      </section>
    </section>
  );
}

export default UserLayout;
