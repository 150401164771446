import React from "react";

const SignupInput = ({
  type,
  value,
  on_change,
  name,
  placeholder = "",
  className = "",
  disabled,
  onBlur,
  min,
  step,
}) => {
  return (
    <input
      className={
        `bg-transparent my-3 h-14 px-4 customInput  rounded w-full border sm:text-sm border-[#85714D] outline-none
      } ` + className
      }
      type={type}
      value={value}
      onChange={on_change}
      name={name}
      placeholder={placeholder?.toUpperCase()}
      disabled={disabled}
      onBlur={onBlur}
      min={min}
      step={step}
    />
  );
};

export default SignupInput;
