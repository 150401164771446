import { createSlice } from "@reduxjs/toolkit";
import {
  getAllContractAdminAction,
  uploadVideoAction,
} from "../actions/adminAction";
const initialState = {
  adminGetAllContractStatus: "idle",
  adminGetAllContractSuccess: "",
  adminGetAllContractError: "",

  adminUploadVidoStatus: "idle",
  adminUploadVidoSuccess: "",
  adminUploadVidoError: "",

  allVidosData: null,
  allContractData: null,
};

const allContractSlice = createSlice({
  name: "allContract",
  initialState,
  reducers: {
    cleargetAllAdminContractStatus(state) {
      state.adminGetAllContractStatus = "idle";
      state.adminGetAllContractSuccess = "";
      state.adminGetAllContractError = "";
    },
    cleargetAllUploadStatus(state) {
      state.adminUploadVidoStatus = "idle";
      state.adminUploadVidoSuccess = "";
      state.adminUploadVidoError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(getAllContractAdminAction.pending, (state) => {
        state.adminGetAllContractStatus = "loading";
      })
      .addCase(getAllContractAdminAction.fulfilled, (state, action) => {
        state.adminGetAllContractStatus = "completed";
        // update redux state
        state.allContractData = action?.payload.payload;
      })
      .addCase(getAllContractAdminAction.rejected, (state, { payload }) => {
        state.adminGetAllContractStatus = "failed";
        state.adminGetAllContractError = payload;
      });

    builder
      .addCase(uploadVideoAction.pending, (state) => {
        state.adminUploadVidoStatus = "loading";
      })
      .addCase(uploadVideoAction.fulfilled, (state, action) => {
        state.adminUploadVidoStatus = "completed";
        // update redux state
        state.allVidosData = action?.payload.payload;
      })
      .addCase(uploadVideoAction.rejected, (state, { payload }) => {
        state.adminUploadVidoStatus = "failed";
        // state.adminUploadVidoError = payload;
      });
  },
});

export const { cleargetAllAdminContractStatus, cleargetAllUploadStatus } =
  allContractSlice.actions;

export default allContractSlice.reducer;
