import React from "react";

function LearnComp(props) {
  return (
    <div>
      <div className="flex justify-between my-3 mt-10">
        <div>LEARN WITH OMAYOZA</div>
      </div>
      <p>Watch videos to learn more forex investing</p>
      <div className="videos my-8 grid grid-cols-1 md:grid-cols-4 xl:grid-cols-4 gap-4">
        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div className="single-video">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/IwK_T0sufzE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default LearnComp;
