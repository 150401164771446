import React, { useContext, useEffect, useState } from "react";
import PayazaCheckout from "@payaza/web-sdk";
import "../../assets/css/appModal.css";
import SignupInput from "../inputs/signup";
import { useDispatch, useSelector } from "react-redux";
import { preContractAction } from "../../redux/actions/preContract";
import PaystackPop from "@paystack/inline-js";
import { getAllContractAdminAction } from "../../redux/actions/adminAction";
import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
import { createContractAction } from "../../redux/actions/contractsActions";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getProfileAction } from "../../redux/actions/profileAction";
import axios from "axios";
import { APP_CONFIG } from "../../config/settings";
import { AuthContext } from "../../context/AuthContext";

export default function DepositeModal({
  tierId,
  setModal,
  modal,
  activeQuestLevel,
  PaymentMethod,
}) {
  const preContractSelector = useSelector((state) => state.preContractSlice);
  const { setWood, wood } = useContext(AuthContext);

  const userSelector = useSelector((state) => state.profileSlice.profileData);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(wood ? "100000" : "500000");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(preContractSelector.preContractStatus);
  //   console.log(preContractSelector.preContractData.tnx_ref);
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (preContractSelector.preContractStatus === "loading") {
      setLoading(true);
      return;
    }
  }, [preContractSelector.preContractStatus]);

  useEffect(() => {
    if (preContractSelector.preContractStatus === "completed") {
      setLoading(false);
      dispatch(clearPreContrctStatus());
      dispatch(getAllContractAdminAction());
      setModal(false);
      if (PaymentMethod == "paystack") {
        payNow();
        setWood(false);
      } else {
        payWithCrypto();
        setWood(false);
      }
      return;
    }
  }, [preContractSelector.preContractStatus]);

  useEffect(() => {
    if (preContractSelector.preContractStatus === "failed") {
      setLoading(false);
      return;
    }
  }, [preContractSelector.preContractStatus]);

  const payNow = () => {
    const data = {
      merchant_key: "PZ78-PKLIVE-68096859-0647-4D1B-8BB2-E39E4EAA048D",
      connection_mode: "Live", // Live || Test
      // currency_code: "NGN", // NGN || USD
      email_address: "example@email.com",
      first_name: "mike",
      last_name: "shaibu",
      phone_number: "+1200000000",
      checkout_amount: parseFloat(amount),
      // checkout_amount: parseFloat(100),
      transaction_reference: `${preContractSelector.preContractData.tnx_ref}`,
      onClose: function () {
        // console.log("Closed");
      },
      callback: function (callbackResponse) {
        // console.log(callbackResponse, "data...");
        dispatch(
          createContractAction({
            tnx_ref: preContractSelector.preContractData.tnx_ref,
          })
        );
        dispatch(getAllStatsDataAction());
        dispatch(getAllContractAdminAction());
        navigate("/");
        dispatch(getProfileAction());
      },
    };
    const checkout = new PayazaCheckout(data);
    checkout.showPopup();
    // const paystack = new PaystackPop();
    // paystack.newTransaction({
    //   key: "pk_test_0e601f06676c530da31f0c08c17a3011c3d4bfe0",
    //   email: `${userSelector.email}`,
    //   ref: `${preContractSelector.preContractData.tnx_ref}`,
    //   amount: amount * 100,
    //   onSuccess: (transaction) => {
    //     dispatch(
    //       createContractAction({
    //         tnx_ref: preContractSelector.preContractData.tnx_ref,
    //       })
    //     );
    //     dispatch(getAllStatsDataAction());
    //     dispatch(getAllContractAdminAction());
    //     navigate("/");
    //     dispatch(getProfileAction());
    //   },
    //   onCancel: () => {},
    // });
  };

  const payWithCrypto = async () => {
    // setCryptoText("processing");

    const tnx_ref = preContractSelector.preContractData.tnx_ref;

    let data = JSON.stringify({
      name: "Omayoza",
      description: "investment purpose",
      local_price: {
        currency: "NGN",
        amount: amount,
      },
      pricing_type: "fixed_price",
      redirect_url:
        window.location.protocol + window.location.host + "/crypto/" + tnx_ref,
      cancel_url: window.location.protocol + window.location.host,
      metadata: {
        cusomter_id: tnx_ref,
      },
    });

    let config = {
      method: "post",
      url: "https://api.commerce.coinbase.com/charges",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CC-Api-Key": APP_CONFIG.COINBASE_API_KEY,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        window.location.href = response.data.data.hosted_url;
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const preContractSubmit = () => {
    if (wood && amount < 100000) {
      Swal.fire({
        icon: "error",
        title: "Sorry",
        text: "Your Deposite must not be above ₦100000",
      });
      return;
    }

    if (!wood && amount < 500000) {
      Swal.fire({
        icon: "error",
        title: "Sorry",
        text: "Your Deposite must not be less than ₦ 500000",
      });
      return;
    }

    dispatch(
      preContractAction({
        tierId: wood ? 4 : tierId,
        invested_amount: amount,
        payment_type: PaymentMethod,
        is_wood: wood ? true : false,
      })
    );
  };

  const preContracWoodtSubmit = () => {
    if (wood && amount < 100000) {
      Swal.fire({
        icon: "error",
        title: "Sorry",
        text: "Your Deposite must not be above ₦100000",
      });
      return;
    }

    dispatch(
      preContractAction({
        tierId: wood ? 4 : tierId,
        invested_amount: amount,
        payment_type: PaymentMethod,
        is_wood: wood ? true : false,
      })
    );
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {loading && <AppModal />}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content  w-[80%]">
            <div className="deposite-box">
              <h3 className="deposite-header">DEPOSIT FUNDS</h3>
              <SignupInput
                min="100000"
                step="100000"
                on_change={(e) => {
                  setAmount(e.target.value);
                }}
                type="number"
                placeholder={
                  wood
                    ? "minimum Deposite is  ₦100000"
                    : "minimum Deposite is  ₦500000"
                }
                value={amount}
              />
              <p className="deposite-sub-text text-center">
                How much would you like to invest
              </p>

              <div className="flex justify-center items-center w-full">
                <button
                  className="text-gray-300 mt-5 self-center rounded p-3 bg-[#85714D] px-8"
                  // onClick={preContractSubmit}
                  onClick={wood ? preContracWoodtSubmit : preContractSubmit}
                  // type="button"
                >
                  Deposit Funds
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
