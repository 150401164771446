import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
import { setData, setToken } from "../storage";

const SIGN_IN = "authentication:SIGN_IN";
const SIGN_UP = "authentication:SIGN_UP";
const FORGOT_PASSWORD = "authentication:FORGOT_PASSWORD";
const SIGN_IN_ADMIN = "authentication:SIGN_IN_ADMIN";
const SIGN_IN_SOCIAL = "authentication:SIGN_IN_SOCIAL";
const CONFIRM_CHANGE_PASSWORD = "authentication:CONFIRM_CHANGE_PASSWORD";

export const signInAction = createAsyncThunk(
  SIGN_IN,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("login", args);

      setToken(response.data.payload.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signInAdminAction = createAsyncThunk(
  SIGN_IN_ADMIN,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("admin/login", args);

      setToken(response.data.payload.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signUpAction = createAsyncThunk(
  SIGN_UP,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`register/direct`, args, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setToken(response.data.payload.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const socialSignUpUpAction = createAsyncThunk(
  SIGN_IN_SOCIAL,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`social`, { email: args });

      setToken(response.data.payload.token);

      // setToken(response.data.payload.main.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const RequestOtpForgotPasswordAction = createAsyncThunk(
  FORGOT_PASSWORD,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`otp/email/fp`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmChangePassword = createAsyncThunk(
  CONFIRM_CHANGE_PASSWORD,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`change-password`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
