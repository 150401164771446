import React, { useState } from "react";

import SignupComp from "../../components/auth/SignUp";
import OtherInfo from "../../components/auth/OtherInfo";
import OtherInfo2 from "../../components/auth/OtherInfo2";
import FinalForm from "../../components/auth/FinalForm";
import ConfirmOtpScreen from "../../components/auth/ConfirmOtpScreen";

const Signup = () => {
  const [user, setUser] = useState();
  const [userOtp, setUserOtp] = useState();
  const [state, setState] = useState({
    loading: false,
    next: 0,
    data: {},
    // Add more state variables as needed
  });

  const handleSubmit = (data) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      data: { ...state.data, ...data },
    }));

    //on success
    if (state.next === 4) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      loading: false,
      next: state.next + 1,
    }));
  };
  return (
    <>
      <section>
        {
          //TODO: use FRAMERMOTION.JS to animate form transition...
        }
        {state.next === 0 && (
          <SignupComp
            handleSubmit={handleSubmit}
            setUser={setUser}
            setUserOtp={setUserOtp}
          />
        )}
        {state.next === 1 && (
          <ConfirmOtpScreen
            handleSubmit={handleSubmit}
            setUser={setUser}
            user={user}
            userOtp={userOtp}
          />
        )}
        {state.next === 2 && <OtherInfo handleSubmit={handleSubmit} />}
        {state.next === 3 && <OtherInfo2 handleSubmit={handleSubmit} />}
        {state.next === 4 && <FinalForm handleSubmit={handleSubmit} />}
      </section>
    </>
  );
};

export default Signup;
