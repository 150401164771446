import React, { useState } from "react";
import Logo from "../assets/images/logo.svg";

import { Link } from "react-router-dom";

const Navbar = ({ toggle, closeModal }) => {
  return (
    <>
      {toggle && (
        <div
          className={`fixed md:h-full w-full overlay bg-black  top-14 transition-transform transform ${
            toggle ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ height: "400px" }}
        >
          <div className="bg-black  z-50 md:hidden text-white w-fit py-10 px-8">
            <div className="flex mb-12 gap-x-8">
              <div className="md:flex hidden item-center z-[3000] md:mr-20">
                {/* Logo Here */}
                <Link to={"/"} className="flex">
                  <img src={Logo} alt="" className="logo" />
                </Link>
              </div>
            </div>

            <ul className="block gap-x-8 pt-8 leading-10">
              {/* Header links */}
              <li>
                <Link
                  onClick={() => closeModal()}
                  to={"/markets"}
                  className="btn-link"
                >
                  Markets
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => closeModal()}
                  to={"/learn-with-omayoza"}
                  className="btn-link"
                >
                  Learn with us
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={() => closeModal()}
                  to={"/about"}
                  className="btn-link"
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => closeModal()}
                  to={"/quests"}
                  className="btn-link"
                >
                  Quests
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
