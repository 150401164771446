import React, { useContext, useEffect, useState } from "react";
import logoSvg from "../../assets/svg/logoSvg.svg";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import authImage from "../../assets/images/authImage.png";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../redux/storage";
import { toast } from "react-toastify";
import { requestOtpAction } from "../../redux/actions/otpAction";
import {
  clearOtpData,
  clearRequestOtpStatus,
} from "../../redux/reducers/otpReducer";
import AppModal from "../AppModal/AppModal";
import TermsOfServiceModal from "../AppModal/TermsOfServiceModal";
import AppBtn from "../AppBtn/AppBtn";

const MoreAboutYou = ({ setUserOtp }) => {
  const [loading, setIsLoading] = useState(false);

  const { formDataInfo, setFormDataInfo } = useContext(AuthContext);

  const initialValues = {
    address: "",
    postal: "",
    phone: "",
  };

  const validationSchema = yup.object({
    address: yup.string().required("Address is required"),
    postal: yup.string().required("Postal code  is required"),

    phone: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Number is required"),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const otpSelector = useSelector((state) => state.otpSlice);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "completed") {
      setUserOtp(otpSelector?.otpData?.otp);
      setIsLoading(false);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    dispatch(clearOtpData());
  }, []);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${otpSelector.requestOtpError}`);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  const handleFirstSubmit = (values) => {
    navigate("/final-form");
    setFormDataInfo({
      ...formDataInfo,
      address: values.address,
      postal: values.postal,
      phone: values.phone,
    });
  };

  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex flex-col  max-w-7xl   mx-auto  md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] hidden md:block">
            <img
              src={authImage}
              alt=""
              className="w-[100%] h-full object-cover"
            />

            <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFirstSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <div className="flex relative max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                  <Link
                    to="/"
                    className="absolute top-10 left-12 cursor-pointer md:top-5"
                  >
                    <img src={logoSvg} />
                  </Link>
                  <div className="bg-[#85714d37] rounded-md p-5  pt-20 md:pt-10">
                    <p className="font-bold text-center lg:text-left text-2xl uppercase">
                      Sign Up
                    </p>

                    <p className="text-sm text-center">
                      To continue with the account creation process, we kindly
                      ask you to provide us with the following information.
                    </p>
                    <div className="w-full">
                      <div>
                        <SignupInput
                          type="text"
                          on_change={handleChange("address")}
                          onBlur={handleBlur("address")}
                          placeholder="address"
                        />
                        {touched.address && errors.address ? (
                          <p className="errorText">
                            {touched.address && errors.address
                              ? errors.address
                              : ""}
                          </p>
                        ) : null}

                        <SignupInput
                          type="number"
                          on_change={handleChange("postal")}
                          onBlur={handleBlur("postal")}
                          placeholder="postal code"
                          className="inputWithoutCount"
                        />
                        {touched.postal && errors.postal ? (
                          <p className="errorText">
                            {touched.postal && errors.postal
                              ? errors.postal
                              : ""}
                          </p>
                        ) : null}

                        <SignupInput
                          type="number"
                          on_change={handleChange("phone")}
                          onBlur={handleBlur("phone")}
                          placeholder="Phone number"
                          className="inputWithoutCount"
                        />
                        {touched.phone && errors.phone ? (
                          <p className="errorText">
                            {touched.phone && errors.phone ? errors.phone : ""}
                          </p>
                        ) : null}
                      </div>
                      <div className="mt-5 flex items-center">
                        <AppBtn
                          title="Back"
                          className="mobile-btn"
                          onClick={() => navigate("/introduce-yourself")}
                        />
                        <AppBtn
                          title="Next"
                          className="mobile-btn ml-3"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default MoreAboutYou;
