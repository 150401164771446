import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { SignupInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { secondSignUpAction } from "../../redux/actions/secondSignUpAction";
import { clearSecondSignUpStatus } from "../../redux/reducers/secondSignUpReducer";
import mobileImg from "../../assets/images/loginMobile.png";
import { useNavigate } from "react-router-dom";
import AppModal from "../../components/AppModal/AppModal";

const ThirdSignUpPage = ({ handleSubmit }) => {
  const [loading, setIsLoading] = useState();
  const navigate = useNavigate();

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    postal: "",
    phone: "",
    nin: "",
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    address: yup.string().required("Address Name is required"),
    postal: yup.string().required("Postal code Name is required"),
    phone: yup.string().required("Phone Number is required"),
    nin: yup.string().required("Nin Number is required"),
  });

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.secondSignUpSlice);

  useEffect(() => {
    if (authSelector.secondSignUpStatus == "loading") {
      setIsLoading(true);
      return;
    }
    dispatch(clearSecondSignUpStatus());
  }, [authSelector.secondSignUpStatus]);

  useEffect(() => {
    if (authSelector.secondSignUpStatus == "completed") {
      navigate("/signup/fourth");
      setIsLoading(false);
      return;
    }
    dispatch(clearSecondSignUpStatus());
  }, [authSelector.secondSignUpStatus]);

  useEffect(() => {
    if (authSelector.secondSignUpStatus == "failed") {
      setIsLoading(false);
      return;
    }
    dispatch(clearSecondSignUpStatus());
  }, [authSelector.secondSignUpStatus]);

  const handleSecondSubmit = (values) => {
    dispatch(secondSignUpAction(values));
  };

  return (
    <>
      <AppModal modal={loading} />
      <section>
        <div className=" flex flex-col md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
            <img
              src={mobileImg}
              alt=""
              className="w-[100%] h-full object-cover"
            />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSecondSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <div className="flex flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                  <div className="font-bold text-2xl">Sign Up</div>
                  {
                    //this should be i separate component
                  }
                  <div className="w-full ">
                    <div className="gap-5 flex items-center w-full  justify-between">
                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("first_name")}
                          onBlur={handleBlur("first_name")}
                          placeholder="first name"
                        />
                        {touched.first_name && errors.first_name ? (
                          <p className="errorText block">
                            {touched.first_name && errors.first_name
                              ? errors.first_name
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("middle_name")}
                          onBlur={handleBlur("middle_name")}
                          placeholder="middle name"
                        />
                        {touched.first_name && errors.first_name ? (
                          <p className="errorText">
                            {touched.first_name && errors.first_name
                              ? null
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("last_name")}
                          onBlur={handleBlur("last_name")}
                          placeholder="surname"
                        />
                        {touched.last_name && errors.last_name ? (
                          <p className="errorText">
                            {touched.last_name && errors.last_name
                              ? errors.last_name
                              : ""}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("address")}
                          onBlur={handleBlur("address")}
                          placeholder="address"
                        />
                        {touched.address && errors.address ? (
                          <p className="errorText">
                            {touched.address && errors.address
                              ? errors.address
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("postal")}
                          onBlur={handleBlur("postal")}
                          placeholder="postal code"
                        />
                        {touched.postal && errors.postal ? (
                          <p className="errorText">
                            {touched.postal && errors.postal
                              ? errors.postal
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("phone")}
                          onBlur={handleBlur("phone")}
                          placeholder="number"
                        />
                        {touched.phone && errors.phone ? (
                          <p className="errorText">
                            {touched.phone && errors.phone ? errors.phone : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          on_change={handleChange("nin")}
                          onBlur={handleBlur("nin")}
                          placeholder="Nin"
                        />
                        <p className="errorText">
                          {touched.nin && errors.nin ? errors.nin : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="text-gray-300 rounded p-3 bg-[#85714D] px-8"
                    // onClick={on_submit}
                    // type="button"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default ThirdSignUpPage;
