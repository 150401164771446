import React, { useEffect, useState } from "react";

import "../../assets/css/appModal.css";
import SignupInput from "../inputs/signup";
import { useDispatch, useSelector } from "react-redux";
import { preContractAction } from "../../redux/actions/preContract";
import Swal from "sweetalert2";
import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
import {
  createContractAction,
  endContractAction,
} from "../../redux/actions/contractsActions";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";
import { clearEndContractStatus } from "../../redux/reducers/contractsReducer";
import WithdrawPaymentModal from "./WithdrawPaymentModal";
import ThirtyDays from "../WithdrawlNotifications/ThirtyDays";
import LessThan30Days from "../WithdrawlNotifications/LessThan30Days";
import { FaTimes } from "react-icons/fa";
import GreaterThan60Days from "../WithdrawlNotifications/GreaterThan60Days";

export default function AppWithrawModal({
  modal,
  setModal,
  contractId,
  questLevel,
}) {
  const contractActionSelector = useSelector((state) => state.contract);
  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [showBank, setShowBank] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (contractActionSelector.endContractStatus === "loading") {
      setLoading(true);
      return;
    }
    if (contractActionSelector.endContractStatus === "completed") {
      setLoading(false);
      dispatch(clearEndContractStatus());
      setShowSuccess(true);

      dispatch(getAllStatsDataAction());
      setModal(false);
      return;
    }
    if (contractActionSelector.endContractStatus === "failed") {
      setLoading(false);
      dispatch(clearEndContractStatus());
      return;
    }
  }, [contractActionSelector.endContractStatus]);

  const withdrawContractSubmit = () => {
    dispatch(
      endContractAction({
        contract_id: contractId,
      })
    );
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {loading && <AppModal />}
      {modal && (
        <div className="popup">
          <div className="popup-inner h-[600px] bg-black">
            <button className="close-btn-modal">
              <FaTimes
                onClick={() => {
                  setModal(false);
                }}
              />
            </button>
            {(userContractSelector?.usercontracts?.day_count < 30) && (
              <ThirtyDays
                withdrawContractSubmit={withdrawContractSubmit}
                setModal={setModal}
                setShowBank={setShowBank}
                />
              )} 
            {userContractSelector?.usercontracts?.day_count > 30 && userContractSelector?.usercontracts?.day_count < 60 && (
              <LessThan30Days
                withdrawContractSubmit={withdrawContractSubmit}
                setModal={setModal}
                setShowBank={setShowBank}
              />
            )}
            { userContractSelector?.usercontracts?.day_count > 60 && (
              <GreaterThan60Days
                withdrawContractSubmit={withdrawContractSubmit}
                setModal={setModal}
                setShowBank={setShowBank}
              />
            )}
          </div>
        </div>
      )}

      <WithdrawPaymentModal
        modal={showBank}
        setModal={setShowBank}
        contractId={contractId}
        questLevel={questLevel}
      />

      {/* show success message */}
      {showSuccess && (
        <div className="popup">
          <div className="popup-inner bg-black">
            <button className="close-btn-modal">
              <FaTimes
                onClick={() => {
                  setShowSuccess(false);
                }}
              />
            </button>
            <h4 className="font-medium">Withdrawal Request Submitted!</h4>
            <p className="text-sm mt-5">
              {" "}
              Congratulations on initiating your withdrawal request! We've
              received your request, and a notification has been sent to our
              diligent admin team for processing. Please note that it may take
              up to 48 hours for the withdrawal to reflect in your bank account.
            </p>
            <p className="text-sm mt-5">
              {" "}
              During this time, our team will carefully review and process your
              request to ensure a seamless and secure transaction. Rest assured,
              we are working diligently to fulfill your withdrawal as quickly as
              possible.
            </p>

            <p className="text-sm mt-5">
              {" "}
              We appreciate your patience and understanding as we strive to
              provide you with the best service. If you have any questions or
              need further assistance, please don't hesitate to reach out to our
              support team.{" "}
            </p>

            <p className="text-sm mt-5">
              Thank you for choosing Omayoza for your investment needs. We're
              committed to delivering a superior experience from start to
              finish.
            </p>
            <p className="text-sm mt-5">
              {" "}
              Wishing you continued success on your investment journey
            </p>
            <p className="text-sm mt-5">The Omayoza Team</p>
          </div>
        </div>
      )}
    </>
  );
}
