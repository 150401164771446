import React, { useEffect, useState } from "react";
import "../../assets/css/appModal.css";
import SignupInput from "../inputs/signup";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { preContractAction } from "../../redux/actions/preContract";
import PaystackPop from "@paystack/inline-js";
import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
import { createContractAction } from "../../redux/actions/contractsActions";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as yup from "yup";
import { clearChangePasswordStatus } from "../../redux/reducers/profileReducer";
import { changePassWordAction } from "../../redux/actions/profileAction";

export default function ChangePasswordModal({ setModal, modal }) {
  const profileSelector = useSelector((state) => state.profileSlice);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (profileSelector.changePasswordStatus === "loading") {
      setLoading(true);
      return;
    }
  }, [profileSelector.changePasswordStatus]);

  useEffect(() => {
    if (profileSelector.changePasswordStatus === "completed") {
      setLoading(false);
      Swal.fire("Your Password was Changed Successfully");
      dispatch(clearChangePasswordStatus());
      setModal(false);
      return;
    }
  }, [profileSelector.changePasswordStatus]);

  useEffect(() => {
    if (profileSelector.changePasswordStatus === "failed") {
      setLoading(false);
      setModal(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Old Password is Incorrect",
      });

      dispatch(clearChangePasswordStatus());
      return;
    }
  }, [profileSelector.changePasswordStatus]);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = yup.object({
    oldPassword: yup.string().required("Old Password is required"),
    newPassword: yup
      .string()
      .trim()
      .min(8, "min of 8 characters")
      .required("New password is required"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const handleChangePassword = (values) => {
    dispatch(
      changePassWordAction({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
    );
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>

          <div className="modal-content">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleChangePassword}
            >
              {({
                errors,
                values,
                touched,
                handleBlur,
                handleSubmit,
                handleChange,
              }) => {
                return (
                  <div className="deposite-box">
                    <h3 className="deposite-header">CHANGE PASSWORD</h3>
                    <SignupInput
                      on_change={handleChange("oldPassword")}
                      onBlur={handleBlur("oldPassword")}
                      placeholder="Old Password"
                    />
                    {touched.oldPassword && errors.oldPassword ? (
                      <p className="errorText">
                        {touched.oldPassword && errors.oldPassword
                          ? errors.oldPassword
                          : ""}
                      </p>
                    ) : null}

                    <SignupInput
                      on_change={handleChange("newPassword")}
                      onBlur={handleBlur("newPassword")}
                      placeholder="New Password"
                    />
                    {touched.newPassword && errors.newPassword ? (
                      <p className="errorText">
                        {touched.newPassword && errors.newPassword
                          ? errors.newPassword
                          : ""}
                      </p>
                    ) : null}
                    <SignupInput
                      on_change={handleChange("confirmNewPassword")}
                      onBlur={handleBlur("confirmNewPassword")}
                      placeholder="Confirm New Password"
                    />
                    {touched.confirmNewPassword && errors.confirmNewPassword ? (
                      <p className="errorText">
                        {touched.confirmNewPassword && errors.confirmNewPassword
                          ? errors.confirmNewPassword
                          : ""}
                      </p>
                    ) : null}

                    <button
                      className="text-gray-300 mt-5 self-center rounded p-3 bg-[#85714D] px-8"
                      // onClick={preContractSubmit}
                      onClick={handleSubmit}
                      // type="button"
                    >
                      {loading ? "Updating Password..." : "Change Password"}
                    </button>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}
