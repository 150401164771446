import { createSlice } from "@reduxjs/toolkit";
import { requestOtpAction } from "../actions/otpAction";
const initialState = {
  requestOtpStatus: "idle",
  requestOtpSuccess: "",
  requestOtpError: "",

  otpData: null,
};

const requestOtpSlice = createSlice({
  name: "requestOtp",
  initialState,
  reducers: {
    clearRequestOtpStatus(state) {
      state.requestOtpStatus = "idle";
      state.requestOtpSuccess = "";
      state.requestOtpError = "";
    },

    clearOtpData(state) {
      state.otpData = null;
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(requestOtpAction.pending, (state) => {
        state.requestOtpStatus = "loading";
      })
      .addCase(requestOtpAction.fulfilled, (state, { payload }) => {
        state.requestOtpStatus = "completed";
        state.otpData = payload?.payload;
      })
      .addCase(requestOtpAction.rejected, (state, { payload }) => {
        state.requestOtpStatus = "failed";
        state.requestOtpError = payload?.message;
      });
  },
});

export const { clearRequestOtpStatus, clearOtpData } = requestOtpSlice.actions;

export default requestOtpSlice.reducer;
