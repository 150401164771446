import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const SECOND_SIGN_UP = "authentication:SECOND_SIGN_UP";

export const secondSignUpAction = createAsyncThunk(
  SECOND_SIGN_UP,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`register/fill-1`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
