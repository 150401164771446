const KEY_TOKEN = "APP:USER:TOKEN";
const SUCCESS_SIGNUPTOKEN = "APP:USER:DATA";

export const setToken = (token) => {
  localStorage.setItem(KEY_TOKEN, token);
};

export const getToken = () => {
  let token = localStorage.getItem(KEY_TOKEN);
  return token;
};

export const setData = (data) => {
  localStorage.setItem(SUCCESS_SIGNUPTOKEN, data);
};

export const getData = () => {
  let loginToken = localStorage.getItem(SUCCESS_SIGNUPTOKEN);
  return loginToken;
};
