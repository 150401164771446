import { createSlice } from "@reduxjs/toolkit";
import {
  createContractAction,
  endContractAction,
  getContractEligibiltyAction,
  getContractdAction,
  acceptDeclineContractAction,
  closeContractAction,
} from "../actions/contractsActions";
const initialState = {
  createContractStatus: "idle",
  createContractSuccess: "",
  createContractError: "",

  getContractStatus: "idle",
  getContractSuccess: "",
  getContractError: "",

  contractEligibilityStatus: "idle",
  contractEligibilitySuccess: "",
  contractEligibilityError: "",

  endContractStatus: "idle",
  endContractSuccess: "",
  endContractError: "",

  acceptDeclineContractStatus: "idle",
  acceptDeclineContractSuccess: "",
  acceptDeclineContractError: "",

  closeContractStatus: "idle",
  closeContractSuccess: "",
  closeContractError: "",

  contractsData: null,
  eligibilityData: null,
};

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    clearCreateContractStatus(state) {
      state.createContractStatus = "idle";
      state.createContractSuccess = "";
      state.createContractError = "";
    },
    clearGetContractStatus(state) {
      state.getContractStatus = "idle";
      state.getContractSuccess = "";
      state.getContractError = "";
    },

    clearContractEligibilityStatus(state) {
      state.contractEligibilityStatus = "idle";
      state.contractEligibilitySuccess = "";
      state.contractEligibilityError = "";
    },

    clearEndContractStatus(state) {
      state.endContractStatus = "idle";
      state.endContractSuccess = "";
      state.endContractError = "";
    },

    clearAcceptDeclineContractStatus(state) {
      state.acceptDeclineContractStatus = "idle";
      state.acceptDeclineContractSuccess = "";
      state.acceptDeclineContractError = "";
    },

    clearCloseContractStatus(state) {
      state.closeContractStatus = "idle";
      state.closeContractSuccess = "";
      state.closeContractError = "";
    },
  },

  extraReducers: (builder) => {
    // ======CREATE CONTRACT STATUS=======//
    builder
      .addCase(createContractAction.pending, (state) => {
        state.createContractStatus = "loading";
      })
      .addCase(createContractAction.fulfilled, (state, action) => {
        state.createContractStatus = "completed";
        // update redux state
        // state.userData = action?.payload.payload.main.user;
      })
      .addCase(createContractAction.rejected, (state, { payload }) => {
        state.createContractStatus = "failed";
        state.createContractError = payload.payload;
      });

    // ======GET CONTRACT STATUS =======//
    builder
      .addCase(getContractdAction.pending, (state) => {
        state.getContractStatus = "loading";
      })
      .addCase(getContractdAction.fulfilled, (state, { payload }) => {
        state.getContractStatus = "completed";
        state.contractsData = payload.payload;
      })
      .addCase(getContractdAction.rejected, (state, { payload }) => {
        state.getContractStatus = "failed";
        state.getContractError = payload;
      });

    // ======GET CONTRACT ELIGIBILITY STATUS=======//
    builder
      .addCase(getContractEligibiltyAction.pending, (state) => {
        state.contractEligibilityStatus = "loading";
      })
      .addCase(getContractEligibiltyAction.fulfilled, (state, { payload }) => {
        state.contractEligibilityStatus = "completed";
        state.eligibilityData = payload.payload;
      })
      .addCase(getContractEligibiltyAction.rejected, (state, { payload }) => {
        state.contractEligibilityStatus = "failed";
        state.contractEligibilityError = payload;
      });

    // ======END CONTRACT ELIGIBILITY=======//
    builder
      .addCase(endContractAction.pending, (state) => {
        state.endContractStatus = "loading";
      })
      .addCase(endContractAction.fulfilled, (state, { payload }) => {
        state.endContractStatus = "completed";
      })
      .addCase(endContractAction.rejected, (state, { payload }) => {
        state.endContractStatus = "failed";
        state.endContractError = payload;
      });

    // ======ACCEPT OR DECLINE CONTRACT=======//
    builder
      .addCase(acceptDeclineContractAction.pending, (state) => {
        state.acceptDeclineContractStatus = "loading";
      })
      .addCase(acceptDeclineContractAction.fulfilled, (state, { payload }) => {
        state.acceptDeclineContractStatus = "completed";
      })
      .addCase(acceptDeclineContractAction.rejected, (state, { payload }) => {
        state.acceptDeclineContractStatus = "failed";
        state.acceptDeclineContractError = payload;
      });

    // ======CLOSE CONTRACT=======//
    builder
      .addCase(closeContractAction.pending, (state) => {
        state.closeContractStatus = "loading";
      })
      .addCase(closeContractAction.fulfilled, (state, { payload }) => {
        state.closeContractStatus = "completed";
      })
      .addCase(closeContractAction.rejected, (state, { payload }) => {
        state.closeContractStatus = "failed";
        state.closeContractError = payload;
      });
  },
});

export const {
  clearCreateContractStatus,
  clearGetContractStatus,
  clearContractEligibilityStatus,
  clearEndContractStatus,
  clearAcceptDeclineContractStatus,
  clearCloseContractStatus,
} = contractSlice.actions;

export default contractSlice.reducer;
