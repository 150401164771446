import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";

const GET_USER_NOTIFICATIONS = "user:GET_USER_NOTIFICATIONS";
const GET_USER_INVESTMENT_HISTORY = "user:GET_USER_INVESTMENT_HISTORY";

export const getNotificationsAction = createAsyncThunk(
  GET_USER_NOTIFICATIONS,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().get("notification");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getInvestmentHistoryAction = createAsyncThunk(
  GET_USER_INVESTMENT_HISTORY,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().get("investment-history");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
