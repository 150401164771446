import React, { useRef } from "react";
import Email from "../assets/icons/email.svg";
import Phone from "../assets/icons/phone.svg";
import Send from "../assets/icons/send.svg";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
const Help = ({chatTrigger}) => {

  return (
    <>
      <section className=" mx-auto py-8   md:px-5 mb-20">
        <div className="mb-4 text-4xl  font-semibold">Contact Us</div>
        <p className="mb-3">
          We value your feedback, inquiries, and suggestions. Our dedicated
          support team is here to assist you. Choose your preferred method of
          contact below and reach out to us. We look forward to hearing from
          you! <br /> Email:
        </p>
        <p className="mb-3">
          For general inquiries, feedback, or support, please email us at
          info@omayoza.com
          <br />
          Phone:
        </p>
        <p className="mb-3">
          To speak with a representative directly, you can reach us during
          business hours at +23400000000 (A number will be provided later)
        </p>

        <h3 className="mb-3">Live Chat:</h3>
        <p className="mb-3">
          Need immediate assistance? Our live chat feature is available on our
          website. Simply click on the chat icon in the corner of your screen to
          connect with a member of our support team.
        </p>
        <p className="mb-3">
          We strive to respond to all inquiries promptly. Please allow up to 24
          hours for a response via email or live chat. If you have an urgent
          matter, we recommend reaching out to us by phone for immediate
          assistance.
        </p>
        <p className="mb-3">
          Your satisfaction is our priority, and we're here to ensure your
          experience with Omayoza is exceptional. Don't hesitate to contact us
          with any questions or concerns you may have. We're here to help you on
          your investment journey!
        </p>
        <p className="mb-3">Thank you for choosing Omayoza.</p>

        {/* <ol type="1" className="mb-32">
          <li className="mb-5 flex flex-col">
            <span>1. What is Omayoza?</span>

            <p className="ml-5">
              . Omayoza is an automated investment platform that offers users
              the opportunity to invest and earn risk-free returns over a
              specified period of time.
            </p>
          </li>

          <li className="mb-5 flex flex-col">
            <span>2. How does Omayoza work?</span>

            <p className="ml-5">
              . Omayoza allows users to create personalized investment
              portfolios and choose from different investment quests with
              varying durations and growth potentials. Users can make deposits,
              track their investments, and withdraw funds after a specific
              period.
            </p>
          </li>

          <li className="mb-5 flex flex-col">
            <span>3. Is my investment safe with Omayoza?</span>

            <p className="ml-5">
              . Yes, Omayoza prioritizes the security of user investments. We
              implement advanced security measures and comply with relevant
              regulations to ensure the safety of your funds.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>4. What are the available investment quests?</span>

            <p className="ml-5">
              . quests: The "Sapling" Quest (3 months), The "Branching Out"
              Quest (6 months), and The "Canopy" Quest (12 months). Each quest
              offers different growth potentials based on your investment goals
              and risk tolerance.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>
              5. Can I withdraw my funds before the quest duration ends?
            </span>

            <p className="ml-5">
              . Yes, you can withdraw your funds after 60 days; however, there
              will be a penalty. If you withdraw before 60 days, a penalty of
              30% of the initial investment will be applied. After 60 days, the
              penalty reduces to 10% of the initial investment.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>6. What payment methods are accepted on Omayoza?</span>

            <p className="ml-5">
              . We offer multiple payment options, including bank transfers,
              credit/debit card payments, and digital wallets, to provide
              convenience and flexibility for our users.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>7. Can I open multiple investment contracts?</span>

            <p className="ml-5">
              . Yes, depending on your investment class, you can open a specific
              number of concurrent investment contracts. Bronze users can open
              up to 3 contracts, Silver users up to 5, and Gold users up to 7.
            </p>
          </li>

          <li className="mb-5 flex flex-col">
            <span>8. How often are the interest rates updated?</span>

            <p className="ml-5">
              . The interest rates for each investment quest are fixed for the
              duration of the quest and are determined based on prevailing
              market conditions.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>9. Is there a minimum investment amount?</span>

            <p className="ml-5">
              . Yes, there is a minimum investment amount, which will be
              specified during the registration and investment process.
            </p>
          </li>
          <li className="mb-5 flex flex-col">
            <span>10. How can I contact customer support?</span>

            <p className="ml-5">
              . You can reach our customer support team through email, phone, or
              chat. We are here to assist you with any questions or concerns you
              may have.
            </p>
          </li>
        </ol> */}
        <div className="grid md:grid-cols-2 gap-4 items-center">
          <div>
            <div className="flex items-center gap-x-6 mb-6">
              <img src={Phone} alt="" />
              <a href="tel:5554280940">09067583456</a>
            </div>

            <div className="flex items-center gap-x-6 mb-6">
              <img src={Email} alt="" />

              <a href="mailto:demo@demo.com"> info@omayoza.com</a>
            </div>
            <p className="base-text mb-8 ">
              Didn’t answer your question? <button onClick={chatTrigger}>Chat with us</button> 
            </p>
          </div>

          {/* <div className="order-first md:order-last mb-8 md:mb-0">
            <p className="base-text mb-8 ">
              Didn’t answer your question? Chat with us
            </p>
            <form>
              <div className="append ">
                <input
                  type="text"
                  className="w-full p-3.5 px-4 pr-14"
                  placeholder="send a message..."
                />
                <button className="btn-send">
                  <img src={Send} alt="" />
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Help;
