import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const GET_ALL_CONTRACT = "admin:GET_ALL_CONTRACT";
const UPLOAD_VIDEO = "admin:UPLOAD_VIDEO";

export const getAllContractAdminAction = createAsyncThunk(
  GET_ALL_CONTRACT,
  async (args) => {
    const response = await axiosClient().get("contract");
    return response.data;
  }
);

export const uploadVideoAction = createAsyncThunk(
  UPLOAD_VIDEO,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`blog`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
