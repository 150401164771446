import React, { useContext, useEffect, useState } from "react";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdminLoginStatus,
  clearLoginStatus,
} from "../../redux/reducers/authentication";
import {
  signInAction,
  signInAdminAction,
} from "../../redux/actions/authenticationAction";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import google from "../../assets/images/googleLogin.png";
import facebook from "../../assets/images/facebooklogin.png";
import apple from "../../assets/images/appleLogin.png";
import mobileImg from "../../assets/images/loginMobile.png";
import { getToken } from "../../redux/storage";
import loginImage from "../../assets/images/loginImage.png";

const LoginAdmin = () => {
  const [loading, setIsLoading] = useState();
  const { setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("invalide email").required("email is required"),
    password: yup
      .string()
      .trim()
      .min(3, "min of 8 characters")
      .required("password is required"),
  });

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (authSelector.signingInAdminStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.signingInAdminStatus]);

  useEffect(() => {
    if (authSelector.signingInAdminStatus == "completed") {
      setAuthState(true);
      setIsLoading(false);
      navigate("/");
      return;
    }
    dispatch(clearLoginStatus());
  }, [authSelector.signingInAdminStatus]);

  useEffect(() => {
    if (authSelector.signingInAdminStatus == "failed") {
      toast.error(`${authSelector.signingInAdminError}`);
      setIsLoading(false);
      return;
    }
    dispatch(clearAdminLoginStatus());
  }, [authSelector.signingInAdminStatus]);

  const handleLAdminogin = (values) => {
    dispatch(signInAdminAction(values));
  };

  return (
    <section>
      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
          <img
            src={loginImage}
            alt=""
            className="w-[100%] h-full object-cover"
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLAdminogin}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            return (
              <div className="flex flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  LOG IN ADMIN
                </p>
                <div className="w-full">
                  <div>
                    <SignupInput
                      type="email"
                      name="email"
                      on_change={handleChange("email")}
                      onBlur={handleBlur("email")}
                      placeholder="Email"
                    />
                    {touched.email && errors.email ? (
                      <p className="errorText">
                        {touched.email && errors.email ? errors.email : ""}
                      </p>
                    ) : null}

                    <SignupInput
                      type="password"
                      name="password"
                      on_change={handleChange("password")}
                      onBlur={handleBlur("password")}
                      placeholder="password"
                    />

                    {touched.password && errors.password ? (
                      <p className="errorText">
                        {touched.password && errors.password
                          ? errors.password
                          : ""}
                      </p>
                    ) : null}

                    <p className="text-gray-400 text-xs text-right">
                      Forgot Password
                    </p>

                    <div className="flex items-end bg-black w-[100%] justify-items-end"></div>
                  </div>
                </div>

                <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                  <button
                    className="text-gray-300 w-32 self-center rounded p-3 bg-[#85714D] px-8"
                    onClick={handleSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default LoginAdmin;
