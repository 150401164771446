export const banks = [
  "9mobile 9Payment Service Bank",
  "Abbey Mortgage Bank",
  "Above Only MFB",
  "Abulesoro MFB",
  "Access Bank",
  "Access Bank (Diamond)",
  "Accion Microfinance Bank",
  "Ahmadu Bello University Microfinance Bank",
  "Airtel Smartcash PSB",
  "AKU Microfinance Bank",
  "ALAT by WEMA",
  "Amju Unique MFB",
  "AMPERSAND MICROFINANCE BANK",
  "Aramoko MFB",
  "ASO Savings and Loans",
  "Astrapolaris MFB LTD",
  "Bainescredit MFB",
  "Banc Corp Microfinance Bank",
  "Bowen Microfinance Bank",
  "Branch International Financial Services Limited",
  "Carbon",
  "CASHCONNECT MFB",
  "CEMCS Microfinance Bank",
  "Chanelle Microfinance Bank Limited",
  "Citibank Nigeria",
  "Consumer Microfinance Bank",
  "Corestep MFB",
  "Coronation Merchant Bank",
  "County Finance Limited",
  "Crescent MFB",
  "Dot Microfinance Bank",
  "Ecobank Nigeria",
  "Ekimogun MFB",
  "Ekondo Microfinance Bank",
  "Eyowo",
  "Fairmoney Microfinance Bank",
  "Fidelity Bank",
  "Firmus MFB",
  "First Bank of Nigeria",
  "First City Monument Bank",
  "FirstTrust Mortgage Bank Nigeria",
  "FLOURISH MFB",
  "FSDH Merchant Bank Limited",
  "Gateway Mortgage Bank LTD",
  "Globus Bank",
  "GoMoney",
  "Goodnews Microfinance Bank",
  "Greenwich Merchant Bank",
  "Guaranty Trust Bank",
  "Hackman Microfinance Bank",
  "Hasal Microfinance Bank",
  "Heritage Bank",
  "HopePSB",
  "Ibile Microfinance Bank",
  "Ikoyi Osun MFB",
  "Ilaro Poly Microfinance Bank",
  "mowo MFB",
  "Infinity MFB",
  "Jaiz Bank",
  "Kadpoly MFB",
  "Keystone Bank",
  "Kredi Money MFB LTD",
  "Kuda Bank",
  "Lagos Building Investment Company Plc.",
  "Links MFB",
  "Living Trust Mortgage Bank",
  "Lotus Bank",
  "Mayfair MFB",
  "Mint MFB",
  "Moniepoint MFB",
  "MTN Momo PSB",
  "Optimus Bank Limited",
  "Paga",
  "PalmPay",
  "Parallex Bank",
  "Parkway - ReadyCash",
  "Paycom",
  "Peace Microfinance Bank",
  "Personal Trust MFB",
  "WithdrawPaymentModal.jsx:135 Petra Mircofinance Bank Plc",
  "Polaris Bank",
  "Polyunwana MFB",
  "PremiumTrust Bank",
  "Providus Bank",
  "QuickFund MFB",
  "Rand Merchant Bank",
  "Refuge Mortgage Bank",
  "Rigo Microfinance Bank Limited",
  "ROCKSHIELD MICROFINANCE BANK",
  "Rubies MFB",
  "Safe Haven MFB",
  "Safe Haven Microfinance Bank Limited",
  "Shield MFB",
  "Solid Allianze MFB",
  "Solid Rock MFB",
  "Sparkle Microfinance Bank",
  "Stanbic IBTC Bank",
  "Standard Chartered Bank",
  "Stellas MFB",
  "Sterling Bank",
  "Suntrust Bank",
  "Supreme MFB",
  "TAJ Bank",
  "Tanadi Microfinance Bank",
  "Tangerine Money",
  "TCF MFB",
  "Titan Bank",
  "Titan Paystack",
  "U&C Microfinance Bank Ltd (U AND C MFB)",
  "Uhuru MFB",
  "Unaab Microfinance Bank Limited",
  "Unical MFB",
  "Unilag Microfinance Bank",
  "Union Bank of Nigeria",
  "United Bank For Africa",
  "Unity Bank",
  "VFD Microfinance Bank Limited",
  "Waya Microfinance Bank",
  "Wema Bank",
  "Zenith Bank",
];
