import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "../../redux/actions/profileAction";

import { getData } from "../../redux/storage";
import { motion } from "framer-motion";
import "../../assets/css/userDashboard.css";
import logo from "../../assets/images/logo.svg";
import { FaTimes } from "react-icons/fa";
import { clearUserData } from "../../redux/reducers/authentication";
// import MessageModal from "../AppModal/MessageModal";
import { clearOtpData } from "../../redux/reducers/otpReducer";

const Sidebar = ({ urls = [], username, expanded, setExpanded }) => {
  const { setAuthState } = useContext(AuthContext);
  const [activeLink, setActiveLink] = useState(0);
  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const adminSelector = useSelector((state) => state.authenticationSlice);

  const dispatch = useDispatch();

  const [dimension, setDimension] = useState(window.innerWidth);
  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  const detectSize = () => {
    setDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [dimension]);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-80%",
    },
  };

  const navigate = useNavigate();
  return (
    <motion.div
      className="appSidebar"
      variants={sidebarVariants}
      animate={dimension <= 700 ? `${expanded}` : ""}
    >
      <div className="sidebar-mobile-view px-5 mb-8">
        <Link to={"/"} className="flex">
            <img src={logo} alt="" className="w-[150px] h-[20px] md:w-[200px] md:h-[100px]" />
        </Link>
        <FaTimes
          onClick={() => setExpanded(!expanded)}
          className="close-icon"
        />
      </div>

      <div className="md:block py-2 px-4 font-bold  uppercase hidden">
        {dataSelector?.first_name} {dataSelector?.last_name}
      </div>

      <ul className="">
        {urls.map((e, i) => (
          <li
            key={e.link + i}
            onClick={() => setExpanded(!expanded)}
            className={`my-3 `}
          >
            <Link
              to={e.link}
              className="blockon py-2 px-4 capitalize text-white"
            >
              {e.name}
            </Link>
          </li>
        ))}

        <li
          className="block py-2 px-4 capitalize cursor-pointer text-red-600"
          onClick={() => {
            localStorage.removeItem("persist:root");
            setAuthState(false);
            localStorage.clear();
            dispatch(clearUserData());
            dispatch(clearOtpData());
            navigate("/");
          }}
        >
          Logout
        </li>
      </ul>

      {/* {adminSelector?.adminData?.admin?.role === "admin" ? null : ( */}

      {adminSelector?.adminData?.admin?.role !== "admin" && (
        <button
          onClick={() => {
            dataSelector?.account_status === "pending"
              ? navigate("/dashboard/investment-knowledge")
              : navigate("/dashboard/quest");
          }}
          className="block py-2 px-4 rounded-sm bg-[#85714D] w-[80%] mx-auto withdrawBtn"
          id="deposit-fund"
        >
          Start a Quest
        </button>
      )}

      {/* <MessageModal setModal={setModal} modal={modal} /> */}
    </motion.div>
  );
};

export default Sidebar;
