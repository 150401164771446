import { createSlice } from "@reduxjs/toolkit";
import {
  signInAction,
  signUpAction,
  signInAdminAction,
  socialSignUpUpAction,
  RequestOtpForgotPasswordAction,
  confirmChangePassword,
} from "../actions/authenticationAction";
const initialState = {
  signingInStatus: "idle",
  signingInSuccess: "",
  signingInError: "",

  signingInAdminStatus: "idle",
  signingInAdminSuccess: "",
  signingInAdminError: "",

  signingUpStatus: "idle",
  signingUpSuccess: "",
  signingUpError: "",

  SocialSignUpStatus: "idle",
  SocialSignUpSuccess: "",
  SocialSignUpError: "",

  requestOtpForgotPasswordStatus: "idle",
  requestOtpForgotPasswordSuccess: "",
  requestOtpForgotPasswordError: "",

  confirmChangeOfPasswordStatus: "idle",
  confirmChangeOfPasswordSuccess: "",
  confirmChangeOfPasswordError: "",

  userData: null,
  uerProfile: null,
  adminData: null,
  socialData: null,
  otpForgot: null,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearLoginStatus(state) {
      state.signingInStatus = "idle";
      state.signingInSuccess = "";
      state.signingInError = "";
    },
    clearRegisterStatus(state) {
      state.signingUpStatus = "idle";
      state.signingUpSuccess = "";
      state.signingUpError = "";
    },

    clearAdminLoginStatus(state) {
      state.signingInAdminStatus = "idle";
      state.signingInAdminSuccess = "";
      state.signingUpError = "";
    },

    clearSocialSignUpStatus(state) {
      state.SocialSignUpStatus = "idle";
      state.SocialSignUpSuccess = "";
      state.SocialSignUpError = "";
    },
    clearRequestOtpStatus(state) {
      state.requestOtpForgotPasswordStatus = "idle";
      state.requestOtpForgotPasswordSuccess = "";
      state.requestOtpForgotPasswordError = "";
    },

    clearConfirmChangeOfPasswordStatus(state) {
      state.confirmChangeOfPasswordStatus = "idle";
      state.confirmChangeOfPasswordSuccess = "";
      state.confirmChangeOfPasswordError = "";
    },

    clearUserData(state) {
      state.userData = null;
      state.adminData = null;
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(signInAction.pending, (state) => {
        state.signingInStatus = "loading";
      })
      .addCase(signInAction.fulfilled, (state, { payload }) => {
        state.signingInStatus = "completed";
        // update redux state
        state.userData = payload?.payload;
      })
      .addCase(signInAction.rejected, (state, { payload }) => {
        state.signingInStatus = "failed";

        state.signingInError = payload?.message;
      });

    // ======SIGNING ADMIN STATUS ACTION=======//
    builder
      .addCase(signInAdminAction.pending, (state) => {
        state.signingInAdminStatus = "loading";
      })
      .addCase(signInAdminAction.fulfilled, (state, { payload }) => {
        state.signingInAdminStatus = "completed";
        // update redux state
        state.adminData = payload?.payload;
      })
      .addCase(signInAdminAction.rejected, (state, { payload }) => {
        state.signingInAdminStatus = "failed";

        state.signingInAdminError = payload?.message;
      });

    // ======SIGNING UP STATUS ACTION=======//
    builder
      .addCase(signUpAction.pending, (state) => {
        state.signingUpStatus = "loading";
      })
      .addCase(signUpAction.fulfilled, (state, { payload }) => {
        state.userData = payload?.payload;
        state.signingUpStatus = "completed";
      })
      .addCase(signUpAction.rejected, (state, { payload }) => {
        state.signingUpStatus = "failed";

        state.signingUpError = payload.message;
      });

    builder
      .addCase(socialSignUpUpAction.pending, (state) => {
        state.SocialSignUpStatus = "loading";
      })
      .addCase(socialSignUpUpAction.fulfilled, (state, { payload }) => {
        state.SocialSignUpStatus = "completed";
        state.socialData = payload?.payload;
        state.userData = payload?.payload;
      })
      .addCase(socialSignUpUpAction.rejected, (state, { payload }) => {
        state.SocialSignUpStatus = "failed";

        state.SocialSignUpError = payload.message;
      });

    builder
      .addCase(RequestOtpForgotPasswordAction.pending, (state) => {
        state.requestOtpForgotPasswordStatus = "loading";
      })
      .addCase(
        RequestOtpForgotPasswordAction.fulfilled,
        (state, { payload }) => {
          state.requestOtpForgotPasswordStatus = "completed";
          state.otpForgot = payload?.payload;
        }
      )
      .addCase(
        RequestOtpForgotPasswordAction.rejected,
        (state, { payload }) => {
          state.requestOtpForgotPasswordStatus = "failed";
          state.requestOtpForgotPasswordError = payload.message;
        }
      );

    builder
      .addCase(confirmChangePassword.pending, (state) => {
        state.confirmChangeOfPasswordStatus = "loading";
      })
      .addCase(confirmChangePassword.fulfilled, (state, { payload }) => {
        state.confirmChangeOfPasswordSuccess = payload.message;
        state.confirmChangeOfPasswordStatus = "completed";
      })
      .addCase(confirmChangePassword.rejected, (state, { payload }) => {
        state.confirmChangeOfPasswordStatus = "failed";
        state.confirmChangeOfPasswordError = payload.message;
      });
  },
});

export const {
  clearLoginStatus,
  clearRegisterStatus,
  clearAdminLoginStatus,
  clearUserData,
  clearSocialSignUpStatus,
  clearRequestOtpStatus,
  clearConfirmChangeOfPasswordStatus,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
