import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const AppBtn = ({ onClick, title, className = "", showSpinner = false }) => {
  return (
    <button
      className={
        `btn btn-primary flex items-center
      } ` + className
      }
      onClick={onClick}
    >
      {showSpinner && (
        <ClipLoader size={20} color="#fff" loading={true} className="mr-3" />
      )}

      <span> {title}</span>
    </button>
  );
};

export default AppBtn;
