import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearLoginStatus,
  clearRegisterStatus,
} from "../../redux/reducers/authentication";
import {
  signInAction,
  signUpAction,
} from "../../redux/actions/authenticationAction";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import mobileImg from "../../assets/images/loginMobile.png";
import OtpInput from "react-otp-input";
import AppModal from "../../components/AppModal/AppModal";

const SecondSignUpPage = () => {
  const [loading, setIsLoading] = useState();
  const [otp, setOtp] = useState("");
  const { user, userOtp } = useContext(AuthContext);
  const [state, setState] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (authSelector.signingUpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.signingUpStatus]);

  useEffect(() => {
    if (authSelector.signingUpStatus == "completed") {
      setIsLoading(false);
      navigate("/signup/third");
      return;
    }
    dispatch(clearRegisterStatus());
  }, [authSelector.signingUpStatus]);

  useEffect(() => {
    if (authSelector.signingUpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${authSelector.signingUpError}`);
      return;
    }
    dispatch(clearRegisterStatus());
  }, [authSelector.signingUpStatus]);

  const handleFirstSubmit = () => {
    if (userOtp !== otp) {
      toast.error("Invalid OTP");
      return;
    }

    dispatch(
      signUpAction({
        email: user.email,
        password: user.password,
      })
    );
  };

  return (
    <section>
      <AppModal modal={loading} />
      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
          <img
            src={mobileImg}
            alt=""
            className="w-[100%] h-full object-cover"
          />
        </div>

        <div className="flex flex-col md:items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
          <p className="font-medium text-center lg:text-left text-sm uppercase">
            Please Validate Your Email to Continue Your Rgeistration
          </p>
          <div className="w-[60%] flex justify-between mt-5">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{ width: 18 }}> </span>}
              renderInput={(props) => (
                <input {...props} style={{}} className="otp-field" />
              )}
            />
          </div>

          <div className="w-[100%] flex flex-col justify-center items-center mt-5">
            <button
              className="text-gray-300 w-44 self-center rounded p-2 bg-[#85714D] px-8"
              onClick={handleFirstSubmit}
            >
              Validate OTP
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondSignUpPage;
