import React, { useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoginStatus,
  clearSocialSignUpStatus,
} from "../../redux/reducers/authentication";
import {
  signInAction,
  socialSignUpUpAction,
} from "../../redux/actions/authenticationAction";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/images/googleLogin.png";
import LoginImage from "../../assets/images/premium/loginpage.jpg";

import axios from "axios";
import AppModal from "../AppModal/AppModal";
import logoSvg from "../../assets/svg/logoSvg.svg";

const Login = () => {
  const [loading, setIsLoading] = useState();
  const { setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("invalide email").required("email is required"),
    password: yup
      .string()
      .trim()
      .min(3, "min of 8 characters")
      .required("password is required"),
  });

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (authSelector.signingInStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.signingInStatus]);

  useEffect(() => {
    if (authSelector.signingInStatus == "completed") {
      setAuthState(true);
      setIsLoading(false);
      navigate("/");
      return;
    }
    dispatch(clearLoginStatus());
  }, [authSelector.signingInStatus]);

  useEffect(() => {
    if (authSelector.signingInStatus == "failed") {
      toast.error('Cannot sign in at this moment. Please try again');
      setIsLoading(false);
      return;
    }
    dispatch(clearLoginStatus());
  }, [authSelector.signingInStatus]);

  const handleLogin = (values) => {
    dispatch(signInAction(values));
  };

  // SOCIAL MEDIA lOGIN USEEffECT

  useEffect(() => {
    if (authSelector.SocialSignUpStatus == "loading") {
      setIsLoading(true);
      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "completed") {
      setIsLoading(false);
      setIsLoading(false);
      // authSelector.socialData.account_status.toLowerCase() == "pending";
      setAuthState(true);
      navigate("/");

      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "failed") {
      setIsLoading(false);
      dispatch(clearSocialSignUpStatus());
      return;
    }
  }, [authSelector.SocialSignUpStatus]);

  // useEffect(()=>{

  // },[authSelector.socialData])

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const result = tokenResponse.access_token;

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${result}`
        )
        .then((response) => {
          dispatch(socialSignUpUpAction(response.data.email));
        })
        .catch((e) => {
          // console.log(e);
          setIsLoading(false);
        });
    },
    onError: () => {
      // console.log('Login Failed');

      setIsLoading(false);
    },
    onabort: () => {
      // console.log('Login Aborted');

      setIsLoading(false);
    },
    onNonOAuthError: (err) => {
      // console.log(err);

      setIsLoading(false);
    },
  }
  );

  return (
    <section className="home-bg-image">
      <AppModal modal={loading} />
      <div className="flex max-w-7xl   mx-auto  flex-col md:flex-row gap-4 justify-between">
        <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] relative ">
          <img
            src={LoginImage}
            alt=""
            className="w-[100%] h-full object-cover"
          />
          <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
        </div>

        <div className="flex relative max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
          <Link
            to="/"
            className="absolute top-5 left-12 cursor-pointer"
          >
            <img src={logoSvg} />
          </Link>
          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            return (
              <div className="flex max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  Login
                </p>
                <div className="w-full">
                  <div>
                    <SignupInput
                      type="email"
                      name="email"
                      on_change={handleChange("email")}
                      onBlur={handleBlur("email")}
                      placeholder="Email"
                    />
                    {touched.email && errors.email ? (
                      <p className="errorText">
                        {touched.email && errors.email ? errors.email : ""}
                      </p>
                    ) : null}

                    <SignupInput
                      type="password"
                      name="password"
                      on_change={handleChange("password")}
                      onBlur={handleBlur("password")}
                      placeholder="password"
                    />

                    {touched.password && errors.password ? (
                      <p className="errorText">
                        {touched.password && errors.password
                          ? errors.password
                          : ""}
                      </p>
                    ) : null}

                    <p
                      className="text-gray-400 text-xs text-right cursor-pointer"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password
                    </p>

                    <div className="flex items-end bg-black w-[100%] justify-items-end"></div>
                  </div>
                </div>

                <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                  <button
                    className="text-gray-300 w-32 self-center rounded p-3 bg-[#85714D] px-8"
                    onClick={handleSubmit}
                  >
                    Login
                  </button>

                  <div className="font-bold mt-5">Or</div>
                  <div className="flex flex-col gap-1 mt-8">
                    <div
                      onClick={() => {
                        setIsLoading(true);
                        login();
                      }}
                      className=" flex flex-row items-center cursor-pointer"
                    >
                      <img
                        src={google}
                        alt=""
                        style={{ width: "17", height: 17 }}
                      />
                      <button className="text-gray-300 ml-3 ">
                        Sign In with Google
                      </button>
                    </div>

                    {/* <div className=" flex flex-row items-center mt-3 cursor-pointer">
                      <img
                        src={facebook}
                        alt=""
                        style={{ width: "17", height: 17 }}
                      />
                      <button className="text-gray-300 flex-row ml-3 ">
                        SignIn with Facebook
                      </button>
                    </div> */}
                  </div>



                  <p className="mt-8 text-xs md:text-base">
                    Don’t have an account? Click here to
                    <span
                      onClick={() => navigate("/signup")}
                      style={{
                        color: "#85714D",
                        marginLeft: 5,
                        cursor: "pointer",
                        textTransform: "uppercase",
                      }}
                    >
                      sign up
                    </span>
                  </p>
                </div>
              </div>
            );
          }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default Login;
