// import Bronze from "../assets/newImages/bronze.png";
// import Silver from "../assets/newImages/silver.png";
// import Gold from "../assets/newImages/gold.png";

import Bronze from "../assets/images/premium/bronze.jpg";
import Silver from "../assets/images/premium/silver.jpg";
import Gold from "../assets/images/premium/gold.jpg";

export const questData = [
  {
    title: " Bronze Adventurer: Igniting the Spark",
    content:
      "As a Bronze Adventurer, you'll kickstart your investment journey with enthusiasm and curiosity. This is where your financial adventure begins, equipping you with the basic tools and resources needed to navigate the realm of investments. As a Bronze Adventurer, you'll have the opportunity to earn interest on your investments of up to 9%. It's a solid foundation to build upon as you gain experience and progress further on your journey.",
    image: Bronze,
  },
  {
    title: "  Silver Explorer: Expanding Your Horizons",
    content:
      " After igniting the spark as a Bronze Adventurer, you'll progress to the Silver Explorer class, ready to dive deeper into the investment landscape. As a Silver Explorer, you'll gain access to more advanced tools and resources, empowering you to expand your horizons and uncover new opportunities. Your experience and expertise will be rewarded, as the interest on your investments can go up to an impressive 22%. This increased interest rate reflects the growth and progress you've achieved on your investment journey.",
    image: Silver,
  },
  {
    title: " Gold Trailblazer: Leading the Way to Success",
    content:
      " For those driven by ambition and seeking to reach the pinnacle of investment excellence, the Gold Trailblazer class beckons. As a Gold Trailblazer, you'll have access to the most powerful tools and resources within Omayoza. Your dedication and expertise will be recognized with even greater rewards. As a Gold Trailblazer, you can enjoy interest rates of up to 24% on your investments,showcasing the remarkable heights you've reached on your investment journey. But that's not all. As an exclusive benefit, completing an investment contract as a Gold Trailblazer earns you a unique Non-Fungible Token (NFT) that unlocks up to an additional 5% in interest. This NFT is a symbol of your accomplishment, granting you the opportunity to further amplify your returns and accelerate your wealth accumulation. By harnessing the power of this special NFT, you'll have the potential to unlock a total interest rate of up to 29%. It's a testament to your mastery of the investment realm and your ability to achieve extraordinary results.",
    image: Gold,
  },
];
