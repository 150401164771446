import React from "react";
import { Link, useNavigate } from "react-router-dom";
import twitter from "../assets/images/twitter.png";
import facebook from "../assets/images/facebook.png";
import youtube from "../assets/images/youtube.png";
import discord from "../assets/images/discord.png";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="app-footer px-10">
      <div className="">
        <div className="flex justify-between w-full items-end">
          <div className="flex gap-12 justify-between h-fit items-center">
            <div className="flex gap-3 items-center ">
              <img
                src={youtube}
                alt=""
                className="cursor-pointer"
                style={{ width: 26, height: 18 }}
              />
              <img
                src={discord}
                alt=""
                className="cursor-pointer"
                style={{ width: 32, height: 32 }}
              />
              <img
                src={facebook}
                alt=""
                className="cursor-pointer"
                style={{ width: 32, height: 32 }}
              />
              <img
                src={twitter}
                alt=""
                className="cursor-pointer"
                style={{ width: 26, height: 21 }}
              />
            </div>
          </div>

          <p className="text-center primary-color">
              Copyright &copy; Omayoza {new Date().getFullYear()}
            </p>

          <div>
          <h3 className="footer-head">Contact Us</h3>
          <div className="flex flex-col mt-3">
              <Link to="/faqs">
                <span>FAQs</span>
              </Link>
              <Link to="/faqs">
                <span>Terms and Conditions</span>
              </Link>
              <Link to="/contact-us">
                <span>Contact Us</span>
              </Link>
            </div>
            <h3 className="footer-head mt-3">About Us</h3>
            <div className="flex flex-col">
              <span>About</span>
              <span>Omayoza News</span>
              <span>Careers</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-line" /> */}
      
    </footer>
  );
};

export default Footer;
