import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/appModal.css";
import knowledge from "../../assets/images/knowledge.jpg";
import SignupInput from "../inputs/signup";
import { useDispatch, useSelector } from "react-redux";
import { preContractAction } from "../../redux/actions/preContract";
import PaystackPop from "@paystack/inline-js";
import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
import { createContractAction } from "../../redux/actions/contractsActions";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import DepositeModal from "./DepositeModal";
import ChoosePaymentModal from "./ChoosePaymentModal";

export default function WoodModal({ modalWood, setModalWood }) {
  const preContractSelector = useSelector((state) => state.preContractSlice);
  const [PaymentMethod, setPaymentMethod] = useState("paystack");
  const [depositeModal, setChooseDepositeModal] = useState(false);
  const [modal, setModal] = useState(false);
  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const { setWood, wood } = useContext(AuthContext);
  const [tierId, setTierId] = useState(1);
  const [activeQuestLevel, setActiveQuestLevel] = useState(
    dataSelector?.current_quest_level
  );

  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );

  const navigate = useNavigate();

  const toggleModal = () => {
    setModalWood(!modalWood);
  };

  if (modalWood) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {modalWood && (
        <div className="modal" style={{ zIndex: 9999 }}>
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="bg-[#000] w-[100%] flex flex-col  rounded-md content-center p-10 relative">
              <FaTimes
                size={20}
                className="absolute top-3 right-5 cursor-pointer"
                onClick={toggleModal}
              />
              <p className="text-left">
                Discover the Wood Quest: Your One-time 30-Day Trading Trial with
                a 2% Interest Rate!
              </p>
              <p className="text-sm mt-5 text-left">
                Attention all new traders! Don't miss out on the exclusive Wood
                Quest, a limited-time offer that gives you a 30-day risk-free
                trading experience with a guaranteed 2% interest rate on your
                investments. Once this opportunity is passed up or completed, it
                will no longer be available.
              </p>
              <p className="text-sm mt-5 text-left">
                Sign up the Wood Quest today ans size this one-time chance to
                kickstart your trading journey.
              </p>
              <p className="text-sm mt-5 text-left">Happy trading!</p>
              {/* <p className="text-sm mt-5 text-left">
                Sign up the Wood Quest today ans size this one-time chance to
                kickstart your trading journey. <br /> <br /> 
              </p> */}

              <button
                className="text-gray-300 mt-5 self-center rounded p-3 bg-[#85714D] px-8"
                onClick={() => {
                  setModal(true);
                  setModalWood(false);
                  setActiveQuestLevel(0);
                  setWood(true);
                  setModal(true);
                }}
              >
                Join Wood Quest
              </button>
            </div>
          </div>
        </div>
      )}

      <DepositeModal
        activeQuestLevel={activeQuestLevel}
        tierId={tierId}
        modal={depositeModal}
        PaymentMethod={PaymentMethod}
        setModal={setChooseDepositeModal}
      />

      <ChoosePaymentModal
        modal={modal}
        setModal={setModal}
        PaymentMethod={PaymentMethod}
        setPaymentMethod={setPaymentMethod}
        setChooseDepositeModal={setChooseDepositeModal}
      />
    </>
  );
}
