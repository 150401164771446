import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllStatsDataAction } from "../../../redux/actions/statsActions";
import AppWithrawModal from "../../../components/AppModal/AppWithrawModal";
import {
  getInvestmentHistoryAction,
  getNotificationsAction,
} from "../../../redux/actions/notificationAction";
import ProgressBar from "@ramonak/react-progress-bar";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { getAllContractAdminAction } from "../../../redux/actions/adminAction";
import third from "../../../assets/images/third.jpg";
import first from "../../../assets/images/first.jpg";
import second from "../../../assets/images/second.jpg";
import fourth from "../../../assets/images/fourth.jpg";
import fifth from "../../../assets/images/fifth.jpg";
import Bronze from "../../../assets/newImages/opp1.png";


function DashboardComp(props) {
  const dataSelector = useSelector((state) => state?.statsSlice?.statsData);
  const [contractId, setContractId] = useState();
  const [questLevel, setQuestLevel] = useState();
  const [activeQuest, setActiveQuest] = useState(false);
  const contractSelector = useSelector((state) => state?.adminSlice);

  const investmentHistorySelector = useSelector(
    (state) => state.notifictionSlice.investmentHistory
  );

  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const checker = () => {
    dataSelector?.stats?.some((item) => item?.constract?.status === "ACTIVE") ? setActiveQuest(true) : setActiveQuest(false);
  }

  useEffect(() => {
    checker()
    // console.log("setActiveQuest", activeQuest);
  }, [dataSelector])

  useEffect(() => {
    dispatch(getAllStatsDataAction());
  }, []);

  useEffect(() => {
    dispatch(getAllContractAdminAction());
  }, []);

  useEffect(() => {
    dispatch(getInvestmentHistoryAction());
  }, []);

  if (dataSelector == null) {
    return <></>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // console.log(dataSelector?.stats?.constract, "jjjjjjj");


  return (
    <>
      <div className="w-full h-full ">
        <div className="flex mb-10 pt-5 gap-10">
          <div className="w-[100%]">
            <div className="w-[90%] mx-auto ">
              {/* Empty data */}

              {dataSelector?.stats?.length == 0 ? (
                <Slider {...settings}>
                  <div className="item-container-stats rounded-md">
                    <div className="mb-5 flex justify-between items-center ">
                      <p id="account-tour"> Accounts(₦)</p>

                      <button
                        disabled
                        className="text-gray-300 p-2 rounded-sm cursor-pointer mr-1 bg-[#85714D]  action-btn text-center group relative inline-block"
                        id="withdraw-tour"
                      >
                        <span className="inline-block ml-1"> Withdraw</span>

                        <span className="invisible text-sm inline-block absolute top-5 p-3 whitespace-nowrap group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white  rounded  right-0 mt-10">
                          you dont have any active contract
                        </span>
                      </button>
                    </div>

                    <div className="grid grid-cols-2   md:grid-cols-3 gap-4">
                      <div className="bg-gray-500 flex flex-col rounded-md relative justify-center items-center gap-4 h-[100px] first-bg">
                        <p className="z-40">Initial Captial</p>
                        <h2 className="z-40">₦ 0</h2>

                        <img
                          src={first}
                          className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                        />
                      </div>
                      <div className="bg-gray-500 flex relative flex-col rounded-md justify-center items-center gap-4 h-[100px] second-bg">
                        <p className="z-40">Interest Rate</p>
                        <h2 className="z-40">0 %</h2>

                        <img
                          src={second}
                          className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                        />
                      </div>
                      <div className="bg-gray-500 flex flex-col rounded-md justify-center items-center gap-4 h-[100px] relative">
                        <p className="z-40">Expected earning</p>
                        <h2 className="z-40">₦ 0</h2>
                        <img
                          src={third}
                          className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                        />
                      </div>
                      <div className="bg-gray-500 flex flex-col relative rounded-md justify-center items-center gap-4 h-[100px]">
                        <p className="z-40">Total Earning</p>
                        <h2 className="z-40">₦ 0</h2>

                        <img
                          src={fourth}
                          className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                        />
                      </div>
                      <div className="bg-gray-500 flex relative flex-col rounded-md justify-center items-center gap-4 h-[100px]">
                        <p className="z-40">Contract Term</p>
                        <h2 className="z-40">0 day</h2>
                        <img
                          src={fifth}
                          className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </Slider>
              ) : (
                <Slider {...settings}>

                  {activeQuest === false && 
                  
                  <button
                  disabled={true}
                  className="w-fit text-gray-300 tool-tip p-2 disabled:opacity-50 rounded-sm cursor-pointer mr-1 bg-[#85714D]  action-btn text-center group relative flex"
                  id="withdraw-tour"
                >
                  <span className="inline-block ml-1"> Withdraw</span>
                    <span className="invisible tool-tip  text-sm inline-block absolute top-5 p-3 whitespace-nowrap group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white  rounded  right-36 -mt-5">
                      Hold on! Engage in an Epic Quest to Unlock Withdrawals!
                    </span>
                </button>
                }
                  {dataSelector?.stats?.map((item) => {
                    return (
                      <div className="item-container-stats  transition-transform ease-out duration-500 rounded-md">
                        <div className="mb-5 flex justify-between items-center ">
                          <p id="account-tour"> Accounts(₦)</p>

                          <button
                            disabled={
                              item?.constract?.status === "ACTIVE"
                                ? false
                                : true
                            }
                            onClick={() => {
                              setModal(true);
                              setQuestLevel(item?.constract?.quest_level);
                              setContractId(item?.constract?.id);
                            }}
                            className="text-gray-300 tool-tip p-2 disabled:opacity-50 rounded-sm cursor-pointer mr-1 bg-[#85714D]  action-btn text-center group relative inline-block"
                            id="withdraw-tour"
                          >
                            <span className="inline-block ml-1"> Withdraw</span>
                            {item?.constract?.status === "PENDING" && (
                              <span className="invisible tool-tip  text-sm inline-block absolute top-5 p-3 whitespace-nowrap group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white  rounded  right-36 -mt-5">
                                Admin need to aprove your account before you can
                                withraw
                              </span>
                            )}
                          </button>
                        </div>

                        <div className="grid grid-cols-2   md:grid-cols-3 gap-4">
                          <div className="bg-gray-500 flex relative flex-col rounded-md justify-center items-center gap-4 h-[100px]">
                            <p className="z-40">Initial Captial</p>
                            <h2 className="z-40">
                              ₦
                              {item?.initialCapitalInvested == undefined
                                ? " 0"
                                : new Intl.NumberFormat().format(
                                    item?.initialCapitalInvested
                                  )}
                            </h2>

                            <img
                              src={first}
                              className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                            />
                          </div>
                          <div className="bg-gray-500 flex relative flex-col rounded-md justify-center items-center gap-4 h-[100px] second-bg">
                            <p className="z-40">Interest Rate</p>
                            <h2 className="z-40">
                              {/* {item.constract?.tier?.daily_rate} */}
                              {item.constract?.daily_rate}%
                            </h2>

                            <img
                              src={second}
                              className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                            />
                          </div>
                          <div className="bg-gray-500 flex relative flex-col rounded-md justify-center items-center gap-4 h-[100px] third-bg">
                            <p className="z-40">Expected earning</p>
                            <h2 className="z-40">
                              ₦
                              {item?.expectedEarning == undefined
                                ? " 0"
                                : new Intl.NumberFormat().format(
                                    item?.expectedEarning
                                  )}
                            </h2>

                            <img
                              src={third}
                              className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                            />
                          </div>
                          <div className="bg-gray-500 relative flex flex-col rounded-md justify-center items-center gap-4 h-[100px] fourth-bg">
                            <p className="z-40">Total Earning</p>
                            <h2 className="z-40">
                              ₦{" "}
                              {item.totalEarning == undefined
                                ? "0"
                                : new Intl.NumberFormat().format(
                                    item?.totalEarning
                                  )}
                            </h2>

                            <img
                              src={fourth}
                              className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                            />
                          </div>
                          <div className="bg-gray-500 relative flex flex-col rounded-md justify-center items-center gap-4 h-[100px] fifth-bg">
                            <p className="z-40">Contract Term</p>
                            <h2 className="z-40">{item.termedDuration}</h2>

                            <img
                              src={fifth}
                              className="absolute top-0 right-0 h-[100%] w-[100%] rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
            <div className="flex md:flex-row flex-col mt-10 w-[92%] mx-auto  pt-10 md:pt-0 gap-3 px-2 ">
              <div className="p-4 bg-black rounded  w-[100%]  md:w-[70%] h-[320px] overflow-y-auto flex flex-col gap-3">
                <h2>Quest Progress</h2>
                {contractSelector?.allContractData?.length ? (
                  contractSelector?.allContractData.map((item) => {
                    if (item.status == "NOT_PAID") {
                      return null;
                    }

                    return (
                      <div>
                        <p className="mb-2 text-sm">
                          {item?.status ===
                          "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                            ? "Contract Ended Waiting For Withdrawal"
                            : item.status === "ACTIVE"
                            ? `Active ${item?.quest_level} Adventurer Quest`
                            : item?.status ===
                              "ENDED_NORMALLY_WAITING_FOR_WITHDRAWAL"
                            ? "Contract Ended Waiting For Withdrawal"
                            : null}
                        </p>

                        {item.status === "PENDING" ? (
                          <p className="text-sm">
                            Congratulations on embarking on your quest! Your
                            investment journey is now underway. Hold tight as
                            your request is pending approval by the Cartel. An
                            email will be sent to you as soon as it's approved,
                            unveiling a world of possibilities. Get ready to
                            conquer new heights!
                          </p>
                        ) : (
                          <ProgressBar
                            completed={
                              item?.status ===
                              "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                                ? 100
                                : parseInt(item?.percentage)
                            }
                            bgColor="#85714D"
                            height="10px"
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="text-xs">
                    No Quest Started Yet? Begin Your Adventure and Track Your
                    Investment Journey!
                  </p>
                )}
              </div>

              <div className="p-4 bg-black mb-32 md:mb-0 flex flex-col gap-3 w-[100%] md:w-[30%] rounded h-[320px] ">
                <h2>Upcoming Events</h2>
                <img
                  src={Bronze}
                  className="h-[100px] w-[100%] object-cover"
                  alt=""
                />
                <p className="text-[12px]">
                  Discover upcoming events and seize new opportunities on your
                  investment journey!
                </p>
                {/* <button className="text-gray-300 p-2 mt-8 cursor-pointer bg-[#85714D]  action-btn text-center rounded-sm  w-1/2">
                Stay in the know
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <AppWithrawModal
          contractId={contractId}
          modal={modal}
          setModal={setModal}
          questLevel={questLevel}
        />
      </div>
    </>
  );
}

export default DashboardComp;
