import React, { useState } from "react";
import "../../assets/css/appModal.css";
import AppLoader from "../AppLoader/AppLoader";
// import "./Modal.css";

export default function AppModal({ modal, setModal }) {
  //   const [modal, setModal] = useState(true);

  //   const toggleModal = () => {
  //     setModal(!modal);
  //   };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {/* <button onClick={toggleModal} className="btn-modal">
        Open
      </button> */}

      {modal && (
        <div className="modal" modal={modal} setModal={setModal}>
          <div className="overlay"></div>
          <div className="modal-content">
            <AppLoader />
          </div>
        </div>
      )}
    </>
  );
}
