import React, { useContext, useEffect, useState } from "react";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import google from "../../assets/images/googleLogin.png";
import facebook from "../../assets/images/facebooklogin.png";
import apple from "../../assets/images/appleLogin.png";
import mobileImg from "../../assets/images/loginMobile.png";
import { getToken } from "../../redux/storage";
import { toast } from "react-toastify";
import { requestOtpAction } from "../../redux/actions/otpAction";
import {
  clearOtpData,
  clearRequestOtpStatus,
} from "../../redux/reducers/otpReducer";
import AppModal from "../../components/AppModal/AppModal";

const FirstSignUpPage = () => {
  const [loading, setIsLoading] = useState(false);
  const { setUser, setUserOtp } = useContext(AuthContext);
  const navigate = useNavigate();

  const [state, setState] = useState({});
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("invalide email").required("email is required"),
    password: yup
      .string()
      .trim()
      .min(8, "min of 8 characters")
      .required("password is required"),
  });

  const dispatch = useDispatch();
  const otpSelector = useSelector((state) => state.otpSlice);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "completed") {
      setUserOtp(otpSelector?.otpData?.otp);
      setIsLoading(false);
      navigate("/signup/second");
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    dispatch(clearOtpData());
  }, []);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${otpSelector.requestOtpError}`);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  const handleFirstSubmit = (values) => {
    setUser(values);
    dispatch(
      requestOtpAction({
        email: values.email,
      })
    );
  };

  return (
    <section>
      <AppModal modal={loading} />
      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
          <img
            src={mobileImg}
            alt=""
            className="w-[100%] h-full object-cover"
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFirstSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            return (
              <div className="flex flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  Sign Up
                </p>
                <div className="w-full">
                  <div>
                    <SignupInput
                      type="email"
                      name="email"
                      on_change={handleChange("email")}
                      onBlur={handleBlur("email")}
                      placeholder="Email"
                    />
                    {touched.email && errors.email ? (
                      <p className="errorText">
                        {touched.email && errors.email ? errors.email : ""}
                      </p>
                    ) : null}

                    <SignupInput
                      type="password"
                      name="password"
                      on_change={handleChange("password")}
                      onBlur={handleBlur("password")}
                      placeholder="password"
                    />

                    {touched.password && errors.password ? (
                      <p className="errorText">
                        {touched.password && errors.password
                          ? errors.password
                          : ""}
                      </p>
                    ) : null}

                    <div className="flex items-end bg-black w-[100%] justify-items-end"></div>
                  </div>
                </div>

                <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                  <button
                    className="text-gray-300 w-32 self-center rounded p-3 bg-[#85714D] px-8"
                    onClick={handleSubmit}
                  >
                    Sign Up
                  </button>

                  <p className="mt-8 text-xs md:text-base">
                    Don you have an account? Click here to
                    <span
                      onClick={() => navigate("/login")}
                      style={{
                        color: "#85714D",
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    >
                      login
                    </span>
                  </p>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default FirstSignUpPage;
