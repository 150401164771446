import React from "react";
import "./ValidateMessage.css";
import { ImInfo } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ValidateMessage = ({ onClick = () => {} }) => {
  const navigate = useNavigate();

  const profileData = useSelector((_) => _.profileSlice.profileData);

  // console.log("profileData", profileData);

  if (profileData == null || profileData?.account_status == "active") {
    return null;
  }
  return (
    <button
      onClick={() => {
        navigate("/dashboard/settings");
        onClick();
      }}
      className="custom-message cursor-pointer"
    >
      <span className="flex items-center bg-orange-100 text-orange-400 p-3 rounded-md">
        <ImInfo className="mr-2" />
        Go to settings and update your account
      </span>
    </button>
  );
};

export default ValidateMessage;
