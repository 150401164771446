import React, { useState } from "react";
import SquareRadioInput from "../../inputs/radioButton";
import one from "../../../assets/images/one.png";
import two from "../../../assets/images/two.png";
import three from "../../../assets/images/three.png";
import AppModal from "../../AppModal/AppModal";
import AppBtn from "../../AppBtn/AppBtn";
import checked from "../../../assets/svg/checked.svg";
import unChecked from "../../../assets/svg/unChecked.svg";
import { toast } from "react-toastify";

const Component1 = ({ stepperInfor, setStepperInfo, setActiveStep }) => {
  const [selectedItem, setSelectedItem] = useState(-1);

  const invest_knowledge_data = [
    {
      img: one,
      text: "Academic degree in financial field",
      selected: false,
    },
    {
      img: two,
      text: "Professional certificate or relevant experience",
      selected: false,
    },
    {
      img: three,
      text: "Attended trainings/courses",
      selected: false,
    },
  ];

  const nextScreen = () => {
    if (selectedItem == -1) {
      toast.error(`please select an investment knowledge to continue`);
      return;
    }
    setActiveStep(1);
  };

  return (
    <>
      <AppModal />
      <div className="flex w-full flex-col gap-10  mt-5 px-1  md:px-10 ">
        <div className="font-bold  text-sm md:text-2xl">
          INVESTMENT KNOWLEDGE
        </div>

        <div className="flex flex-col gap-5 bg-[#85714d37] pb:10 md:pb-10 p-5">
          <p>
            Forex investment involves investing currencies. The best investment
            platforms offer investments in dozens of different currencies. If
            you’re new to investing, it’s best to start with a small number of
            currencies in order to minimize risks and keep your investment
            account manageable.
          </p>

          <div className="flex flex-col md:flex-row  gap-2 justify-start md:justify-between">
            {invest_knowledge_data.map((e, i) => (
              <div
                onClick={() => {
                  setSelectedItem(i);
                  setStepperInfo({
                    ...stepperInfor,
                    investment_knowledge: e.text,
                  });
                }}
                key={e.text}
                className="w-full md:w-1/3 flex md:flex-rol flex-col md:flex-col gap-3 cursor-pointer"
              >
                <img
                  src={e.img}
                  alt={e.img}
                  className="w-[100%] object-cover h-[160px] inline-block mt-3 md:mt-0 md:w-[273px]"
                />

                <div className="flex flex-row mt-0 md:mt-5">
                  {selectedItem === i ? (
                    <img src={checked} className="w-[24px] h-[24px]" />
                  ) : (
                    <img src={unChecked} className="w-[24px] h-[24px]" />
                  )}

                  <span className="ml-2 inline-block text-base pl-3 md:pl-0 item-text w-3/4">
                    {e.text}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={nextScreen}
          className="text-gray-300 p-2 mb-24 md:mb-24 rounded-sm cursor-pointer bg-[#85714D]  action-btn text-center group relative inline-block"
        >
          <span className="inline-block ml-1"> Next</span>
        </button>
      </div>
    </>
  );
};

export default Component1;
