import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/dashboard/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommonDataAction } from "../redux/actions/commonActions";
import { FaAlignJustify } from "react-icons/fa";
import logo from "../assets/images/logo.svg";
import "../assets/css/userDashboard.css";

function AdminLayout() {
  const authSelector = useSelector((state) => state.authenticationSlice);
  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCommonDataAction());
  }, []);

  // console.log(dataSelector, "info.....");

  // console.log(authSelector?.userData, "error");
  const [adminData, setAdminData] = useState({
    loading: false,
    username: "Full Name",
    _links: [
      {
        name: "Dashboard",
        link: "/",
      },
      {
        name: "Notifications",
        link: "/admin/notifications",
      },
      {
        name: "Create",
        link: "/admin/create",
      },
      {
        name: "Settings",
        link: "/admin/settings",
      },
    ],
    // Add more state variables as needed
  });

  return (
    // <section className="flex w-full flex-col md:flex-row gap-4 ">
    //   <div className="hidden w-[20%] md:block fixed">
    //     <Sidebar urls={state._links} username={state.username} />
    //   </div>
    //   <div className="w-full ml-[20%] pb-[10%] px-3 md:px-10 overflow-y-auto h-screen">
    //     <Outlet />
    //   </div>
    // </section>

    <section className="userDashboardContainer">
      <div className="mobile px-5">
        <div className="name-container">
          <div>
            <Link to={"/"} className="flex">
             <img src={logo} alt="" className="w-[100px] h-[20px] md:w-[200px] md:h-[100px]" />
             </Link>
            <FaAlignJustify
              className="mt-5"
              size={25}
              onClick={() => setExpanded(!expanded)}
            />
          </div>

          <h5 className="font-bold">
            {dataSelector?.first_name} {dataSelector?.last_name}
          </h5>
        </div>
      </div>
      <div>
        <Sidebar
          urls={adminData._links}
          username={adminData.username}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </div>
      <div className="mainContentWrapper">
        <Outlet />
      </div>
    </section>
  );
}

export default AdminLayout;
