import React, { useEffect, useState } from "react";
import "./adminStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { FaBox, FaEye, FaMoneyBill } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import Select from "react-select";
import { getAllContractAdminAction } from "../../redux/actions/adminAction";
import { acceptDeclineContractAction } from "../../redux/actions/contractsActions";
import {
  clearAcceptDeclineContractStatus,
  clearCloseContractStatus,
} from "../../redux/reducers/contractsReducer";
import AppModal from "../../components/AppModal/AppModal";
import ProgressBar from "@ramonak/react-progress-bar";

function Contracts({ adminContractSelector }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  //   const check = ["ACTIVE", "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"];

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <>
      <div className="mt-10 custom-bg-wrapper">
        <h3 className="mt-5 mb-5">User Details</h3>
        <div className="flex justify-between items-center">
          <div className="admin-item-box">Full Name</div>
          <div className="admin-item-box">Quest</div>
          <div className="admin-item-box">Tier</div>
          <div className="admin-item-box">End Date</div>
          <div className="admin-item-box">Status</div>
          <div className="admin-item-box"></div>
          <div className="admin-item-box"></div>
          <div className="admin-item-box"></div>
        </div>

        {adminContractSelector?.allContractData?.map((item, index) => {
          return (
            <>
              <div className="flex justify-between bottom-margin items-center cursor-pointer">
                <div className="admin-item-box">
                  {item?.user?.first_name} {item?.user?.last_name}
                </div>
                <div className="admin-item-box">{item?.quest_level}</div>
                <div className="admin-item-box">{item?.tier.title}</div>
                <div className="admin-item-box">
                  {item?.tier.duration * 30 - item.day_count}
                </div>
                <div className="admin-item-box">
                  {" "}
                  {item?.status === "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                    ? "Half withrawal"
                    : item?.status}
                </div>

                {item?.status === "PENDING" ? (
                  <button
                    disabled={
                      item?.status === "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                        ? true
                        : false
                    }
                    className="admin-item-box btn-action"
                    onClick={() =>
                      dispatch(
                        acceptDeclineContractAction({
                          contract_id: item.id,
                          action: "accept",
                        })
                      )
                    }
                  >
                    Approve
                  </button>
                ) : (
                  <button
                    disabled
                    className="admin-item-box btn-action bg-[#A2A3A2] text-xs"
                  >
                    Running Contract
                  </button>
                )}

                {item?.status === "PENDING" ? (
                  <button
                    disabled={
                      item?.status === "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                        ? true
                        : false
                    }
                    //   disabled={item?.status == check ? true : false}
                    className="admin-item-box btn-action bg-[#A2A3A2]"
                    onClick={() =>
                      dispatch(
                        acceptDeclineContractAction({
                          contract_id: item.id,
                          action: "decline",
                        })
                      )
                    }
                  >
                    Decline
                  </button>
                ) : (
                  <button
                    disabled
                    className="admin-item-box btn-action bg-[#A2A3A2]"
                  >
                    Running Contract
                  </button>
                )}

                <div className="admin-item-box">
                  <BsChevronDown onClick={() => toggle(index)} />
                </div>
              </div>

              <div
                className={
                  selected === index ? "item-accordian show" : "item-accordian"
                }
              >
                <div className="flex justify-between  bottom-margin middle-box mb-3">
                  <div className="flex-1">
                    <div className="amount-bg">
                      <h4>{item?.user?.totalAmountInvested}</h4>
                      <h5>Total Balance (₦)</h5>
                    </div>
                    <h6 className="mt-5">All Ongoing Contracts</h6>

                    <div className="flex justify-between items-center mt-5 mr-20">
                      <div className="text-xs w-[120px]">Full Name</div>
                      <div className="text-xs w-[120px]">Quest</div>
                      <div className="text-xs w-[120px]">Tier</div>
                      <div className="text-xs w-[120px]">End Date</div>
                    </div>

                    {item?.user?.contracts.map((data) => {
                      // console.log(data, "alllddd.....");
                      return (
                        <div className="flex justify-between  items-center mb-3 mt-2 mr-20">
                          <div className="text-xs w-[120px]">
                            {item?.user?.first_name} {item?.user?.last_name}
                          </div>
                          <div className="text-xs w-[120px]">
                            {item?.quest_level}
                          </div>
                          <div className="text-xs w-[120px]">
                            {item?.tier.title}
                          </div>
                          <div className="text-xs w-[120px]">
                            {" "}
                            {item?.tier.duration * 30 - item.day_count}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="divider"></div>

                  <div className="flex-1">
                    <h2 className="mb-5 font-semibold">Investment History</h2>
                    {item?.user?.contracts?.map((data) => {
                      return (
                        <div className="mb-3">
                          <p className="mb-2 text-xs">
                            {data.status.toLowerCase()} transaction as{" "}
                            {data.quest_level} Adventurer
                          </p>
                          <ProgressBar
                            completed={item?.percentage}
                            height="5px"
                            bgColor="#85714D"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Contracts;
