import React, { useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoginStatus,
  clearSocialSignUpStatus,
} from "../../redux/reducers/authentication";
import {
  confirmChangePassword,
  signInAction,
  socialSignUpUpAction,
} from "../../redux/actions/authenticationAction";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import google from "../../assets/images/googleLogin.png";
import facebook from "../../assets/images/facebooklogin.png";
import axios from "axios";
import AppModal from "../../components/AppModal/AppModal";

const ChangePassword = () => {
  const [loading, setIsLoading] = useState();
  const { setAuthState, getOtp, email } = useContext(AuthContext);

  const navigate = useNavigate();

  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = yup.object({
    password: yup.string().required("Password is required"),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (authSelector.confirmChangeOfPasswordStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.confirmChangeOfPasswordStatus]);

  useEffect(() => {
    if (authSelector.confirmChangeOfPasswordStatus == "completed") {
      setIsLoading(false);
      toast.success(`${authSelector.confirmChangeOfPasswordSuccess}`);
      navigate("/login");
      return;
    }
    dispatch(clearLoginStatus());
  }, [authSelector.confirmChangeOfPasswordStatus]);

  useEffect(() => {
    if (authSelector.confirmChangeOfPasswordStatus == "failed") {
      toast.error(`${authSelector.confirmChangeOfPasswordError}`);
      setIsLoading(false);
      return;
    }
    dispatch(clearLoginStatus());
  }, [authSelector.confirmChangeOfPasswordStatus]);

  const handlePasswordChange = (values) => {
    dispatch(
      confirmChangePassword({
        email: email,
        password: values.password,
        resetPasswordToken: getOtp.resetPasswordToken,
      })
    );
  };

  useEffect(() => {
    if (authSelector.SocialSignUpStatus == "loading") {
      setIsLoading(true);
      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "completed") {
      setIsLoading(false);

      setAuthState(true);
      setIsLoading(false);
      if (authSelector.socialData.account_status.toLowerCase() == "pending") {
        navigate("/signup");
      } else {
        navigate("/");
      }

      dispatch(clearSocialSignUpStatus());
      return;
    }

    if (authSelector.SocialSignUpStatus == "failed") {
      setIsLoading(false);
      dispatch(clearSocialSignUpStatus());
      return;
    }
  }, [authSelector.SocialSignUpStatus]);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const result = tokenResponse.access_token;

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${result}`
        )
        .then((response) => {
          dispatch(socialSignUpUpAction(response.data.email));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onError: () => {
      console.log('Login Failed');

      setIsLoading(false);
    },
    onabort: () => {
      console.log('Login Aborted');

      setIsLoading(false);
    },
    onNonOAuthError: (err) => {
      console.log(err);

      setIsLoading(false);
    },
  });

  return (
    <section className="home-bg-image">
      <AppModal modal={loading} />
      <div className="flex max-w-7xl   mx-auto  flex-col md:flex-row gap-4 justify-between">
        <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] relative ">
          <img src="r101.png" alt="" className="w-[100%] h-full object-cover" />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlePasswordChange}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            return (
              <div className="flex max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  CHANGE PASSWORD
                </p>

                <p className="text-center w-[85%]">
                  Lets try this again, make sure your password is one you will
                  always remember!
                </p>
                <div className="w-full">
                  <div>
                    <SignupInput
                      type="password"
                      name="password"
                      on_change={handleChange("password")}
                      onBlur={handleBlur("password")}
                      placeholder="New Password"
                    />

                    {touched.password && errors.password ? (
                      <p className="errorText">
                        {touched.password && errors.password
                          ? errors.password
                          : ""}
                      </p>
                    ) : null}
                    <SignupInput
                      type="password"
                      name="passwordConfirmation"
                      on_change={handleChange("passwordConfirmation")}
                      onBlur={handleBlur("passwordConfirmation")}
                      placeholder="Confirm Password"
                    />

                    {touched.passwordConfirmation &&
                    errors.passwordConfirmation ? (
                      <p className="errorText">
                        {touched.passwordConfirmation &&
                        errors.passwordConfirmation
                          ? errors.passwordConfirmation
                          : ""}
                      </p>
                    ) : null}

                    <div className="flex items-end bg-black w-[100%] justify-items-end"></div>
                  </div>
                </div>

                <div className="w-[100%] flex flex-col justify-center items-center mt-5">
                  <button
                    className="text-gray-300 w-60 self-center rounded p-3 bg-[#85714D] px-8"
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>

                  <div className="font-bold mt-5">Or</div>
                  <div className="flex flex-col gap-1 mt-8">
                    <div
                      onClick={() => login()}
                      className=" flex flex-row items-center cursor-pointer"
                    >
                      <img
                        src={google}
                        alt=""
                        style={{ width: "17", height: 17 }}
                      />
                      <button className="text-gray-300 ml-3 ">
                        SignIn with Google
                      </button>
                    </div>

                    <div className=" flex flex-row items-center mt-3 cursor-pointer">
                      <img
                        src={facebook}
                        alt=""
                        style={{ width: "17", height: 17 }}
                      />
                      <button className="text-gray-300 flex-row ml-3 ">
                        SignIn with Facebook
                      </button>
                    </div>
                  </div>

                  <p className="mt-8 text-xs md:text-base">
                    Don’t have an account? Click here to
                    <span
                      onClick={() => navigate("/signup")}
                      style={{
                        color: "#85714D",
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    >
                      sign up
                    </span>
                  </p>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default ChangePassword;
