import React, { useEffect, useState } from "react";
import "./adminStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { FaBox, FaEye, FaMoneyBill } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import Select from "react-select";
import { getAllContractAdminAction } from "../../redux/actions/adminAction";
import {
  acceptDeclineContractAction,
  closeContractAction,
} from "../../redux/actions/contractsActions";
import {
  clearAcceptDeclineContractStatus,
  clearCloseContractStatus,
} from "../../redux/reducers/contractsReducer";
import AppModal from "../../components/AppModal/AppModal";
import Contracts from "./Contracts";
import Withrawals from "./Withrawals";
import { getNotificationsAction } from "../../redux/actions/notificationAction";

function AdminDashboard() {
  const adminContractSelector = useSelector((state) => state.adminSlice);
  const contractActionSelector = useSelector((state) => state.contract);
  const [loading, setIsLoading] = useState();
  const [selected, setSelected] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState("Contracts");
  const [selectedOption, setSelectedOption] = useState(null);
  const [contractList, setcontractList] = useState([]);
  // const [lists, setLists] = useState(adminContractSelector?.allContractData);

  const dispatch = useDispatch();

  const filter = ["Withdrawals", "Contracts"];

  // useEffect(() => {
  //   setcontractList(adminContractSelector?.allContractData);
  // }, [adminContractSelector?.allContractData]);

  useEffect(() => {
    dispatch(getAllContractAdminAction());
  }, []);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, []);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  useEffect(() => {
    if (contractActionSelector.acceptDeclineContractStatus == "loading") {
      setIsLoading(true);
      return;
    }

    if (contractActionSelector.acceptDeclineContractStatus == "completed") {
      dispatch(getAllContractAdminAction());
      dispatch(clearAcceptDeclineContractStatus());
      setIsLoading(false);

      // alert("completed");
      return;
    }

    if (contractActionSelector.acceptDeclineContractStatus == "failed") {
      // console.log("failed");
      dispatch(clearAcceptDeclineContractStatus());
      dispatch(clearAcceptDeclineContractStatus());
      setIsLoading(false);
      return;
    }
  }, [contractActionSelector.acceptDeclineContractStatus]);

  // CLOSE CONTRACT USEEFFECT
  useEffect(() => {
    if (contractActionSelector.closeContractStatus == "loading") {
      setIsLoading(true);
      return;
    }

    if (contractActionSelector.closeContractStatus == "completed") {
      dispatch(getAllContractAdminAction());
      dispatch(clearCloseContractStatus());
      setIsLoading(false);

      // alert("completed");
      return;
    }

    if (contractActionSelector.closeContractStatus == "failed") {
      // console.log("failed");
      dispatch(clearCloseContractStatus());
      setIsLoading(false);
      return;
    }
  }, [contractActionSelector.closeContractStatus]);

  // console.log(contractActionSelector.acceptDeclineContractStatus, "connn");
  // console.log(contractActionSelector.closeContractStatus, "zzzzzz");
  // console.log(adminContractSelector.allContractData, "cityyyyy");

  return (
    <>
      {/* <AppModal modal={loading} /> */}
      <div className="mt-10  px-10 mb-10">
        <div className="flex justify-between items-center">
          <h3 className="flex-1">DASHBOARD</h3>

          <div className="dropDown">
            <div
              className="dropDown-btn"
              onClick={(e) => setIsActive(!isActive)}
            >
              <span>{activeItem}</span>

              <BsChevronDown />
            </div>
            {isActive && (
              <div className="dropdown-content">
                {filter.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => {
                        setIsActive(!isActive);
                        setActiveItem(item);
                        // console.log(item.value, "qqqq");
                        // sortData(item.value);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {activeItem === "Withdrawals" ? (
          <Withrawals adminContractSelector={adminContractSelector} />
        ) : (
          <Contracts adminContractSelector={adminContractSelector} />
        )}
      </div>
    </>
  );
}

export default AdminDashboard;
