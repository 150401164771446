import React from "react";
import AppBtn from "../AppBtn/AppBtn";
import { useSelector } from "react-redux";

function ThirtyDays({ withdrawContractSubmit, setModal, setShowBank }) {
  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );
  return (
    <div className="" style={{ textAlign: "left" }}>
      <p className="mt-5">
        🚨 Whoa, Adventurer! Wait for the Quest to Unfold! 🚨{" "}
      </p>
      <p className="mt-5">
        Attention, brave investor! Before you proceed with your swift withdrawal
        maneuver, here's a thrilling revelation for you. If it has been less
        than 30 days since the start of your quest, a daring challenge awaits.
      </p>
      <p className="mt-5">
        Prepare yourself for the penalty that comes with early withdrawals! ⏳
        Timing is Everything: Remember, our quests are designed to reward those
        who embrace the journey. Should you choose to withdraw before completing
        30 days, a 30% penalty on your initial investment shall befall you. But
        fear not, for the path to glory lies in patience!
      </p>
      <p className="mt-5">
        {" "}
        🌟 Embrace the Quest: Allow your investment to flourish and mature,
        soaking in the potential returns that await. By completing the full
        quest, you'll unlock the true essence of financial triumph.
      </p>
      <p className="mt-5">
        💪 Unleash Your Potential: Stay committed, stay determined, and watch
        your wealth soar to new heights. The longer you journey, the greater the
        rewards that await your victorious return!
      </p>
      Now, fellow adventurer,
      <p className="mt-5">
        choose your path wisely. Will you accept the challenge and let your
        investments grow to their full potential, or will you succumb to the
        allure of an early withdrawal?
      </p>
      <p className="mt-5">
        {" "}
        Remember, greatness comes to those who persevere. The choice is yours,
        intrepid explorer! ⚔️💰
      </p>
      <div className="flex justify-between md:flex-row flex-col">
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            If your circumstances demand a swift withdrawal, we understand the
            need for flexibility. While an early withdrawal carries a 30%
            penalty on your initial investment if made within 30 days, we
            respect your choice. Claim your funds and forge a different path,
            knowing that future quests await your return when the time is right.
            Your financial journey is in your hands!
          </p>

          {userContractSelector?.userbank?.acct_name ? (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-8 mb-10"
              onClick={() => {
                withdrawContractSubmit();
                setModal(false);
              }}
            />
          ) : (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-8 mb-10"
              onClick={() => {
                setShowBank(true);
                setModal(false);
              }}
            />
          )}
        </div>
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            If your circumstances demand a swift withdrawal, we understand the
            need for flexibility. While an early withdrawal carries a 30%
            penalty on your initial investment if made within 30 days, we
            respect your choice. Claim your funds and forge a different path,
            knowing that future quests await your return when the time is right.
            Your financial journey is in your hands!
          </p>
          <AppBtn
            onClick={() => setModal(false)}
            title="Seize the moment"
            className="mt-8 mb-10"
          />
        </div>
      </div>
    </div>
  );
}

export default ThirtyDays;
