import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import About from "./pages/About";
import Learn from "./pages/Learn";
import Quest from "./pages/Quest";
import Help from "./pages/Help";
import Faqs from "./pages/Faqs";
import Markets from "./pages/Markets";
import Careers from "./pages/Careers";
import Signup from "./pages/auth_pages/Signup";
import Login from "./components/auth/Login";

import NotFoundPage from "./components/NotFound";

import Setting from "./pages/dashboard/pages/Setting";

import LearnComp from "./pages/dashboard/pages/Learn";
import DashboardComp from "./pages/dashboard/pages/Dashboard";

import UserLayout from "./layouts/UserLayout";

import WebsiteLayout from "./layouts/WebsiteLayout";

import QuestLevel from "./components/dashboard/index/QuestComponent";

import FirstStepper from "./pages/dashboard/pages/FirstStepper";
import { AuthContext } from "./context/AuthContext";
import { getData, getToken } from "./redux/storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "./redux/actions/profileAction";

import AdminLayout from "./layouts/AdminLayout";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminNotification from "./pages/AdminDashboard/AdminNotification";
import AdminCreate from "./pages/AdminDashboard/AdminCreate";
import AdminSettings from "./pages/AdminDashboard/AdminSettings";
import LoginAdmin from "./components/auth/LoginAdmin";
import { getAllContractAdminAction } from "./redux/actions/adminAction";
import FirstSignUpPage from "./pages/auth_pages/FirstSignUpPage";
import SecondSignUpPage from "./pages/auth_pages/SecondSignUpPage";
import ThirdSignUpPage from "./pages/auth_pages/ThirdSignUpPage";
import FourthSignUpPage from "./pages/auth_pages/FourthSignUpPage";
import FifthSignUpPage from "./pages/auth_pages/FifthSignUpPage";
import ForgotPasswordPage from "./pages/auth_pages/ForgotPasswordPage";
import CryptoHook from "./pages/cryptoHook";
import IntroduceYourSelf from "./components/auth/IntroduceYourSelf";
import MoreAboutYou from "./components/auth/MoreAboutYou";
import FinalForm from "./components/auth/FinalForm";
import ConfirmOtpScreen from "./components/auth/ConfirmOtpScreen";
import axiosClient from "./api/axios";
import Tour from "reactour";
import { getAllCommonDataAction } from "./redux/actions/commonActions";
import WoodModal from "./components/AppModal/WoodModal";
import ConfirmOtpForgotPassword from "./pages/auth_pages/ConfirmOtpForgotPassword";
import ChangePassword from "./pages/auth_pages/ChangePassword";
import "./app.css";
import Tacs from "./pages/Tacs";
import ContactUs from "./pages/ContactUs";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const App = () => {
  const token = getToken();
  const [authState, setAuthState] = useState();
  const [formDataInfo, setFormDataInfo] = useState();
  const [seenTour, setseenTour] = useState(true);
  const [showTour, setshowTour] = useState(true);
  const [showWood, setshowWood] = useState(false);
  const [wood, setWood] = useState(false);
  const [getOtp, setGetOtp] = useState({});
  const [email, setEmail] = useState("");

  const [user, setUser] = useState();
  const [userOtp, setUserOtp] = useState();

  const dispatch = useDispatch();
  const authSelector = useSelector(
    (state) => state.authenticationSlice.userData
  );
  const adminSelector = useSelector((state) => state.authenticationSlice);
  const profileSelector = useSelector((state) => state?.profileSlice);

  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const [activeQuestLevel, setActiveQuestLevel] = useState(
    dataSelector?.current_quest_level
  );

  useEffect(() => {
    localStorage.setItem("woodState", JSON.stringify(wood));
  }, []);

  useEffect(() => {
    const savedState = localStorage.getItem("woodState");
    if (savedState) {
      setWood(JSON.parse(savedState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("woodState", JSON.stringify(wood));
  }, [wood]);

  useEffect(() => {
    if (profileSelector?.profileData?.has_seen_tour == 0) {
      setseenTour(false);
    } else {
      setseenTour(true);
    }
  }, [profileSelector.profileData]);

  const closeTour = async () => {
    setshowTour(false);
    await axiosClient().post("tour");
    dispatch(getProfileAction());
    // show wood
    setshowWood(true);

    dispatch(getAllCommonDataAction());
  };


  const tawkMessengerRef = useRef();

  const manuallyTriggerChat = () => {
    tawkMessengerRef.current.maximize();
};

  const steps = [
    {
      // selector: "",
      content: () => (
        <div>
          Welcome to your Omayoza User Dashboard! We're thrilled to have you on{" "}
          <br />
          board and ready to explore the exciting world of secure investments.{" "}
          <br />
          As you navigate through our platform, we want to introduce you to some{" "}
          <br />
          key features that will enhance your investment experience.
        </div>
      ),
      position: ["45%", "45%"],
      style: {
        backgroundColor: "#85714D",
        // width: "20px",
      },
    },
    {
      selector: "#deposit-fund",
      content:
        "Quest Selection: Deposit Funds and Start Your Investment Journey We believe in providing you with a seamless and engaging investment experience. To begin, click on the  ' Start a Quest' button to fund your account. Once you've completed the deposit, you'll be ready to embark on your investment quest. Choose from a range of quests tailored to your investment goals, risk tolerance, and desired duration. Each quest presents a unique opportunity to grow your wealth and uncover hidden investment potentials.",
      style: {
        backgroundColor: "#85714D",
      },
    },
    {
      selector: "#account-tour",
      content:
        "Account Panels: Watch Your Investments Grow Keep a close eye on your investment progress and witness the growth of your accounts through our intuitive Account Panels. These panels provide a comprehensive view of your portfolio, displaying real-time data and insights into your investments. Watch as your funds grow and monitor the performance of your investment quests. With Omayoza, your financial success is at your fingertips.      ",
      style: {
        backgroundColor: "#85714D",
      },
    },
    {
      selector: "#withdraw-tour",
      content:
        "Withdrawal Button: Conveniently Manage Your Funds Once you've started your investment journey and achieved your desired milestones, you may want to access your funds. Our platform offers a hassle-free way to withdraw from your account. Simply click on the Withdrawal Button to initiate the process. We provide flexibility and convenience to cater to your financial needs.",

      style: {
        backgroundColor: "#85714D",
      },
    },
    {
      // selector: "",
      content: () => (
        <div>
          Remember, at Omayoza, we prioritize your security, offer risk-free
          returns, and provide personalized investment options based on your
          user class and experience level. Our platform is designed to empower
          you to make informed investment decisions and achieve your financial
          goals. <br />
          <br />
          If you have any questions or need assistance along the way, our
          dedicated support team is here to help. Feel free to reach out via
          email, phone, or chat. <br />
          Welcome to Omayoza, where your investment success awaits. Let's embark
          on this journey together and unlock the full potential of your
          investments. <br />
          Happy investing!
        </div>
      ),
      position: ["45%", "45%"],
      style: {
        backgroundColor: "#85714D",
        // width: "20px",
      },
    },
  ];

  useLayoutEffect(() => {
    if (token) {
      setAuthState(true);
      return;
    }
  }, [authState]);

  useEffect(() => {
    if (getToken() != null) {
      dispatch(getProfileAction());
    }
  }, []);

  useEffect(() => {
    if (getToken() != null) {
      dispatch(getAllContractAdminAction());
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setAuthState,
        authState,
        setUserOtp,
        userOtp,
        setUser,
        user,
        formDataInfo,
        setFormDataInfo,
        wood,
        setWood,
        getOtp,
        setGetOtp,
        email,
        setEmail,
      }}
    >
      <GoogleOAuthProvider clientId="388514044970-0952pr83snddd8fomi8bcfa8qdbpt0g6.apps.googleusercontent.com">
        {!seenTour && (
          <Tour
            steps={steps}
            isOpen={showTour}
            accentColor="black"
            onRequestClose={closeTour}
            className="custom-tour"
          />
        )}

        <WoodModal
          modalWood={showWood}
          setModalWood={setshowWood}
          setModal={(v) => {}}
          activeQuestLevel={activeQuestLevel}
          setActiveQuestLevel={setActiveQuestLevel}
        />

        <Routes>
          {authState && authSelector?.user.role == "user" && (
            <>
              <Route path="/" element={<UserLayout />}>
                <Route path="/crypto" element={<CryptoHook />} />
                <Route index element={<DashboardComp />} />
                <Route
                  path="/dashboard/investment-knowledge"
                  index
                  element={<FirstStepper />}
                />

                <Route path="/dashboard/quest" element={<QuestLevel />} />
                <Route path="/dashboard/help" element={<Help chatTrigger={manuallyTriggerChat} />} />
                <Route path="/dashboard/learn" element={<LearnComp />} />
                <Route path="/dashboard/settings" element={<Setting />} />

                <Route path="/signup/first" element={<FirstSignUpPage />} />
                <Route path="/signup/second" element={<SecondSignUpPage />} />
                <Route path="/signup/third" element={<ThirdSignUpPage />} />
                <Route path="/signup/fourth" element={<FourthSignUpPage />} />
                <Route path="/signup/fifth" element={<FifthSignUpPage />} />
                <Route path="*" element={<AdminDashboard />} />

                {/* <Route path="*" element={<NotFoundPage />} /> */}
              </Route>
            </>
          )}

          {authState && adminSelector?.adminData?.admin?.role == "admin" && (
            <>
              <Route path="/" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route
                  path="/admin/notifications"
                  element={<AdminNotification />}
                />
                <Route path="/admin/create" element={<AdminCreate />} />
                <Route path="/admin/settings" element={<AdminSettings />} />
                {/* <Route path="*" element={<NotFoundPage />} /> */}
                <Route path="*" element={<AdminDashboard />} />
              </Route>
            </>
          )}

          {!authState && (
            <>
              <Route path="/" element={<WebsiteLayout />}>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/markets" element={<Markets />} />
                <Route path="/learn-with-omayoza" element={<Learn />} />
                <Route path="/about" element={<About />} />
                <Route path="/quests" element={<Quest />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/tacs" element={<Tacs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<Home />} />

              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/login/admin" element={<LoginAdmin />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route
                path="/confirm-otp-forgot"
                element={<ConfirmOtpForgotPassword />}
              />
              <Route
                path="/introduce-yourself"
                element={<IntroduceYourSelf />}
              />
              <Route path="/more-about-you" element={<MoreAboutYou />} />
              <Route path="/confirmOtp" element={<ConfirmOtpScreen />} />
              <Route path="/final-form" element={<FinalForm />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />

              {/* <Route path="*" element={<NotFoundPage />} /> */}
            </>
          )}
        </Routes>

        <ToastContainer />
      </GoogleOAuthProvider>
      <TawkMessengerReact
                propertyId="665c99619a809f19fb37f7d5"
                widgetId="1hvcre9qe"
                ref={tawkMessengerRef}/>
    </AuthContext.Provider>
  );
};

export default App;
