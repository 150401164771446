import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";

const GET_ALL_MY_CONTRACT = "user:GET_ALL_MY_CONTRACT";
const GET_CHECK_CONTRACT_ELIGIBILITY = "user:GET_CHECK_CONTRACT_ELIGIBILITY";
const CREATE_CONTRACT = "user:CREATE_CONTRACT";
const END_CONTRACT = "user:END_CONTRACT";
const ACCEPT_DECLINE_CONTRACT = "admin:ACCEPT_DECLINE_CONTRACT";
const CLOSE_CONTRACT = "admin:CLOSE_CONTRACT";

export const closeContractAction = createAsyncThunk(
  CLOSE_CONTRACT,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("contract/withdrawal", args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getContractdAction = createAsyncThunk(
  GET_ALL_MY_CONTRACT,
  async (args) => {
    const response = await axiosClient().get("contract");
    return response.data;
  }
);

export const getContractEligibiltyAction = createAsyncThunk(
  GET_CHECK_CONTRACT_ELIGIBILITY,
  async (args) => {
    const response = await axiosClient().get("contract/eligibility");
    return response.data;
  }
);

export const createContractAction = createAsyncThunk(
  CREATE_CONTRACT,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("contract/callback", args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const endContractAction = createAsyncThunk(
  END_CONTRACT,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("contract/end", args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const acceptDeclineContractAction = createAsyncThunk(
  ACCEPT_DECLINE_CONTRACT,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().patch("contract", args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
