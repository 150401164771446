import React from "react";
import AppBtn from "../AppBtn/AppBtn";
import { useSelector } from "react-redux";

function GreaterThan60Days({ withdrawContractSubmit, setModal, setShowBank }) {
  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );
  return (
    <div className="" style={{ textAlign: "left" }}>
      <p className="mt-5">
        🔓 The Time Has Come: Unlock Your Well-Earned Riches! 💸{" "}
      </p>
      <p className="mt-5">
      Attention, triumphant investor! The moment you've been waiting for has arrived. After conquering the 60-day mark of your epic quest, you're now poised to unlock the treasures you've amassed. Prepare to savor the sweet taste of victory as you withdraw your hard-earned funds!
      </p>
      <p className="mt-5">
      💰 Reap the Rewards: Your patience and dedication have paid off. Enjoy the fruits of your investment journey and relish in the financial abundance that awaits you. This is the culmination of your strategic prowess and unwavering commitment.
      </p>
      <p className="mt-5">
        {" "}
        🚀 Launch into Freedom: With the 60-day milestone behind you, seize this opportunity to unlock your full potential. Break free from the confines of the quest and let your funds soar to new heights. The time is now, and the possibilities are limitless.
      </p>
      <p className="mt-5">
      🌟 Celebrate Your Success: Raise your banner high and bask in the glory of your achievement. You've emerged victorious, and your financial conquests deserve to be celebrated. Share your triumphs with pride and inspire others to embark on their own quests to greatness.
      </p>
      Now, fellow adventurer,
      <p className="mt-5">
      The moment has come to claim what is rightfully yours. Embrace this exhilarating milestone and relish in the rewards of your investment prowess. You've earned it, brave investor!
      </p>
      <p className="mt-5">
        {" "}
        Happy withdrawing, and may your fortunes continue to flourish! ⚔️💰
      </p>
      <div className="flex justify-between md:flex-row flex-col">
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            If your circumstances demand a swift withdrawal, we understand the
            need for flexibility. While an early withdrawal carries a 30%
            penalty on your initial investment if made within 30 days, we
            respect your choice. Claim your funds and forge a different path,
            knowing that future quests await your return when the time is right.
            Your financial journey is in your hands!
          </p>

          {userContractSelector?.userbank?.acct_name ? (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-8 mb-10"
              onClick={() => {
                withdrawContractSubmit();
                setModal(false);
              }}
            />
          ) : (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-8 mb-10"
              onClick={() => {
                setShowBank(true);
                setModal(false);
              }}
            />
          )}
        </div>
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            If your circumstances demand a swift withdrawal, we understand the
            need for flexibility. While an early withdrawal carries a 30%
            penalty on your initial investment if made within 30 days, we
            respect your choice. Claim your funds and forge a different path,
            knowing that future quests await your return when the time is right.
            Your financial journey is in your hands!
          </p>
          <AppBtn
            onClick={() => setModal(false)}
            title="Seize the moment"
            className="mt-8 mb-10"
          />
        </div>
      </div>
    </div>
  );
}

export default GreaterThan60Days;
