import React from "react";

const AppLoader = () => {
  return (
    <div className="loading">
      <div className="spinner"></div>
      <p className="loaderText">Loading...</p>
    </div>
  );
};

export default AppLoader;
