import React, { useEffect, useState } from "react";
import OtherInfo from "../../../components/auth/OtherInfo";
import { SignupInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import ChangePasswordModal from "../../../components/AppModal/ChangePasswordModal";
import { FaPen } from "react-icons/fa";
import {
  getProfileAction,
  updateProfilePictureAction,
} from "../../../redux/actions/profileAction";
import { FaCamera } from "react-icons/fa";
import { object, string, number, date, InferType } from "yup";
import { toast } from "react-toastify";
import AppModal from "../../../components/AppModal/AppModal";
import axiosClient from "../../../api/axios";

function Setting(props) {
  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const [modal, setModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [realImageFile, setRealImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(dataSelector?.picture || null);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    valid_id: {},
    selected: "",
    // Add more state variables as needed
  });

  const [input, setInput] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    postal: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    setInput({
      first_name: dataSelector?.first_name,
      middle_name: dataSelector?.middle_name,
      last_name: dataSelector?.last_name,
      address: dataSelector?.address,
      postal: dataSelector?.postal,
      phone: dataSelector?.phone,
    });
  }, [dataSelector]);

  const handleChange = (event) => {
    const fileList = event.target.files;
    if (fileList && fileList[0]) {
      const file = fileList[0];
      const imageURL = URL.createObjectURL(file);
      setRealImageFile(file);
      setImagePreviewUrl(imageURL); // This is optional if you need a preview
    }
  };

  const changeProfilePicture = async (e) => {
    e.preventDefault();
  if (!realImageFile) {
    console.error("No image file selected");
    return;
  }

  let formData = new FormData();
  formData.append("image", realImageFile);

  try {
    const response = dispatch(updateProfilePictureAction(formData));
    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`);
    }
    console.log("Profile picture updated successfully");
  } catch (error) {
    console.error("Error updating profile picture:", error);
    // Show an error message to the user
  }
  };

  const updateInfo = async () => {
    try {
      // validate
      let userSchema = object({
        first_name: string().required().min(3),
        last_name: string().required().min(3),
        middle_name: string().required().min(3),
        address: string().required().min(3),
        postal: string().required().min(3),
        phone: string().required().min(3),
        password: string().optional(),
      });

      setIsLoading(true);

      const fd = new FormData();
      fd.append("first_name", input.first_name);
      fd.append("last_name", input.last_name);
      fd.append("middle_name", input.middle_name);
      fd.append("address", input.address);
      fd.append("postal", input.postal);
      fd.append("phone", input.phone);

      if (
        dataSelector?.password?.length == 0 ||
        dataSelector.password == null
      ) {
        fd.append("password", input.password);
      }

      const res = await axiosClient().post("/profile/verify", fd);
      dispatch(getProfileAction());
      toast.success(`Updated Successfully`);
      setIsLoading(false);
    } catch (error) {
      toast.error(`${error?.message || "Input field required"}`);
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-10 px-1 mb-20  md:px-20">
      <AppModal modal={loading} />
      <h4 className="text-lg font-medium">SETTINGS</h4>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="md:w-[90%] w-[100%] mt-7">
        {imagePreviewUrl && <img src={imagePreviewUrl || realImageFile} alt="Image Preview" style={{width: "150px", height: "150px", objectFit: "cover", border: '1px solid #85714D'}} />}

          <form onSubmit={changeProfilePicture}>
          <div className="upload-btn-wrapper flex flex-col items-center justify-center text-center">
            <FaCamera className="btnUpload" size={30} />
            <p>Select Image to change</p>
            <input accept="image/*" type="file" name="image" id="image" onChange={handleChange} />
          </div>
          { imagePreviewUrl && <button
            className="p-4 text-sm bg-[#85714D] rounded mt-5"
          >
            Change Picture
          </button> }
          </form>

          <button
            onClick={() => setModal(true)}
            className="p-4 text-sm bg-[#85714D] rounded mt-5"
          >
            Change Password
          </button>

          <div className="my-6 text-lg font-medium">PAYMENT DETAILS</div>

          <div className="flex  flex-col gap-4">
            <div>
              <span className="text-sm">Account Number</span>
              <SignupInput
                type="text"
                value={dataSelector?.userbank.acct_number}
                disabled={true}
              />
            </div>

            <div>
              <span className="text-sm">Account Name</span>
              <SignupInput
                type="text"
                value={dataSelector?.userbank.acct_name}
                disabled={true}
              />
            </div>

            <div>
              <span className="text-sm">Bank Name</span>
              <SignupInput
                type="text"
                value={dataSelector?.userbank.bank_name}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="">
          <div className="gap-4 flex-col md:flex-row  flex items-center  justify-between my-4">
            <div>
              <span className="text-sm">First Name</span>
              <SignupInput
                type="text"
                name="first_name"
                value={input.first_name}
                on_change={(e) =>
                  setInput({
                    ...input,
                    first_name: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <span className="text-sm">Middle Name</span>
              <SignupInput
                type="text"
                name="middle_name"
                value={input.middle_name}
                on_change={(e) =>
                  setInput({
                    ...input,
                    middle_name: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <span className="text-sm">Last Name</span>
              <SignupInput
                type="text"
                name="last_name"
                value={input.last_name}
                on_change={(e) =>
                  setInput({
                    ...input,
                    last_name: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            {(dataSelector?.password?.length == 0 ||
              dataSelector.password == null) && (
              <div>
                <span className="text-sm">Set An Entry Password</span>
                <SignupInput
                  type="password"
                  value={input.password}
                  on_change={(e) =>
                    setInput({
                      ...input,
                      password: e.target.value,
                    })
                  }
                  name="password"
                  placeholder="password"
                />
              </div>
            )}

            <div>
              <span className="text-sm">Address</span>
              <SignupInput
                type="text"
                value={input.address}
                on_change={(e) =>
                  setInput({
                    ...input,
                    address: e.target.value,
                  })
                }
                name="address"
                placeholder="address"
              />
            </div>

            <div>
              <span className="text-sm">Postal Code</span>
              <SignupInput
                type="text"
                value={input.postal}
                on_change={(e) =>
                  setInput({
                    ...input,
                    postal: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <span className="text-sm">Phone Number</span>
              <SignupInput
                type="text"
                value={input.phone}
                on_change={(e) =>
                  setInput({
                    ...input,
                    phone: e.target.value,
                  })
                }
              />
            </div>

            <button
              onClick={() => updateInfo()}
              className="p-4 text-sm bg-[#85714D] rounded mt-0"
            >
              UPDATE YOUR INFORMATION
            </button>
          </div>
        </div>
      </div>
      <ChangePasswordModal modal={modal} setModal={setModal} />
    </div>
  );
}

export default Setting;
