import { createSlice } from "@reduxjs/toolkit";
import { secondSignUpAction } from "../actions/secondSignUpAction";
const initialState = {
  secondSignUpStatus: "idle",
  secondSignUpSuccess: "",
  secondSignUpError: "",
};

const secondAuthenticationSlice = createSlice({
  name: "second-authentication",
  initialState,
  reducers: {
    clearSecondSignUpStatus(state) {
      state.secondSignUpStatus = "idle";
      state.secondSignUpSuccess = "";
      state.secondSignUpError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING UP STATUS ACTION=======//
    builder
      .addCase(secondSignUpAction.pending, (state) => {
        state.secondSignUpStatus = "loading";
      })
      .addCase(secondSignUpAction.fulfilled, (state) => {
        state.secondSignUpStatus = "completed";
      })
      .addCase(secondSignUpAction.rejected, (state, { payload }) => {
        state.secondSignUpStatus = "failed";

        state.secondSignUpError = payload;
      });
  },
});

export const { clearSecondSignUpStatus } = secondAuthenticationSlice.actions;

export default secondAuthenticationSlice.reducer;
