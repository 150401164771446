import React from "react";
import FaqImage from "../../assets/images/faq-img.svg";

const TacComponent = () => {
  return (
    <div className="mx-auto py-8   md:px-5 mb-20">
      <div className="mb-4 mt-0 md:mt-0 text-4xl  font-semibold">Terms and Conditions Page</div>

      <p>Welcome to Omayoza! These terms and conditions ("Terms") govern your use of the Omayoza platform and services. By accessing or using our platform, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our platform.</p>
      <ol type="1" className="mb-32">
        <li className="mb-5 flex flex-col">
          <span>1. Account Registration</span>

          <p className="ml-5">
            <li>Eligibility: Users must be at least 18 years old and have the legal capacity to enter into a binding agreement.</li>
            <li>Account Information: Users are responsible for providing accurate and up-to-date personal and financial information during the registration process.</li>
          </p>
        </li>

        <li className="mb-5 flex flex-col">
          <span>2. Investment Contracts</span>

          <p className="ml-5">
          <li>Investment Selection: Users can choose from various investment contracts ("quests") with different durations and interest rates.</li>
          <li>Contract Modification: Investment contracts cannot be modified once submitted.</li>
          <li>Investment Performance: Returns on investments are subject to market conditions and may fluctuate.</li>

          </p>
        </li>

        <li className="mb-5 flex flex-col">
          <span>3. User Classes and Investment Limits</span>

          <p className="ml-5">
            <li>User Classes: Users are grouped into three classes - Bronze, Silver, and Gold - based on their investment history and account activity.</li>
            <li>Investment Limits: The number of concurrent investment contracts allowed depends on the user's class: Bronze (up to 3 contracts), Silver (up to 5 contracts), and Gold (up to 7 contracts).</li>
          </p>
        </li>
        <li className="mb-5 flex flex-col">
          <span>4. Withdrawals and Penalties</span>

          <p className="ml-5">
            <li>Withdrawal Eligibility: Users can request withdrawals after 60 days from the start of the investment contract.</li>
            <li>Penalties: Early withdrawals before 60 days are subject to a penalty of 30% of the initial investment. After 60 days, withdrawals are subject to a penalty of 10% of the initial investment.</li>
            <li>Full Withdrawals: Partial withdrawals are not permitted. Users must withdraw the entire investment amount.</li>
          </p>
        </li>
        <li className="mb-5 flex flex-col">
          <span>
            5. Compliance and Security
          </span>

          <p className="ml-5">
            <li>Compliance: Users must comply with applicable laws and regulations, including anti-money laundering (AML) and know your customer (KYC) requirements.</li>
            <li>Security: The platform implements advanced security measures to protect user information and funds.</li>
          </p>
        </li>
        <li className="mb-5 flex flex-col">
          <span>6. Termination</span>

          <p className="ml-5">
            <li>Termination by User: Users can terminate their account at any time by following the account closure process specified on the platform.</li>
            <li>Termination by Omayoza: The platform reserves the right to terminate or suspend user accounts for violation of the terms or any fraudulent or suspicious activity.</li>
          </p>
        </li>
        <li className="mb-5 flex flex-col">
          <span>7. Governing Law and Dispute Resolution</span>

          <p className="ml-5">
            <li>Governing Law: The terms and conditions are governed by the laws of Nigeria.</li>
            <li>Dispute Resolution: Any disputes arising from the terms and conditions will be resolved through arbitration in accordance with the rules of Nigerian Institute of Chartered Arbitrators (NICArb) in Nigeria.</li>
          </p>
        </li>

        <li className="mb-5 flex flex-col">
          <span>8. Amendments</span>

          <p className="ml-5">
            <li>The platform reserves the right to modify or update the terms and conditions at any time. Users are responsible for reviewing them periodically for any updates.</li>
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TacComponent;
