import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const REQUEST_OTP = "user:CONFIRM_OTP";

export const requestOtpAction = createAsyncThunk(
  REQUEST_OTP,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`otp/email/validate`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
