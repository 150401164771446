import React, { useContext, useEffect, useState } from "react";
import { SignupInput } from "../../components";
import authImage from "../../assets/images/authImage.png";
import logoSvg from "../../assets/svg/logoSvg.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { requestOtpAction } from "../../redux/actions/otpAction";
import {
  clearOtpData,
  clearRequestOtpStatus,
} from "../../redux/reducers/otpReducer";
import AppModal from "../AppModal/AppModal";
import TermsOfServiceModal from "../AppModal/TermsOfServiceModal";
import AppBtn from "../AppBtn/AppBtn";

const SignupComp = ({ setUserOtp }) => {
  const { formDataInfo, setFormDataInfo } = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("FirstName is required"),
    last_name: yup.string().required("LastName is required"),
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const otpSelector = useSelector((state) => state.otpSlice);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "completed") {
      setUserOtp(otpSelector?.otpData?.otp);
      setIsLoading(false);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    dispatch(clearOtpData());
  }, []);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${otpSelector.requestOtpError}`);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  const handleSecondSubmit = (values) => {
    navigate("/more-about-you");

    setFormDataInfo({
      ...formDataInfo,
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
    });
  };

  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex max-w-[1700px]   mx-auto  flex-col md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] hidden md:block">
            <img
              src={authImage}
              alt=""
              className="w-[100%] h-full object-cover"
            />
            <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSecondSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <div className="flex max-w-xl mx-auto flex-col relative items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
                  <Link
                    to="/"
                    className="absolute top-10 left-12 cursor-pointer md:top-5"
                  >
                    <img src={logoSvg} />
                  </Link>
                  <div className="bg-[#85714d37] rounded-md p-5 pt-20 md:pt-10 ">
                    <p className="font-bold text-center lg:text-left text-2xl uppercase">
                      Sign Up
                    </p>

                    <p className="text-sm text-center mt-5">
                      To get started, we kindly request the following
                      information to create your account:
                    </p>
                    <div className="w-full">
                      <div>
                        <SignupInput
                          type="text"
                          name="first_name"
                          on_change={handleChange("first_name")}
                          onBlur={handleBlur("first_name")}
                          placeholder="firstName"
                        />
                        {touched.first_name && errors.first_name ? (
                          <p className="errorText">
                            {touched.first_name && errors.first_name
                              ? errors.first_name
                              : ""}
                          </p>
                        ) : null}
                        <SignupInput
                          type="text"
                          name=" middle_name:"
                          on_change={handleChange("middle_name")}
                          onBlur={handleBlur("middle_name")}
                          placeholder="Middle Name"
                        />

                        <SignupInput
                          type="text"
                          name="last_name"
                          on_change={handleChange("last_name")}
                          onBlur={handleBlur("last_name")}
                          placeholder="Last Name"
                        />

                        {touched.last_name && errors.last_name ? (
                          <p className="errorText">
                            {touched.last_name && errors.last_name
                              ? errors.last_name
                              : ""}
                          </p>
                        ) : null}

                        {/* <div className="flex items-end bg-black w-[100%] justify-items-end"></div> */}
                      </div>
                      <div className="mt-5 flex items-center">
                        <AppBtn
                          title="Back"
                          className="mobile-btn"
                          onClick={() => navigate("/signup")}
                        />{" "}
                        <AppBtn
                          title="Next"
                          className="mobile-btn ml-3"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default SignupComp;
