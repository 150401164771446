import React, { useState } from "react";
import Component1 from "../../../components/dashboard/index/component1";
import Component2 from "../../../components/dashboard/index/component2";
import Component3 from "../../../components/dashboard/index/Component3";
import Component4 from "../../../components/dashboard/index/Component4";
import { useSelector } from "react-redux";

function FirstStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [stepperInfor, setStepperInfo] = useState({
    investment_knowledge: "",
    purpose_of_investment: "",
    risk_evaluation_amount: "50k-100k",
    risk_evaluation_expectation: "",
    finance_source_of_income: "",
    finance_occupation: "",
    finance_company: "",
    finance_net_annual_income: "",
  });

  return (
    <>
      {activeStep === 0 && (
        <Component1
          stepperInfor={stepperInfor}
          setStepperInfo={setStepperInfo}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 1 && (
        <Component2
          stepperInfor={stepperInfor}
          setStepperInfo={setStepperInfo}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 2 && (
        <Component3
          stepperInfor={stepperInfor}
          setStepperInfo={setStepperInfo}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 3 && (
        <Component4
          stepperInfor={stepperInfor}
          setStepperInfo={setStepperInfo}
          setActiveStep={setActiveStep}
        />
      )}
    </>
  );
}

export default FirstStepper;
