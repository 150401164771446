import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const POST_REGISTRATION = "POST_REGISTRATION";

export const postRegistrationAction = createAsyncThunk(
  POST_REGISTRATION,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`register/finalise`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
