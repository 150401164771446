import React, { useState } from "react";
import SquareRadioInput from "../../inputs/radioButton";
import checked from "../../../assets/svg/checked.svg";
import unChecked from "../../../assets/svg/unChecked.svg";
import { toast } from "react-toastify";

const Component3 = ({ stepperInfor, setStepperInfo, setActiveStep }) => {
  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState({
    _invest_knowledge_data: [
      {
        top: "80%",
        bottom: "20%",
        type: "50k-100k",
      },
      {
        top: "60%",
        bottom: "40%",
        type: "100k-200k",
      },
      {
        top: "50%",
        bottom: "50%",
        type: "200k-500k",
      },
      {
        top: "10%",
        bottom: "90%",
        type: "500k-1M",
      },
      {
        top: "",
        bottom: "",
        type: "1M - Above",
      },
    ],
  });

  const handleChange = (event) => {
    setStepperInfo({
      ...stepperInfor,
      risk_evaluation_amount: event.target.value,
    });
  };

  const nextScreen = () => {
    if (selectedItem == -1) {
      toast.error(` please select the percentage you be willing risk in your
      investments`);
      return;
    }
    setActiveStep(3);
  };

  return (
    <div className="flex flex-col gap-10 px-1  md:px-10   mt-5 md:w-[50%] w-[100%]">
      <div className="font-bold text-sm">RISK EVALUATION</div>
      <div className="flex flex-col gap-5 ">
        <p className="text-xs">What are you willing to risk?</p>

        <select
          onChange={handleChange}
          className="w-full bg-transparent border border-[#85714d] p-4  focus:outline-none"
        >
          <option value="50k-100k">50k-100k</option>
          {state._invest_knowledge_data.map((data, index) => (
            <option
              key={index}
              value={data.type}
              className="text-gray-800 bg-white hover:bg-gray-200 w-1/3"
            >
              {data.type}
            </option>
          ))}
        </select>

        <p className="text-xs">
          What percentage would you be willing risk in your investments?
        </p>

        <div className="flex gap-4 w-full">
          {state._invest_knowledge_data.map((e, i) =>
            i == 4 ? null : (
              <div
                onClick={() => {
                  setSelectedItem(i);
                  setStepperInfo({
                    ...stepperInfor,
                    risk_evaluation_expectation: `${e.bottom} - ${e.top}`,
                  });
                }}
                key={i}
                className="flex flex-col gap-3 w-1/4"
              >
                <div className="bg-[#85714d] p-4 text-center">{e.top}</div>
                <div className="bg-[#0d0d0d] p-4 text-center">{e.bottom}</div>
                <div className="flex flex-row mt-5 mb-10 w-full items-center justify-center">
                  {selectedItem === i ? (
                    <img src={checked} />
                  ) : (
                    <img src={unChecked} />
                  )}
                </div>
              </div>
            )
          )}
        </div>

        <div className="flex flex-row">
          <button
            onClick={() => setActiveStep(1)}
            className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn"
          >
            Back
          </button>

          <button
            onClick={nextScreen}
            className="text-gray-300 p-2 rounded-sm cursor-pointer bg-[#85714D] ml-9 action-btn text-center group relative inline-block"
          >
            <span className="inline-block ml-1"> Next</span>
          </button>

          {/* <button
            disabled={selectedItem == -1 ? true : false}
            onClick={() => {
              validateDropdown();
              setActiveStep(3);
            }}
            className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn ml-9"
          >
            Next
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Component3;
