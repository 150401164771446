import { createSlice } from "@reduxjs/toolkit";
import {
  getProfileAction,
  changePassWordAction,
  changePasswordOtpAction,
  updateProfilePictureAction,
} from "../actions/profileAction";
const initialState = {
  getProfileStatus: "idle",
  getProfileSuccess: "",
  getProfileError: "",

  changePasswordStatus: "idle",
  changePasswordSuccess: "",
  changePasswordError: "",

  changePasswordOtpStatus: "idle",
  changePasswordOtpSuccess: "",
  changePasswordOtpError: "",

  updateProfilePictureStatus: "idle",
  updateProfilePictureSuccess: "",
  updateProfilePictureError: "",

  profileData: null,
  otpData: null,
  profilePictureData: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearGetProfileStatus(state) {
      state.getProfileStatus = "idle";
      state.getProfileSuccess = "";
      state.getProfileError = "";
    },

    clearChangePasswordStatus(state) {
      state.changePasswordStatus = "idle";
      state.changePasswordSuccess = "";
      state.changePasswordError = "";
    },

    clearChangePasswordOtpStatus(state) {
      state.changePasswordOtpStatus = "idle";
      state.changePasswordOtpSuccess = "";
      state.changePasswordOtpError = "";
    },
    getProfile(state) {
      return state.getProfile;
    },

    // getProfile(state) {
    //   state.profileData;
    // },

    clearProfilePictureStatus(state) {
      state.updateProfilePictureStatus = "idle";
      state.updateProfilePictureSuccess = "";
      state.updateProfilePictureError = "";
    },
  },

  extraReducers: (builder) => {
    // ======GET PROFILE STATUS=======//
    builder
      .addCase(getProfileAction.pending, (state) => {
        state.getProfileStatus = "loading";
      })
      .addCase(getProfileAction.fulfilled, (state, { payload }) => {
        state.getProfileStatus = "completed";
        // update redux state
        state.profileData = payload.payload;
      })
      .addCase(getProfileAction.rejected, (state, { payload }) => {
        state.getProfileStatus = "failed";
        state.getProfileError = payload;
      });

    // ======GCHANGE PASSWORD STATUS =======//
    builder
      .addCase(changePassWordAction.pending, (state) => {
        state.changePasswordStatus = "loading";
      })
      .addCase(changePassWordAction.fulfilled, (state) => {
        state.changePasswordStatus = "completed";
      })
      .addCase(changePassWordAction.rejected, (state, { payload }) => {
        state.changePasswordStatus = "failed";
        // console.log(payload);
        state.changePasswordError = payload.message;
      });

    // ======CHANGE PASSWORD OTP STATUS=======//
    builder
      .addCase(changePasswordOtpAction.pending, (state) => {
        state.changePasswordOtpStatus = "loading";
      })
      .addCase(changePasswordOtpAction.fulfilled, (state, { payload }) => {
        state.changePasswordOtpStatus = "completed";
        state.otpData = payload.payload;
      })
      .addCase(changePasswordOtpAction.rejected, (state, { payload }) => {
        state.changePasswordOtpStatus = "failed";
        state.changePasswordOtpError = payload;
      });

    // ======END CONTRACT ELIGIBILITY=======//
    builder
      .addCase(updateProfilePictureAction.pending, (state) => {
        state.updateProfilePictureStatus = "loading";
      })
      .addCase(updateProfilePictureAction.fulfilled, (state, { payload }) => {
        state.updateProfilePictureStatus = "completed";
      })
      .addCase(updateProfilePictureAction.rejected, (state, { payload }) => {
        state.updateProfilePictureStatus = "failed";
        state.updateProfilePictureError = payload;
      });
  },
});

export const {
  clearCreateContractStatus,
  clearGetContractStatus,
  clearContractEligibilityStatus,
  clearEndContractStatus,
  clearChangePasswordStatus,
  clearChangePasswordOtpStatus,
  getProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
