import React, { useEffect, useState } from "react";
import "./adminStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { FaBox, FaEye, FaMoneyBill } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import Select from "react-select";
import { getAllContractAdminAction } from "../../redux/actions/adminAction";
import {
  acceptDeclineContractAction,
  closeContractAction,
} from "../../redux/actions/contractsActions";
import {
  clearAcceptDeclineContractStatus,
  clearCloseContractStatus,
} from "../../redux/reducers/contractsReducer";
import AppModal from "../../components/AppModal/AppModal";
import ProgressBar from "@ramonak/react-progress-bar";

function Withrawals({ adminContractSelector }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  //   console.log(adminContractSelector?.allContractData, "from ...... withraws");

  return (
    <>
      <div className="mt-10 custom-bg-wrapper">
        <h3 className="mt-5 mb-5 font-semibold">Withdrawals</h3>
        <div className="flex justify-between items-center">
          <div className="admin-item-box">Full Name</div>
          <div className="admin-item-box">Quest</div>
          <div className="admin-item-box">Tier</div>
          <div className="admin-item-box">End Date</div>
          <div className="admin-item-box">Status</div>
          <div className="admin-item-box"></div>
          <div className="admin-item-box"></div>
        </div>

        {}

        {adminContractSelector?.allContractData?.map((item, index) => {
          return (
            (item?.status === "COMPLETED" ||
              item?.status === "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL" ||
              item?.status === "ENDED_NORMALLY_WAITING_FOR_WITHDRAWAL") && (
              <>
                <div className="flex justify-between bottom-margin items-center cursor-pointer">
                  <div className="admin-item-box">
                    {item?.user?.first_name} {item?.user?.last_name}{" "}
                  </div>
                  <div className="admin-item-box">{item?.quest_level}</div>
                  <div className="admin-item-box">{item?.tier.title}</div>
                  <div className="admin-item-box">
                    {item?.tier.duration * 30 - item.day_count}
                  </div>
                  <div className="admin-item-box">
                    {item?.status === "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                      ? "Half withrawal"
                      : item?.status}
                  </div>

                  <button
                    style={{
                      backgroundColor:
                        item?.status === "COMPLETED" && " #A7A7A7",
                    }}
                    disabled={item?.status === "COMPLETED" ? true : false}
                    className="admin-item-box btn-action"
                    onClick={() =>
                      dispatch(
                        closeContractAction({
                          contract_id: item.id,
                          action: "paid",
                        })
                      )
                    }
                  >
                    {item?.status === "COMPLETED"
                      ? "Contract Closed"
                      : "Close Contract"}
                  </button>

                  <div className="admin-item-box">
                    <BsChevronDown onClick={() => toggle(index)} />
                  </div>
                </div>

                <div
                  className={
                    selected === index
                      ? "item-accordian show"
                      : "item-accordian"
                  }
                >
                  <div className="flex justify-between  bottom-margin middle-box mb-3">
                    <div className="flex-1">
                      <div className="amount-bg">
                        <h4>{item?.user?.totalAmountInvested}</h4>
                        <h5>Total Balance (₦)</h5>
                      </div>
                      <h6 className="mt-5">All Ongoing Contracts</h6>

                      <div className="flex justify-between items-center mt-5 mr-20">
                        <div className="text-xs w-[120px]">Full Name</div>
                        <div className="text-xs w-[120px]">Quest</div>
                        <div className="text-xs w-[120px]">Tier</div>
                        <div className="text-xs w-[120px]">End Date</div>
                      </div>

                      {item?.user?.contracts.map((data) => {
                        return (
                          <div className="flex justify-between  items-center mb-3 mt-2 mr-20">
                            <div className="text-xs w-[120px]">
                              {item?.user?.first_name} {item?.user?.last_name}
                            </div>
                            <div className="text-xs w-[120px]">
                              {item?.quest_level}
                            </div>
                            <div className="text-xs w-[120px]">
                              {item?.tier.title}
                            </div>
                            <div className="text-xs w-[120px]">
                              {" "}
                              {item?.tier.duration * 30 - item.day_count}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="divider"></div>

                    <div className="flex-1">
                      <h2 className="mb-5 font-semibold">Investment History</h2>
                      {item?.user?.contracts?.map((data) => {
                        return (
                          <div className="mb-3">
                            <p className="mb-2 text-xs">
                              {data.status.toLowerCase()} transaction as{" "}
                              {data.quest_level} Adventurer
                            </p>
                            <ProgressBar
                              completed={item?.percentage}
                              height="5px"
                              bgColor="#85714D"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )
          );
        })}
      </div>
    </>
  );
}

export default Withrawals;
