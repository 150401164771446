import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";

const GET_PROFILE = "user:GET_PROFILE";
const CHANGE_OTP_PASSWORD = "user:CHANGE_OTP_PASSWORD";
const CHANGE_PASSWORD = "user:CHANGE_PASSWORD";
const UPDATE_PROFILE_PICTURE = "user:UPDATE_PROFILE_PICTURE";

export const getProfileAction = createAsyncThunk(
  GET_PROFILE,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().get("profile");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const changePasswordOtpAction = createAsyncThunk(
  CHANGE_OTP_PASSWORD,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("otp/email", args);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changePassWordAction = createAsyncThunk(
  CHANGE_PASSWORD,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().put("profile/password", args);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateProfilePictureAction = createAsyncThunk(
  UPDATE_PROFILE_PICTURE,

  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post("profile/update-image", args);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response);
    }
  }
);
