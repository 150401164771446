import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const PRE_CONTRACT = "authentication:PRE_CONTRACT";
export const preContractAction = createAsyncThunk(
  PRE_CONTRACT,
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosClient().post(`contract`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
