import React, { useContext, useEffect, useState } from "react";
import authImage from "../../assets/images/authImage.png";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import AppModal from "../AppModal/AppModal";
import AppBtn from "../AppBtn/AppBtn";
import OTPInput from "react-otp-input";
import { signUpAction } from "../../redux/actions/authenticationAction";
import { requestOtpAction } from "../../redux/actions/otpAction";

const FinalForm = () => {
  const [loading, setIsLoading] = useState();
  const [otp, setOtp] = useState("");
  const { formDataInfo, setFormDataInfo, setAuthState } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.authenticationSlice);

  useEffect(() => {
    if (authSelector.signingUpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.signingUpStatus]);

  useEffect(() => {
    if (authSelector.signingUpStatus == "completed") {
      setIsLoading(false);
      setAuthState(true);

      navigate("/");
      // window.location.href = "/";
      return;
    }
    dispatch(clearRegisterStatus());
  }, [authSelector.signingUpStatus]);

  useEffect(() => {
    if (authSelector.signingUpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${authSelector.signingUpError}`);
      return;
    }
    dispatch(clearRegisterStatus());
  }, [authSelector.signingUpStatus]);
  
  const resendOtp = async () => {
    dispatch(
      requestOtpAction({
        email: formDataInfo.email,
        first_name: formDataInfo.first_name,
      })
    );
  };
  const handleFinalSubmit = () => {
    if (formDataInfo.otp.otp !== otp) {
      toast.error("Invalid OTP");
      return;
    }

    let formData = new FormData();
    formData.append("doc", formDataInfo.doc);
    formData.append("email", formDataInfo.email);
    formData.append("password", formDataInfo.password);
    formData.append("first_name", formDataInfo.first_name);
    formData.append("middle_name", formDataInfo.middle_name);
    formData.append("last_name", formDataInfo.last_name);
    formData.append("phone", formDataInfo.phone);
    formData.append("postal", formDataInfo.postal);
    formData.append("address", formDataInfo.address);

    dispatch(signUpAction(formData));
  };


  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex flex-col   max-w-[1700px]   mx-auto   md:flex-row gap-4 justify-between">
          <div className="h-[20vh] relative w-full md:w-1/2 md:min-h-[100vh] hidden md:block">
            <img
              src={authImage}
              alt=""
              className="w-[100%] h-full object-cover"
            />
            <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
          </div>

          <div className="flex max-w-xl mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
            <div className=" ">
              <div className="bg-[#85714d37] rounded-md p-5 pt-10 flex flex-col justify-center items-center">
                <p className="font-bold text-center lg:text-left text-2xl uppercase">
                  Verify Account
                </p>

                <p className="text-center mt-5">
                  Thank you for submitting your information and ID snapshot. We
                  take your account security seriously and have sent a
                  verification code to the email address associated with your
                  account. Please check your inbox and locate the email from
                  Omayoza. The email contains a unique verification code that
                  you will need to enter on our platform. Kindly note that this
                  verification code is valid for one hour only.”
                </p>
                <div className="mt-10">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span style={{ width: 18 }}> </span>}
                    renderInput={(props) => (
                      <input {...props} style={{}} className="otp-field" />
                    )}
                  />
                </div>

                <div className="flex gap-4">
                {
                  formDataInfo?.email && formDataInfo?.first_name &&
                  <AppBtn
                  title="Resend OTP"
                  className="mobile-btn ml-3 mt-10"
                  onClick={resendOtp}
                />
                }

                <AppBtn
                  title="Submit"
                  className="mobile-btn ml-3 mt-10"
                  onClick={handleFinalSubmit}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinalForm;
