import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBtn from "../../components/AppBtn/AppBtn";
import UploadBlogVideoModal from "../../components/AppModal/UploadBlogVideoModal";
import UploadVideoModal from "../../components/AppModal/UploadVideoModal";
// import UploadVideoModal from "../../components/AppModal/UploadVideoModal";

function AdminCreate(props) {
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className="mt-10 px-20">
        <div className="flex justify-between items-center">
          <h3>UPLOADS</h3>

          <AppBtn title="Uploads" onClick={() => setModal(true)} />
        </div>
        <div className="grid grid-cols-4 gap-4 mt-5">
          <div className="bg-slate-900 h-[161px]">video 1</div>
          <div className="bg-slate-900 h-[161px]">video 2</div>
          <div className="bg-slate-900 h-[161px]">video 3</div>
          <div className="bg-slate-900 h-[161px]">video 4</div>
        </div>
      </div>
      <UploadBlogVideoModal modal={modal} setModal={setModal} />
    </>
  );
}

AdminCreate.propTypes = {};

export default AdminCreate;
