import React, { useEffect, useState } from "react";
import "../../assets/css/appModal.css";
// import SignupInput from "../inputs/signup";
// import { useDispatch, useSelector } from "react-redux";
// import { preContractAction } from "../../redux/actions/preContract";
// import PaystackPop from "@paystack/inline-js";
// import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
// import { createContractAction } from "../../redux/actions/contractsActions";
// import AppModal from "./AppModal";
// import { getAllStatsDataAction } from "../../redux/actions/statsActions";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import { getProfileAction } from "../../redux/actions/profileAction";
// import axios from "axios";
// import { APP_CONFIG } from "../../config/settings";
import checked from "../../assets/svg/checked.svg";
import SignupInput from "../inputs/signup";

// import unChecked from "../../assets/svg/unChecked.svg";

export default function ChoosePaymentModal({
  tierId,
  setModal,
  modal,
  activeQuestLevel,
  setPaymentMethod,
  setChooseDepositeModal,
}) {
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const [cryptoText, setCryptoText] = useState("Deposit with Cryptocurrency");
  const [selected, setSelected] = useState(-1);

  return (
    <>
      {modal && selected == -1 && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content  w-[90%]">
            <div className=" bg-black w-[70%] px-5 py-10 ">
              <h3 className="deposite-header">Select Payment Method</h3>

              <div className="flex flex-col gap-5 w-full">
                <div
                  className="flex items-center gap-3 mt-5 w-full"
                  onClick={() => {
                    setSelected(1);
                  }}
                >
                  <img src={checked} alt="" />
                  <div className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D] cursor-pointer">
                    Deposit with Fiat (Local Currency)
                  </div>
                </div>
                <div className="flex items-center gap-3 mt-5 w-full cursor-pointer">
                  <img src={checked} alt="" />
                  <div
                    className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D]"
                    onClick={() => {
                      setSelected(2);
                      // setPaymentMethod("crypto");
                      // setChooseDepositeModal(true);
                      // setModal(false);
                    }}
                  >
                    Deposit with crypto
                  </div>
                </div>

                {/* <button
                  className="text-gray-300 mt-5 w-[200px] self-center rounded p-3 bg-[#85714D] px-5"
                  onClick={() => {
                    setChooseDepositeModal(true);
                    setModal(false);
                  }}
                >
                  Deposit With Paystack
                </button>

                <button
                  className="text-gray-300 mt-5 self-center w-[200px] rounded p-3 bg-[#85714D] px-5"
                 
                  onClick={() => {
                    setPaymentMethod("crypto");
                    setChooseDepositeModal(true);
                    setModal(false);
                  }}
                >
                  {cryptoText}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {modal && selected == 1 && (
        <div className="modal">
          <div className="modal-content  w-[90%]">
            <div className=" bg-black w-[70%] px-5 py-10 ">
              <div className="flex justify-between items-center">
                <h3 className="">Deposit with Fiat (Local Currency)</h3>
                <button
                  className="border border-rose-500 p-1"
                  onClick={() => {
                    setSelected(-1);
                  }}
                >
                  Close
                </button>
              </div>
              <div className="flex flex-col gap-5 w-full">
                <div
                  className="flex items-center gap-3 mt-5 w-full"
                >
                  <div className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D] cursor-pointer">
                    <p className="tracking-wider">Account Number: 0058290590</p>
                    <p className="tracking-wider">Bank Name: Unity Bank</p>
                    <p className="tracking-wider">Account Name: GOC global concept limited</p>
                  </div>
                </div>

                <form>
                    <SignupInput
                      type="text"
                      name="amount"
                      // on_change={handleChange("password")}
                      // onBlur={handleBlur("password")}
                      placeholder="Amount sent"
                    />

                    <SignupInput
                      type="text"
                      name="reference"
                      // on_change={handleChange("password")}
                      // onBlur={handleBlur("password")}
                      placeholder="Reference Number"
                    />

                    <SignupInput
                      type="text"
                      name="reference"
                      // on_change={handleChange("password")}
                      // onBlur={handleBlur("password")}
                      placeholder="Channel"
                      value={"Bank Transfer"}
                    />

<button
                    className="text-gray-300 w-full self-center rounded p-3 bg-[#85714D] px-8"
                    // onClick={handleSubmit}
                  >
                    I've sent the money
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal && selected == 2 && (
        <div className="modal">
          <div className="modal-content  w-[90%]">
            <div className=" bg-black w-[70%] px-5 py-10 ">
              <div className="flex justify-between items-center">
                <h3 className="">Deposit with Crypto</h3>
                <button
                  className="border border-rose-500 p-1"
                  onClick={() => {
                    setSelected(-1);
                  }}
                >
                  Close
                </button>
              </div>
              <div className="flex flex-col gap-5 w-full">
                <div className="flex items-center gap-3 mt-5 w-full">
                  <div className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D] cursor-pointer">
                  <p className="tracking-wider">USDT (BEP20)</p>
                  <p className="tracking-wider">0x0a7957d6f3cda12234b68226f8a33e68adde43c8</p>
                  </div>
                </div>

                <div className="flex items-center gap-3 mt-5 w-full">
                  <div className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D] cursor-pointer">
                  <p className="tracking-wider">USDT (TRC)</p>
                  <p className="tracking-wider">TYzMJ95YimpiG1GFG2amL1svSFqrynhyNJ</p>
                  </div>
                </div>

                <div className="flex items-center gap-3 mt-5 w-full">
                  <div className="border-[2px] p-3 rounded-sm text-sm w-[100%] border-[#85714D] cursor-pointer">
                  <p className="tracking-wider">USDC (Poly)</p>
                  <p className="tracking-wider">0x0a7957d6f3cda12234b68226f8a33e68adde43c8</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
