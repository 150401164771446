import React, { useState } from "react";
import Bronze from "../assets/images/bronze.png";
import Silver from "../assets/images/silver.png";
import Gold from "../assets/images/gold.png";
import { questData } from "../constants/questData";
import ReadMoreLess from "../components/ReadMoreLess/ReadMoreLess";

const Quest = () => {
  return (
    <div className="home-bg-image">
      <div className="">
        <section className="quest flex items-center px-10">
          {/* section one */}
          <div className="py-8  max-w-7xl mx-auto">
            <h1 className="big-display uppercase mb-7">The Journey</h1>

            <h6 className="text-base ">
              Embarking on your investment journey with Omayoza is an exciting
              adventure filled with opportunities for growth and financial
              success. As you navigate the investment landscape, you'll progress
              through three user classes - Bronze, Silver, and Gold - each
              representing a significant milestone in your quest for excellence.
              Let's explore the journey that awaits you:
            </h6>
          </div>
        </section>

        <section className=" max-w-7xl mx-auto flex flex-col gap-10 md:flex-row justify-between px-10 my-16">
          {questData.map((item, index) => {
            return (
              <div className="card py-5 px-5 flex items-center flex-col justify-center text-center" key={index}>
                <h6
                  className="text-base md:text-xl text-center font-semibold"
                  style={{ height: "50px" }}
                >
                  {item.title}
                </h6>
                <img
                  src={item.image}
                  alt=""
                  className="object-cover w-[100%] custom-img"
                />
                <ReadMoreLess
                  key={index}
                  text={item.content}
                  maxCharCount={280}
                />
              </div>
            );
          })}
        </section>

        <section className="max-w-7xl mx-auto px-10">
          <h2 className="font-bold text-[24px]">Are you Ready?</h2>
          <p className="mt-5">
            Throughout your journey, the interest rates you earn on your
            investments will reflect your experience level and the progress
            you've made. With each step forward, you'll witness increased
            potential for growth and greater returns on your investments.
            Embrace the opportunities that await as you ascend from Bronze
            Adventurer to Silver Explorer, and ultimately, to the esteemed
            status of Gold Trailblazer. Are you ready to embark on this
            transformative Journey? Join Omayoza today and unlock the full
            potential of your investments. Let your ambition guide you as you
            progress from bronze to silver and finally to the gold standard of
            investment mastery. We wonder what lies beyond
          </p>
          <p className="my-10">
            Are you ready to embark o this informative journey? Join Omayoza
            today and unlock the full potential of your investments. let your
            ambition guide you as you progress from bronze to silver and finally
            to the gold standard of investment mastery. we wonder what lies
            beyond
          </p>
        </section>
      </div>
    </div>
  );
};

export default Quest;
