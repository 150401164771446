import React, { useEffect, useState } from "react";
import axiosClient from "../../api/axios";
import { useFormik } from "formik";
import * as yup from "yup";
import "../../assets/css/appModal.css";
import SignupInput from "../inputs/signup";
import { useDispatch, useSelector } from "react-redux";
import { preContractAction } from "../../redux/actions/preContract";

import Swal from "sweetalert2";
import { clearPreContrctStatus } from "../../redux/reducers/preContractReducer";
import {
  createContractAction,
  endContractAction,
} from "../../redux/actions/contractsActions";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";
import { clearEndContractStatus } from "../../redux/reducers/contractsReducer";
import { banks } from "../../constants/banks";
import { paymentSignUpAction } from "../../redux/actions/paymentDetailsAction";
import { clearBankignUpStatus } from "../../redux/reducers/paymentDetailsReducer";
import { getProfileAction } from "../../redux/actions/profileAction";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getAllContractAdminAction } from "../../redux/actions/adminAction";

export default function WithdrawPaymentModal({
  modal,
  setModal,
  contractId,
  questLevel,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.paymentDetailsSlice);
  const contractActionSelector = useSelector((state) => state.contract);
  const [listOfBanks, setListOfBanks] = useState([]);

  const navigate = useNavigate();
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const validationSchema = yup.object({
    account_number: yup.string().required("Account Number is required"),
    account_name: yup.string().required("Account Name is required"),
    bank_name: yup.string().required("Bank Name is required"),
  });

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "loading") {
      setLoading(true);
      return;
    }
  }, [authSelector.bankDetailSignUpStatus]);

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "completed") {
      setLoading(false);
      dispatch(
        endContractAction({
          contract_id: contractId,
        })
      );
      return;
    }
    dispatch(clearBankignUpStatus());
  }, [authSelector.bankDetailSignUpStatus]);

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "failed") {
      setLoading(false);
      return;
    }
    dispatch(clearBankignUpStatus());
  }, [authSelector.bankDetailSignUpStatus]);

  useEffect(() => {
    if (contractActionSelector.endContractStatus === "loading") {
      setLoading(true);
      return;
    }
    if (contractActionSelector.endContractStatus === "completed") {
      setLoading(false);
      dispatch(clearEndContractStatus());

      dispatch(getAllContractAdminAction());
      dispatch(getAllStatsDataAction());
      dispatch(getProfile());
      dispatch(getProfileAction());
      setModal(false);
      return;
    }
    if (contractActionSelector.endContractStatus === "failed") {
      setLoading(false);
      dispatch(clearEndContractStatus());
      return;
    }
  }, [contractActionSelector.endContractStatus]);

  const onSubmit = (values) => {
    dispatch(paymentSignUpAction(values));
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        account_number: "",
        account_name: "",
        bank_name: "",
      },
      validationSchema,
      onSubmit,
    });

  return (
    <>
      {loading && <AppModal />}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="bg-[#000] w-[80%] flex flex-col items-center rounded-md content-center p-5">
              <h3 className="deposite-header">ADD PAYMENT DETAILS</h3>

              <p className="text-center text-sm">
                Add an account to complete your withrawal
              </p>

              <div className="flex w-full flex-col">
                <div className="flex  w-full  flex-col">
                  <SignupInput
                    type="number"
                    name="account_number"
                    className="inputWithoutCount"
                    value={values.account_number}
                    on_change={handleChange("account_number")}
                    placeholder="account number"
                  />

                  {touched.account_number && errors.account_number ? (
                    <p className="errorText">
                      {touched.account_number && errors.account_number
                        ? errors.account_number
                        : ""}
                    </p>
                  ) : null}
                </div>

                <div className="flex flex-col">
                  <SignupInput
                    type="text"
                    value={values.account_name}
                    name="account_name"
                    on_change={handleChange}
                    placeholder="account name"
                  />
                  {touched.account_name && errors.account_name ? (
                    <p className="errorText">
                      {touched.account_name && errors.account_name
                        ? errors.account_name
                        : ""}
                    </p>
                  ) : null}
                </div>

                <div className="mt-3">
                  <select
                    name="bank_name"
                    value={values.bank_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full bg-transparent border rounded border-[#85714d] p-4"
                  >
                    <option
                      value=""
                      className="text-gray-800 bg-white hover:bg-gray-200 w-1/3"
                    >
                      Bank Name
                    </option>
                    {banks.map((item, index) => (
                      <option
                        key={index}
                        value={item}
                        className="text-gray-800 bg-white hover:bg-gray-200 w-1/3"
                      >
                        {item}
                      </option>
                    ))}
                  </select>

                  {touched.occupation && errors.bank_name && (
                    <p className="text-red-600 text-sm">{errors.bank_name}</p>
                  )}
                </div>

                <button
                  className="text-gray-300 w-32 self-center mt-5 rounded p-3 bg-[#85714D] px-8"
                  onClick={handleSubmit}
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
