import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/style.css"; //custom styling
import "./assets/css/input.css"; //custom styling
import "./assets/css/tailwind.css"; //tailwind framework
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import App from "./app";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
);
