import React, { useState } from "react";
import AppBtn from "../AppBtn/AppBtn";

const ReadMoreLess = ({ text, maxCharCount }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxCharCount);

  return (
    <div className="flex w-fit items-center justify-center flex-col">
      <p className="text-base mt-2 md:mt-5">{displayText}</p>
      {text.length > maxCharCount && (
        <AppBtn
          onClick={handleToggle}
          className="mt-5"
          title={isExpanded ? "Read Less" : "Read More"}
        />
      )}
    </div>
  );
};

export default ReadMoreLess;
