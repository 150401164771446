import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../api/axios";
const GET_ALL_TIER_AND_QUEST = "user:GET_ALL_TIER_AND_QUEST";

export const getAllCommonDataAction = createAsyncThunk(
  GET_ALL_TIER_AND_QUEST,
  async (args) => {
    const response = await axiosClient().get("common");
    return response.data;
  }
);
