import React from "react";

const ContactUsComponent = () => {
  return (
    <div className="mx-auto py-8   md:px-5 mb-20">
      <div className="mb-4 mt-0 md:mt-0 text-4xl  font-semibold">Contact Us</div>

      <p>We value your feedback, inquiries, and suggestions. Our dedicated support team is here to assist you. Choose your preferred method of contact below and reach out to us. We look forward to hearing from you!</p>
      <ol type="1" className="mb-32">
        <li className="mb-5 flex flex-col mt-5">
          <span>Email:</span>

          <p className="ml-5">
          For general inquiries, feedback, or support, please email us at <a href="mailto:info@omayoza.co" className="text-blue underline">info@omayoza.com</a>
          </p>
        </li>

        <li className="mb-5 flex flex-col">
          <span>Phone:</span>

          <p className="ml-5">
          To speak with a representative directly, you can reach us during business hours at +23400000000
          </p>
        </li>

        <li className="mb-5 flex flex-col">
          <span>Live Chat:</span>

          <p className="ml-5">
          Need immediate assistance? Our live chat feature is available on our website. Simply click on the chat icon in the corner of your screen to connect with a member of our support team.
          </p>
        </li>
        

        <li className="mb-5 flex flex-col">
          <p className="">
          <li className="mt-5">We strive to respond to all inquiries promptly. Please allow up to 24 hours for a response via email or live chat. If you have an urgent matter, we recommend reaching out to us by phone for immediate assistance.</li>
          
          <li className="mt-5">Your satisfaction is our priority, and we're here to ensure your experience with Omayoza is exceptional. Don't hesitate to contact us with any questions or concerns you may have. We're here to help you on your investment journey!</li>

          <li className="mt-5">Thank you for choosing Omayoza.</li>

          </p>
        </li>
      </ol>
    </div>
  );
};

export default ContactUsComponent;
