import React, { useContext, useEffect, useState } from "react";
import logoSvg from "../../assets/svg/logoSvg.svg";
import { toast } from "react-toastify";
import authImage from "../../assets/images/authImage.png";
import { useDispatch, useSelector } from "react-redux";
import { clearRegisterStatus } from "../../redux/reducers/authentication";

import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { requestOtpAction } from "../../redux/actions/otpAction";
import {
  clearOtpData,
  clearRequestOtpStatus,
} from "../../redux/reducers/otpReducer";
import AppModal from "../AppModal/AppModal";
import TermsOfServiceModal from "../AppModal/TermsOfServiceModal";
import AppBtn from "../AppBtn/AppBtn";
import axiosClient from "../../api/axios";

const FinalForm = ({ handleSubmit, setUser, setUserOtp }) => {
  const [realImageFile, setRealImageFile] = useState("");
  const { formDataInfo, setFormDataInfo } = useContext(AuthContext);
  const [loading, setIsLoading] = useState();
  const navigate = useNavigate();
  const [Imagestate, setImageState] = useState({
    valid_id: {},
    selected: "",
    // Add more state variables as needed
  });

  const handleChange = (event) => {
    const fileList = event.target.files;
    const imageURL = URL.createObjectURL(fileList[0]);
    setRealImageFile(event.target.files[0]);

    setImageState((prevState) => ({
      ...prevState,
      valid_id: fileList,
      selected: imageURL,
    }));
  };

  const dispatch = useDispatch();
  const otpSelector = useSelector((state) => state.otpSlice);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "completed") {
      setIsLoading(false);
      navigate("/confirmOtp");
      setFormDataInfo({
        ...formDataInfo,
        doc: realImageFile,
        otp: otpSelector.otpData,
      });
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  useEffect(() => {
    dispatch(clearOtpData());
  }, []);

  useEffect(() => {
    if (otpSelector.requestOtpStatus == "failed") {
      setIsLoading(false);
      toast.error(`${otpSelector.requestOtpError}`);
      return;
    }
    dispatch(clearRequestOtpStatus());
  }, [otpSelector.requestOtpStatus]);

  const handleOtpequest = async () => {
    if (realImageFile == "") {
      toast.error("Select a document please");
      return;
    }
    dispatch(
      requestOtpAction({
        email: formDataInfo.email,
        first_name: formDataInfo.first_name,
      })
    );
  };

  return (
    <>
      <section className="home-bg-image">
        <AppModal modal={loading} />
        <div className="flex flex-col  max-w-[1700px]   mx-auto    md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh] relative hidden md:block">
            <img
              src={authImage}
              alt=""
              className="w-[100%] h-full object-cover"
            />
            <div className="absolute top-0 right-0 left-0 bottom-0 auth-img-bg"></div>
          </div>

          <div className="flex max-w-xl relative mx-auto flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
            <Link
              to="/"
              className="absolute inline-block top-10 left-12 cursor-pointer"
            >
              <img src={logoSvg} />
            </Link>

            <div className="bg-[#85714d37] rounded-md p-5 md:pt-5 pt-16">
              <p className="font-bold text-center lg:text-left text-2xl uppercase">
                Sign Up
              </p>
              <p className="text-[12px] mt-3">To ensure the security and compliance of our platform, we kindly request that you provide us with a snapshot of a valid identification document. This helps us verify your identity and protect against fraudulent activities. Please follow the instructions below to submit your ID snapshot securely:</p>
              <ol className="text-[12px] mt-3">
                  <li><b>Prepare Your ID:</b> Take a high-quality snapshot of your valid identification document. Accepted forms of identification typically include government-issued IDs, such as passports or driver's licenses.</li>
                  <li><b>Capture the Image:</b> Find a well-lit area and position your ID on a flat surface. Make sure all details on the ID are clearly visible and legible. Take a photo of the entire document, ensuring that there are no reflections or obstructions.</li>
                  <li><b>Upload Your ID Snapshot:</b> Using the file upload button below, securely upload the image of your ID. We utilize advanced encryption and security measures to protect your personal information.</li>
                  <li><b>ID Verification:</b> Our dedicated team will review the submitted snapshot to verify your identity. This process typically takes a short period of time. Rest assured that your personal information will be handled with the utmost care and in compliance with relevant privacy laws.</li>
              </ol>
              <p className="text-[12px] mt-3">Please note that the ID snapshot is solely for verification purposes and will not be shared with any unauthorized parties. We maintain strict confidentiality and adhere to data protection protocols.</p>

              <p className="text-sm my-3 text-center">
                Upload Valid ID Card Please
              </p>
              <div className="w-full">
                <div className="flex flex-col gap-4  justify-center items-center">
                  <label
                    htmlFor="valid_id"
                    style={{ cursor: "pointer" }}
                    className="relative block rounded-sm max-w-[200px] mx-auto border-[2px] border-[#85714D] w-full h-[150px]"
                  >
                    <input
                      id="valid_id"
                      name="valid_id"
                      hidden
                      type="file"
                      multiple={false}
                      onChange={handleChange}
                    />

                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="text-gray-500">
                        Click to Upload Image
                      </span>
                    </div>

                    {Imagestate.selected && (
                      <img
                        src={Imagestate.selected}
                        alt="Uploaded"
                        className="absolute inset-0 object-cover w-full h-full"
                      />
                    )}
                  </label>

                  <div className="mt-5 flex items-center">
                    <AppBtn
                      title="Back"
                      className="mobile-btn"
                      onClick={() => navigate("/more-about-you")}
                    />
                    <AppBtn
                      title="Next"
                      className="mobile-btn ml-3"
                      onClick={handleOtpequest}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinalForm;
