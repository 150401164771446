import React, { useEffect, useState } from "react";

import "../../assets/css/appModal.css";
import AppBtn from "../AppBtn/AppBtn";

export default function TermsOfServiceModal({ modal, setModal }) {
  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="bg-[#000] w-[100%]  h-[700px] mt-40 flex flex-col overflow-y-auto items-center rounded-md content-center p-10">
              <h3 className="text-[24px] font-medium">Terms and Conditions</h3>

              <ol type="1">
                <li className="text-[14px] mb-3">
                  1. Risk Disclosure: Forex trading involves a significant level
                  of risk, and the terms and conditions will typically include a
                  risk disclosure statement outlining the potential risks
                  associated with trading and the possibility of losing funds.
                </li>

                <li className="text-[14px] mb-3">
                  2. Account Management and Termination: The terms and
                  conditions will specify the procedures for managing your
                  trading account, including depositing and withdrawing funds,
                  account maintenance, and the process for closing or
                  terminating your account.
                </li>

                <li className="text-[14px] mb-3">
                  3. Privacy and Data Protection: This section outlines the
                  broker's policies regarding the collection, storage, and use
                  of your personal and financial information, as well as any
                  third-party disclosures.
                </li>

                <li className="text-[14px] mb-3">
                  4. Legal and Regulatory Compliance: The terms and conditions
                  will state the jurisdiction governing the agreement, any
                  dispute resolution procedures, and compliance with relevant
                  laws, regulations, and licensing requirements.
                </li>

                <li className="text-[14px] mb-3">
                  {" "}
                  5. Amendments and Updates: The terms and conditions may
                  include provisions that allow the broker to make changes to
                  the agreement or platform features, and it will specify how
                  you will be notified of such changes.
                </li>
              </ol>

              <p className="text-[14px] ">
                Remember, it's essential to carefully read and understand the
                terms and conditions provided by your chosen forex broker or
                platform before engaging in any trading activities. If you have
                any questions or uncertainties, it's advisable to seek
                clarification from the broker's customer support or consult with
                a financial professional.
              </p>

              <div className="flex mt-5 gap-3">
                <AppBtn
                  title="Accept"
                  className=" mobile-btn ml-1"
                  onClick={toggleModal}
                />
                <AppBtn
                  title="Decline"
                  className="btn-secondary"
                  onClick={toggleModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
