import React, { useState } from "react";
import FaqImage from "../assets/images/faq-img.svg";
import Help from "./Help";
import FaqComponent from "../components/FaqComponent/FaqComponent";
import line from "../assets/images/line.png";

const Faqs = () => {
  const [active, setActive] = useState(0);
  return (
    <div className="home-bg-image">
      <div className=" max-w-7xl mx-auto  px-8">
        <div className="mb-4 px-0 flex  md:px-10 pt:0 md:pt-16 cursor-pointer">
          <div
            className="flex flex-col ml-0 md:ml-5"
            onClick={() => setActive(0)}
          >
            <span> Contact Us</span>
            {active === 0 && <img src={line} alt="" className="w-[40px]" />}
          </div>

          <span className="mx-2">/</span>
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => setActive(1)}
          >
            <span> FAQs</span>
            {active === 1 && <img src={line} alt="" className="w-[40px]" />}
          </div>
        </div>

        {/* {active === 0 ? (
          <Help setActive={setActive} active={active} />
        ) : ( */}
          <FaqComponent setActive={setActive} active={active} />
        {/* )} */}
      </div>
    </div>
  );
};

export default Faqs;
