import { createSlice } from "@reduxjs/toolkit";
import { postRegistrationAction } from "../actions/postRegistrationAction";
const initialState = {
  postRegistrationStatus: "idle",
  postRegistrationSuccess: "",
  postRegistrationError: "",
};

const postRegistrationSlice = createSlice({
  name: "postRegistration",
  initialState,
  reducers: {
    clearPostRegistrationStatus(state) {
      state.postRegistrationStatus = "idle";
      state.postRegistrationSuccess = "";
      state.postRegistrationError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(postRegistrationAction.pending, (state) => {
        state.postRegistrationStatus = "loading";
      })
      .addCase(postRegistrationAction.fulfilled, (state, action) => {
        state.postRegistrationStatus = "completed";
      })
      .addCase(postRegistrationAction.rejected, (state, { payload }) => {
        state.postRegistrationStatus = "failed";
        state.postRegistrationError = payload;
      });
  },
});

export const { clearPostRegistrationStatus } = postRegistrationSlice.actions;

export default postRegistrationSlice.reducer;
