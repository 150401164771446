import { createSlice } from "@reduxjs/toolkit";
import {
  getNotificationsAction,
  getInvestmentHistoryAction,
} from "../actions/notificationAction";
const initialState = {
  notificationStatus: "idle",
  notificationSuccess: "",
  notificationError: "",

  investmentHistoryStatus: "idle",
  investmentHistorySuccess: "",
  investmentHistoryError: "",

  notificationData: null,
  investmentHistory: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotificationStatus(state) {
      state.notificationStatus = "idle";
      state.notificationSuccess = "";
      state.notificationError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(getNotificationsAction.pending, (state) => {
        state.notificationStatus = "loading";
      })
      .addCase(getNotificationsAction.fulfilled, (state, action) => {
        state.notificationStatus = "completed";
        // update redux state
        state.notificationData = action?.payload.payload;
      })
      .addCase(getNotificationsAction.rejected, (state, { payload }) => {
        state.notificationStatus = "failed";
        state.notificationError = payload;
      });

    builder
      .addCase(getInvestmentHistoryAction.pending, (state) => {
        state.investmentHistoryStatus = "loading";
      })
      .addCase(getInvestmentHistoryAction.fulfilled, (state, action) => {
        state.investmentHistoryStatus = "completed";
        // update redux state
        state.investmentHistory = action?.payload.payload;
      })
      .addCase(getInvestmentHistoryAction.rejected, (state, { payload }) => {
        state.investmentHistoryStatus = "failed";
        state.investmentHistoryError = payload;
      });
  },
});

export const { clearNotificationStatus } = notificationSlice.actions;

export default notificationSlice.reducer;
