import React, { useEffect, useState } from "react";
import { SignupInput } from "../../components";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { clearBankignUpStatus } from "../../redux/reducers/paymentDetailsReducer";
import { paymentSignUpAction } from "../../redux/actions/paymentDetailsAction";

const OtherInfo2 = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState();
  const authSelector = useSelector((state) => state.paymentDetailsSlice);

  const [state, setState] = useState({
    account_number: "",
    account_name: "",
    bank_name: "",
    bvn: "",
    // Add more state variables as needed
  });

  const initialValues = {
    account_number: "",
    account_name: "",
    bank_name: "",
    bank_code: "",
    bvn: "",
  };

  const validationSchema = yup.object({
    account_number: yup.string().required("Account Number is required"),
    account_name: yup.string().required("Account Name is required"),
    bank_name: yup.string().required("Bank Name is required"),
    bvn: yup.string().required("Bvn Number is required"),
  });

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "loading") {
      setIsLoading(true);
      return;
    }
  }, [authSelector.bankDetailSignUpStatus]);

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "completed") {
      handleSubmit(state);
      setIsLoading(false);
      return;
    }
    dispatch(clearBankignUpStatus());
  }, [authSelector.bankDetailSignUpStatus]);

  useEffect(() => {
    if (authSelector.bankDetailSignUpStatus == "failed") {
      setIsLoading(false);
      return;
    }
    dispatch(clearBankignUpStatus());
  }, [authSelector.bankDetailSignUpStatus]);

  const handleBnkDetailsSubmit = (values) => {
    dispatch(paymentSignUpAction(values));
  };

  return (
    <>
      <section>
        <div className=" flex  max-w-[1700px]   mx-auto  flex-col md:flex-row gap-4 justify-between">
          <div className="h-[20vh] w-full md:w-1/2 md:min-h-[100vh]">
            <img
              src="r101.png"
              alt=""
              className="w-[100%] h-full object-cover"
            />
          </div>
          <div className="flex flex-col items-center gap-3 w-full py-5 md:py-0 md:w-1/2 px-5 md:px-10 justify-center">
            <div className="font-bold text-2xl">Sign Up</div>
            <div className="w-full ">
              <div className="my-4 text-sm font-extralight">
                Payment details
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleBnkDetailsSubmit}
              >
                {({
                  errors,
                  values,
                  touched,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                }) => {
                  return (
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          name="account_number"
                          on_change={handleChange("account_number")}
                          placeholder="account number"
                          className="inputWithoutCount"
                        />

                        {touched.account_number && errors.account_number ? (
                          <p className="errorText">
                            {touched.account_number && errors.account_number
                              ? errors.account_number
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          value={state.password}
                          name="account_name"
                          on_change={handleChange("account_name")}
                          placeholder="account name"
                        />
                        {touched.account_name && errors.account_name ? (
                          <p className="errorText">
                            {touched.account_name && errors.account_name
                              ? errors.account_name
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          name="bank_name"
                          on_change={handleChange("bank_name")}
                          placeholder="bank name"
                        />
                        {touched.bank_name && errors.bank_name ? (
                          <p className="errorText">
                            {touched.bank_name && errors.bank_name
                              ? errors.bank_name
                              : ""}
                          </p>
                        ) : null}
                      </div>

                      <div className="flex flex-col">
                        <SignupInput
                          type="text"
                          name="bvn"
                          on_change={handleChange("bvn")}
                          placeholder="bvn"
                        />
                        <p className="errorText">
                          {touched.bvn && errors.bvn ? errors.bvn : ""}
                        </p>
                      </div>
                      <button
                        className="text-gray-300 w-32 self-center rounded p-3 bg-[#85714D] px-8"
                        onClick={handleSubmit}
                      >
                        Next
                      </button>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherInfo2;
