import React, { useState } from "react";
import SquareRadioInput from "../../inputs/radioButton";
import purpose1 from "../../../assets/images/purpose-1.png";
import purpose2 from "../../../assets/images/purpose-2.png";
import purpose3 from "../../../assets/images/purpose-3.png";
import purpose4 from "../../../assets/images/purpose-4.png";
import checked from "../../../assets/svg/checked.svg";
import unChecked from "../../../assets/svg/unChecked.svg";
import { toast } from "react-toastify";

const Component2 = ({ stepperInfor, setStepperInfo, setActiveStep }) => {
  const [selectedItem, setSelectedItem] = useState(-1);

  const invest_knowledge_data = [
    {
      img: purpose1,
      text: "Saving for a goal",
      selected: false,
    },
    {
      img: purpose2,
      text: "Additional source of income",
      selected: false,
    },
    {
      img: purpose3,
      text: "Future needs",
      selected: false,
    },
    {
      img: purpose4,
      text: "Short term",
      selected: false,
    },
  ];

  const nextScreen = () => {
    if (selectedItem == -1) {
      toast.error(`please select purpose of investing to continue`);
      return;
    }
    setActiveStep(2);
  };

  return (
    <div className="flex flex-col gap-10  mt-5 px-1  md:px-10 pb-40 ">
      <div className="font-bold text-2xl">PURPOSE OF INVESTING</div>

      <div className="bg-[#85714d37] w-[100%] md:w-full pl-4 pb-5 pr-5">
        <p className="mt-8 pl-10 md:ml-8 mb-8">Set a target with Omayoza</p>

        <div className="flex flex-col gap-5  place-items-center">
          {/* <div className="flex flex-col md:flex-row  gap-2 justify-start md:justify-between"> */}
          <div className="flex flex-col md:flex-row gap-0 md:gap-10">
            {invest_knowledge_data.map((e, i) => (
              <div
                onClick={() => {
                  setSelectedItem(i);
                  setStepperInfo({
                    ...stepperInfor,
                    purpose_of_investment: e.text,
                  });
                }}
                key={e.text}
                className="w-full md:w-1/3 flex flex-col md:flex-rol md:flex-col cursor-pointer"
              >
                <img
                  src={e.img}
                  alt={e.img}
                  className="w-[100%]   object-cover h-[160px]"
                />

                <div className="flex  flex-row f ml-2 md:ml-0  mt-5 mb-10">
                  {selectedItem === i ? (
                    <img src={checked} className="w-[24px] h-[24px]" />
                  ) : (
                    <img src={unChecked} className="w-[24px] h-[24px]" />
                  )}

                  <span className="ml-2 item-text text-base ">{e.text}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <button
          onClick={() => setActiveStep(0)}
          className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn"
        >
          Back
        </button>

        <button
          onClick={nextScreen}
          className="text-gray-300 p-2 rounded-sm cursor-pointer ml-9 bg-[#85714D]  action-btn text-center group relative inline-block"
        >
          <span className="inline-block ml-1"> Next</span>
          {/* {selectedItem == -1 && (
            <span className="invisible p-3 whitespace-nowrap group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white  rounded top-full mt-5">
              please select a target with Omayoza to continue
            </span>
          )} */}
        </button>

        {/* <button
          disabled={selectedItem == -1 ? true : false}
          onClick={() => setActiveStep(2)}
          className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn ml-9 group relative inline-block"
        >
          <span> Next</span>
          <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-black text-white p-1 rounded top-full mt-2">
            please select an investment knowledge to continue
          </span>
        </button> */}
      </div>
    </div>
  );
};

export default Component2;
