import React from "react";
import Bronze from "../assets/newImages/opp1.png";
import Realtime from "../assets/images/premium/real-time.jpg"
import MultiplePayment from "../assets/images/premium/multiple-payment.jpg";
import SecurePayment from "../assets/images/premium/secure.jpg";
import Silver from "../assets/newImages/opp2.png";
import Gold from "../assets/newImages/opp3.png";
import RandomImage from "../assets/images/random-img.png";
import { useNavigate } from "react-router-dom";
import AppBtn from "../components/AppBtn/AppBtn";
import homeImg from "../assets/images/homeBg.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home-bg-image  ">
      <div className="max-w-[1700px] w-[100%] mx-auto">
        {/* section one */}
        <section className="px-7 app-home lg:px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto pt-24 md:pt-0 px-0 md:px-4">
            <div data-aos="fade-right">
              <div className="pt-16 mb-8 px-0 ">
                <h2 className="md:text-4xl text-2xl md:mt-0 mt-10 font-bold max-w-[510px]  mb-2 lg:mb-6">
                  Welcome to Omayoza: Your Path to Secure Investments and Returns
                </h2>

                <p className="text-sm max-w-[365px] font-montserrat">
                  Experience the Power of Returns! Sign Up with
                  Omayoza and Transform Your Financial Future!
                </p>

                <AppBtn
                  className="w-[60%] md:w-[40%] mt-5"
                  title="Begin your Journey"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div>
        </section>

        <section
          // data-aos="fade-right"
          className="mx-auto home-content  max-w-7xl px-7 flex flex-col items-center justify-center "
        >
          <h4 className="text-[#85714D] text-2xl font-bold  text-center md:mt-10 md:text-4xl mb-5">
            Investment Opportunities with Peace of Mind
          </h4>
          <p className="text-center md:text-base text-sm">
            Experience the Power of Risk-Free Returns! Sign Up with Omayoza and Transform Your Financial Future!
          </p>
        </section>

        <section className="flex mt-20 md:mt-20 px-5 md:px-10 gap-10 flex-col md:flex-row">
          <div className="card w-full md:w-1/3 py-5 px-5">
            <h6
              className="text-base text-center font-semibold"
              style={{ height: "50px" }}
            >
              Real-Time Insights and Information
            </h6>
            <img
              src={Realtime}
              alt=""
              className="object-cover w-[100%] custom-img"
            />
            <p className="text-base text-center mt-2 md:mt-5">
              Stay informed and make informed investment decisions with our
              real-time insights and information. Our automated investment
              algorithm provides you with valuable investment data, helping you
              enhance your performance and make educated choices. Access our
              intuitive investment dashboard to track your initial capital,
              expected returns, upcoming events, and total earnings - all at
              your fingertips.
            </p>
          </div>

          <div className="card w-full md:w-1/3 py-5 px-5">
            <h6
              className="text-base text-center font-semibold"
              style={{ height: "50px" }}
            >
              Multiple Payment Options for Your Convenience
            </h6>
            <img
              src={MultiplePayment}
              alt=""
              className="object-cover w-[100%] custom-img"
            />
            <p className="text-base text-center mt-2 lg:mt-5">
              We understand that everyone has different payment preferences.
              That's why we offer a range of payment options to cater to your
              needs. Whether you prefer bank transfers, credit/debit card
              payments, digital wallets, or other methods, Omayoza provides
              flexibility, making it easier than ever to manage your
              investments.
            </p>
          </div>

          <div className="card w-full md:w-1/3 py-5 px-5">
            <h6
              className="text-base text-center font-semibold"
              style={{ height: "50px" }}
            >
              Secure and Compliant
            </h6>
            <img
              src={SecurePayment}
              alt=""
              className="object-cover w-[100%] custom-img"
            />
            <p className="text-base mt-2 text-center lg:mt-5">
              Your security and privacy are our top priorities. Omayoza
              implements advanced security measures and adheres to all
              applicable laws and regulations, including anti-money laundering
              (AML) and know your customer (KYC) requirements. Rest assured that
              your personal and financial information is protected at all times.
            </p>
          </div>
        </section>

        <section className=" w-[95%] mx-auto py-8 flex flex-col items-center">
          <h4 className="text-[#85714D]  font-bold  text-center md:mt-10 md:text-4xl mb-5">
            Start Your Quest with Omayoza Today
          </h4>
          <p className="text-center text-base">
            Join thousands of satisfied adventurers who have chosen Omayoza as
            their preferred investment platform. Begin your quest to secure
            investments and risk-free returns by signing up today. Let Omayoza
            empower you to reach new heights in your financial journey while
            enjoying the peace of mind you deserve.
          </p>
          <AppBtn
            className="mobile-btn mt-5 w-fit"
            title="Begin your Journey"
            onClick={() => navigate("/signup")}
          />
        </section>
      </div>
    </div>
  );
};

export default Home;
