import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearNotificationStatus } from "../../redux/reducers/notificationReducer";
import { getNotificationsAction } from "../../redux/actions/notificationAction";

function AdminNotification() {
  useEffect(() => {
    dispatch(getNotificationsAction());
  }, []);

  const { notificationStatus, notificationData } = useSelector(
    (state) => state.notifictionSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationStatus === "loading") {
      return;
    }
    if (notificationStatus === "completed") {
      dispatch(clearNotificationStatus());
      return;
    }
    if (notificationStatus === "failed") {
      dispatch(clearNotificationStatus());
      return;
    }
  }, [notificationStatus]);

  return (
    <div className="mt-10 px-1 md:px-10">
      <h3>NOTIFICATIONS</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
        {notificationData?.map((item, index) => {
          return (
            <div
              className="notificationContainer w-[80%] rounded-sm p-4 cursor-pointer mb-5"
              key={index}
            >
              <div className="flex justify-between items-center">
                <p className="text-[15px]">
                  {item?.usercontract?.status ===
                  "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                    ? "half withdrawal"
                    : item?.usercontract?.status?.toLowerCase()}
                  &#160;{" "}
                  {item?.usercontract?.status ===
                  "ENDED_ABNORMALLY_WAITING_FOR_WITHDRAWAL"
                    ? "notification"
                    : "account verification"}
                </p>
                <p className="text-xs">3:46pm</p>
              </div>
              <div className="borderBottom"></div>
            </div>
          );
        })}

        <div className="notificationContainer rounded-sm h-[50%] w-[100%] flex-1"></div>
      </div>
    </div>
  );
}

export default AdminNotification;
