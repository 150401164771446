import React from "react";
import AppBtn from "../AppBtn/AppBtn";
import { useSelector } from "react-redux";

function LessThan30Days({ withdrawContractSubmit, setModal, setShowBank }) {
  const userContractSelector = useSelector(
    (state) => state.profileSlice.profileData
  );
  return (
    <div style={{ textAlign: "left" }}>
      <p className="mt-5">
        🛑 Hold Your Horses, Valiant Investor! The Quest Is Not Yet Complete! 🛑
      </p>
      <p className="mt-5">
        Hey there, daring explorer! Before you make your move to withdraw your
        funds, we have a captivating revelation to share. If it's been less than
        60 days since the start of your epic quest, we must warn you of the
        challenge that lies ahead. Prepare yourself for the penalty that
        accompanies early withdrawals!
      </p>
      <p className="mt-5">
        ⌛️ Timing is Everything: Remember, great fortunes are forged through
        patience and perseverance. If you choose to withdraw before the 60-day
        mark, a penalty of 10% on your initial investment will befall you. Hold
        onto your treasure a little longer and let it grow into something truly
        extraordinary!
      </p>
      <p className="mt-5">
        🌱 Nurture Your Investment: Like a seedling sprouting into a mighty
        tree, your investments need time to flourish. Embrace the full journey
        of your quest and witness the remarkable growth that awaits. The longer
        you remain on the path, the greater the rewards that will greet you!
      </p>
      <p className="mt-5">
        💪 Embody Tenacity: Summon your inner strength and resilience, dear
        adventurer. Show the world that you're not one to shy away from
        challenges. With each passing day, your investments gain strength and
        momentum, propelling you towards triumphant success!
      </p>
      Now, fellow adventurer,
      <p className="mt-5">
        Now, intrepid investor, choose your course wisely. Will you face the
        challenge head-on and allow your investments to reach their full
        potential, or will you succumb to the temptation of an early withdrawal?
      </p>
      <p className="mt-5">
        Remember, true champions are forged in the fires of patience and
        dedication. The choice is yours to make, valiant trailblazer! ⚔️💰
      </p>
      <div className="flex justify-between md:flex-row flex-col mt-5">
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            Brave adventurer, choose the path of resilience and allow your
            investments to blossom into their full potential. Embrace the spirit
            of the quest and watch your wealth grow with each passing day. Stay
            true to the adventure and reap the rewards that await you at the
            journey's end. Trust in your decision and let your investments
            thrive!
          </p>

          {userContractSelector?.userbank?.acct_name ? (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-10 mb-10"
              onClick={() => {
                withdrawContractSubmit();
                setModal(false);
              }}
            />
          ) : (
            <AppBtn
              title="Forge ahead and conquer"
              className="mt-14 mb-10"
              onClick={() => {
                setShowBank(true);
                setModal(false);
              }}
            />
          )}
        </div>
        <div className="md:w-[40%] w-[100%] flex flex-col items-center mt-6">
          <p className="text-center text-sm">
            If your circumstances demand a swift withdrawal, we understand the
            need for flexibility. While an early withdrawal carries a 30%
            penalty on your initial investment if made within 30 days, we
            respect your choice. Claim your funds and forge a different path,
            knowing that future quests await your return when the time is right.
            Your financial journey is in your hands!
          </p>
          <AppBtn
            onClick={() => setModal(false)}
            title="Seize the moment"
            className="mt-8 mb-10"
          />
        </div>
      </div>
    </div>
  );
}

export default LessThan30Days;
