import React, { useContext, useEffect, useState } from "react";
import SquareRadioInput from "../../inputs/radioButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommonDataAction } from "../../../redux/actions/commonActions";
import checked from "../../../assets/svg/checked.svg";
import unChecked from "../../../assets/svg/unChecked.svg";
import lock from "../../../assets/images/lock.png";
import bronze from "../../../assets/images/premium/bronze.jpg";
import silver from "../../../assets/images/premium/silver.jpg";
import gold from "../../../assets/images/premium/gold.jpg";


import canopy from "../../../assets/images/canopy.png";
import branching from "../../../assets/images/branching.png";
import sapling from "../../../assets/images/sapling.png";

import DepositeModal from "../../AppModal/DepositeModal";
import { getProfileAction } from "../../../redux/actions/profileAction";
import { FaInfo } from "react-icons/fa";
import WoodModal from "../../AppModal/WoodModal";
import ChoosePaymentModal from "../../AppModal/ChoosePaymentModal";
import { AuthContext } from "../../../context/AuthContext";

const QuestLevel = ({ handleChangeComp, data }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItemTier, setSelectedItemTier] = useState(-1);
  const [selectedTierValue, setSelectedTierValue] = useState("");
  const [selectedQuestValue, setSelectedQuestValue] = useState("");
  const [tierId, setTierId] = useState(1);
  const [modal, setModal] = useState(false);
  const [onclickquest, setOnClickQuest] = useState(false)
  // console.log(onclickquest)

  useEffect(() => {
    setTimeout(() =>
    {
      setOnClickQuest(false)
      // console.log(onclickquest, 'setOnClickQuest')
    }, 4000)

  }, [onclickquest, setOnClickQuest])

  const [chooseModal, setChooseModal] = useState(false);
  const [depositeModal, setChooseDepositeModal] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState("paystack");

  const dataSelector = useSelector((state) => state.profileSlice.profileData);
  const [activeQuestLevel, setActiveQuestLevel] = useState(
    dataSelector?.current_quest_level
  );

  const commonSelector = useSelector((state) => state?.commonSlice?.commonData);
  const [modalWood, setModalWood] = useState(dataSelector?.isFirstTime);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCommonDataAction());
  }, []);

  useEffect(() => {
    dispatch(getProfileAction());
  }, []);

  // console.log(commonSelector)

  return (
    <>
      <div className="flex flex-col gap-5  mt-5  dashboardWrapper">
        <div className="font-bold text-2xl">QUEST LEVEL</div>

        <div className="alert_item success_item">
          <div className="icon">
            <FaInfo />
          </div>
          <div className="text">
            <h3>Embark on Your Investment Adventure!</h3>
            <p className="text-xs">
              Choose a Quest Level and Tier to Begin Your Journey.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-5 pb-5">
          <p>
            Congratulations on taking the first step towards unlocking your investment potential! To commence your thrilling adventure, it's time to select a Quest Level and Tier that align with your financial goals and aspirations.
          </p>

          <p>
            Let the quest begin! Select your desired Quest Level and Tier to embark on an unforgettable investment journey filled with opportunities and growth.
          </p>

          <p>
            Happy investing and may your journey be filled with success and prosperity!”
          </p>

          <div className="flex flex-col md:flex-row  gap-4 justify-start md:justify-between">
            {commonSelector?.quest_levels.map((e, i) => (
              <>
                <div
                  key={i}
                  className="w-full md:w-1/3  disable-bg md:flex-rol gap-3"
                  style={{ display: e.id == 0 ? "none" : "block" }}
                  onClick={() => {
                    if (i == 0) {
                      setActiveQuestLevel(i);
                    }
                    if (dataSelector?.current_quest_level == i) {
                      setActiveQuestLevel(i);
                    }
                  }}
                >
                  <div className="relative w-[297px] card-img overflow-hidden">
                    {i === 1 ? (
                      <img
                        src={bronze}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    ) : i === 2 ? (
                      <img
                        src={silver}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    ) : (
                      <img
                        src={gold}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    )}

                    {activeQuestLevel !== e.id && (
                      <img
                        src={lock}
                        className="absolute top-[20%] right-0 left-[35%] w-[103px] h-[103px]"
                      />
                    )}

                    {activeQuestLevel !== e.id && onclickquest === false && (
                      <div className="card-body">
                        <h5 className="text-[10px] font-semibold">
                          Unleash Your Inner Adventurer: Unlock New Heights!
                        </h5>
                        <p className="text-xs">
                          Hovering near uncharted territories? This class is a
                          realm reserved for the most intrepid investors. Take
                          up the challenge, hone your skills, and reach for the
                          stars! Progress awaits those who dare to dream big.
                          Are you ready to embark on an extraordinary journey?
                        </p>
                      </div>
                    )}

                    {activeQuestLevel !== e.id && onclickquest === true && (
                      <div className={`card-body`}>
                        <h5 className="text-[10px] -mt-4 font-semibold">
                         Locked Class: Uncharted Territory Ahead! 
                        </h5>
                        <p className="text-[8px]">
                        Whoa there, explorer! This class is locked, reserved for the true trailblazers. Venture further into the realm of investment mastery, and unlock new frontiers that await you. Sharpen your strategies, build your experience, and embrace the pursuit of greatness. Your journey to greatness begins with unlocking this exclusive class!
                        Step into the unknown and conquer the locked class when you're ready. Prepare for exhilarating challenges and unparalleled rewards!
                        {/* Please note: Mastery comes to those who persevere. Keep pushing the boundaries, and one day, this class shall be yours to conquer. */}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row mt-5 cursor-pointer" onClick={() => setOnClickQuest(!onclickquest)}>
                    {activeQuestLevel == e.id ? (
                      <img src={checked} />
                    ) : (
                      <img src={unChecked} />
                    )}

                    <span className="ml-2 text-base w-3/4">
                      {i === 1 ? (
                        <>
                          {e.title}
                          <span className="pr-3"> Adventurer</span>
                        </>
                      ) : i === 2 ? (
                        <>
                          {e.title}
                          <span className="pr-3"> Explorer</span>
                        </>
                      ) : (
                        <>
                          {e.title}
                          <span className="pr-3"> Trailblazer</span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="font-bold text-2xl mt-10 uppercase">CLASS</div>

          <p>Choose class (Your class determines the duration of your quest)</p>
          <div className="flex flex-col md:flex-row mb-20  gap-4 justify-start md:justify-between">
            {commonSelector?.tier.map((e, i) =>
              i == 3 ? null : (
                <div
                  key={i}
                  className="w-full md:w-1/3   md:flex-rol gap-3 cursor-pointer"
                  onClick={() => {
                    setSelectedItemTier(i);
                    setSelectedTierValue(e.title);
                    setTierId(e.id);
                    // setModal(true);
                    setChooseModal(true);
                    // payNow();
                  }}
                >
                  <div className="relative h-[160px] card-img overflow-hidden w-[297px]">
                    {i === 0 ? (
                      <img
                        src={sapling}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    ) : i === 1 ? (
                      <img
                        src={branching}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    ) : (
                      <img
                        src={canopy}
                        className="w-[100%] object-cover h-[160px]"
                      />
                    )}
                    <div className="card-body">{e.description}</div>
                  </div>

                  <div className="flex flex-row mt-5">
                    {selectedItemTier === i ? (
                      <img src={checked} />
                    ) : (
                      <img src={unChecked} />
                    )}

                    <span className="ml-2 text-base w-3/4">
                      {i === 0 ? (
                        <>
                          <span className="pr-3">Sapling</span>

                          {e.title}
                        </>
                      ) : i === 1 ? (
                        <>
                          <span className="pr-3"> Branching Out</span>

                          {e.title}
                        </>
                      ) : (
                        <>
                          <span className="pr-3">Canopy</span>

                          {e.title}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <DepositeModal
        activeQuestLevel={activeQuestLevel}
        tierId={tierId}
        modal={depositeModal}
        PaymentMethod={PaymentMethod}
        setModal={setChooseDepositeModal}
      />

      <ChoosePaymentModal
        modal={chooseModal}
        setModal={setChooseModal}
        setPaymentMethod={setPaymentMethod}
        PaymentMethod={PaymentMethod}
        setChooseDepositeModal={setChooseDepositeModal}
      />

      <WoodModal
        modalWood={modalWood}
        setModalWood={setModalWood}
        setModal={setModal}
        activeQuestLevel={activeQuestLevel}
        setActiveQuestLevel={setActiveQuestLevel}
      />
    </>
  );
};

export default QuestLevel;
