import { createSlice } from "@reduxjs/toolkit";
import { getAllStatsDataAction } from "../actions/statsActions";
const initialState = {
  statsStatus: "idle",
  statsSuccess: "",
  statsError: "",

  statsData: null,
};

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    clearStatsStatus(state) {
      state.statsStatus = "idle";
      state.statsSuccess = "";
      state.statsError = "";
    },
  },

  extraReducers: (builder) => {
    // ======SIGNING STATUS ACTION=======//
    builder
      .addCase(getAllStatsDataAction.pending, (state) => {
        state.statsStatus = "loading";
      })
      .addCase(getAllStatsDataAction.fulfilled, (state, action) => {
        state.statsStatus = "completed";
        // update redux state
        state.statsData = action?.payload.payload;
      })
      .addCase(getAllStatsDataAction.rejected, (state, { payload }) => {
        state.statsStatus = "failed";
        state.statsError = payload;
      });
  },
});

export const { clearStatsStatus } = statsSlice.actions;

export default statsSlice.reducer;
