import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createContractAction } from "../redux/actions/contractsActions";
import { getAllStatsDataAction } from "../redux/actions/statsActions";
import { getProfileAction } from "../redux/actions/profileAction";
import { useNavigate } from "react-router-dom";

export default function CryptoHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let tnx_ref = window.location.pathname.replace("/crypto");

    dispatch(
      createContractAction({
        tnx_ref: tnx_ref,
      })
    );
    dispatch(getAllStatsDataAction());
    navigate("/");
    dispatch(getProfileAction());
  }, []);
  return <div>processing payment</div>;
}
