import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignupInput from "../../inputs/signup";
import { useDispatch, useSelector } from "react-redux";
import { postRegistrationAction } from "../../../redux/actions/postRegistrationAction";
import AppLoader from "../../AppLoader/AppLoader";
import { clearPostRegistrationStatus } from "../../../redux/reducers/postRegistrationReducer";
import AppModal from "../../AppModal/AppModal";
import { getLoginData } from "../../../redux/reducers/authentication";
import { getProfileAction } from "../../../redux/actions/profileAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import AppWithrawModal from "../../AppModal/AppWithrawModal";
import ChoosePaymentModal from "../../AppModal/ChoosePaymentModal";
import DepositeModal from "../../AppModal/DepositeModal";

const Component4 = ({ stepperInfor, setStepperInfo, setActiveStep }) => {
  const [state, setState] = useState({
    _invest_knowledge_data: [
      "Salary",
      "Inheritance",
      "Investment",
      "Wages",
      "Interest",
    ],
    occupation: [
      "Architect",
      "Engineer",
      "Doctor",
      "Lawyer",
      "Student",
      "Others",
    ],
    company: "",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [tierId, setTierId] = useState(1);

  const dispatch = useDispatch();
  const [depositeModal, setChooseDepositeModal] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState("paystack");

  const postSelector = useSelector((state) => state.postRegistrationSlice);
  const dataSelector = useSelector((state) => state.profileSlice.profileData);

  const [activeQuestLevel, setActiveQuestLevel] = useState(
    dataSelector?.current_quest_level
  );
  const { wood } = useContext(AuthContext);

  const checkWood = () => {
    if (wood) {
      setModal(true);
    }
    return;
  };

  useEffect(() => {
    if (postSelector.postRegistrationStatus === "loading") {
      setLoading(true);
      return;
    }
  }, [postSelector.postRegistrationStatus]);

  useEffect(() => {
    if (postSelector.postRegistrationStatus === "completed") {
      setLoading(false);
      dispatch(clearPostRegistrationStatus());
      dispatch(getProfileAction());

      checkWood();

      return;
    }
  }, [postSelector.postRegistrationStatus]);

  useEffect(() => {
    if (postSelector.postRegistrationStatus === "failed") {
      setLoading(false);
      dispatch(clearPostRegistrationStatus());
      return;
    }
  }, [postSelector.postRegistrationStatus]);

  // console.log(postSelector.postRegistrationStatus);

  const validationSchema = Yup.object({
    annualIncome: Yup.number()
      .positive()
      .integer()
      .required("Net Annual Income is required "),
    sourceOfIncome: Yup.string().required("Source of Income is required"),
    occupation: Yup.string().required("occupation is required"),
  });

  const onSubmit = (values) => {
    dispatch(
      postRegistrationAction({
        ...stepperInfor,
        finance_occupation: values.occupation,
        finance_source_of_income: values.sourceOfIncome,
        finance_net_annual_income: values.annualIncome,
        finance_company: "Empty",
      })
    );
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        annualIncome: "",
        sourceOfIncome: "",
        occupation: "",
      },
      validationSchema,
      onSubmit,
    });

  return (
    <>
      {/* {loading && <AppLoader />} */}
      <AppModal modal={loading} />

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-10 -mt-2 px-1  md:px-10  md:mt-5 md:w-[55%] w-[100%]">
          <div className="font-bold mt-5 text-2xl">FINANCIAL STATUS</div>

          <div className="flex flex-col gap-5 ">
            <select
              name="sourceOfIncome"
              value={values.sourceOfIncome}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full bg-transparent border rounded border-[#85714d] p-4  focus:outline-none "
            >
              <option value="">Select Source of Income</option>

              {state._invest_knowledge_data.map((data, index) => (
                <option
                  key={index}
                  value={data}
                  className="text-gray-800  bg-white hover:bg-gray-200 w-1/3"
                >
                  {data}
                </option>
              ))}
            </select>
            <p className="text-xs">
              Knowing your source of income would help us manage your
              investments better
            </p>

            {touched.sourceOfIncome && errors.sourceOfIncome && (
              <p className="text-red-600 text-sm">{errors.sourceOfIncome}</p>
            )}

            <select
              name="occupation"
              value={values.occupation}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full bg-transparent border rounded border-[#85714d] p-4"
            >
              <option value="">Select Your Occupation</option>
              <option
                value=""
                className="text-gray-800 bg-white hover:bg-gray-200 w-1/3"
              >
                Occupation
              </option>
              {state.occupation.map((data, index) => (
                <option
                  key={index}
                  value={data}
                  className="text-gray-800 bg-white hover:bg-gray-200 w-1/3"
                >
                  {data}
                </option>
              ))}
            </select>
            <p className="text-xs">Tell us what you do for a living</p>

            {touched.occupation && errors.occupation && (
              <p className="text-red-600 text-sm">{errors.occupation}</p>
            )}

            <SignupInput
              value={values.annualIncome}
              on_change={handleChange}
              name="annualIncome"
              onBlur={handleBlur}
              className="-mt-1 inputWithoutCount"
              type="number"
              placeholder="Net annual income"
            />
            <span>
              {errors.annualIncome && touched.annualIncome ? (
                <p className="text-red-600 text-sm">{errors.annualIncome}</p>
              ) : null}
            </span>
          </div>

          <div className="flex flex-row -mt-10">
            <button
              onClick={() => setActiveStep(2)}
              className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn"
            >
              Back
            </button>

            <button
              onClick={onSubmit}
              className="text-gray-300 p-2 rounded-sm bg-[#85714D] action-btn ml-9  w-6/12"
            >
              Start Investing
            </button>
          </div>
        </div>
      </form>

      <DepositeModal
        activeQuestLevel={activeQuestLevel}
        tierId={tierId}
        modal={depositeModal}
        PaymentMethod={PaymentMethod}
        setModal={setChooseDepositeModal}
      />

      <ChoosePaymentModal
        modal={modal}
        setModal={setModal}
        PaymentMethod={PaymentMethod}
        setPaymentMethod={setPaymentMethod}
        setChooseDepositeModal={setChooseDepositeModal}
      />
    </>
  );
};

export default Component4;
