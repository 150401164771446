import { combineReducers } from "@reduxjs/toolkit";
import authenticationSlice from "./authentication";
import secondSignUpSlice from "./secondSignUpReducer";
import paymentDetailsSlice from "./paymentDetailsReducer";
import commonSlice from "./commonReducer";
import postRegistrationSlice from "./postRegistrationReducer";
import preContractSlice from "./preContractReducer";
import profileSlice from "./profileReducer";
import statsSlice from "./statsReducer";
import notifictionSlice from "./notificationReducer";
import adminSlice from "./adminReducer";
import otpSlice from "./otpReducer";
import contract from "./contractsReducer";
export default combineReducers({
  authenticationSlice,
  secondSignUpSlice,
  paymentDetailsSlice,
  commonSlice,
  postRegistrationSlice,
  preContractSlice,
  profileSlice,
  statsSlice,
  notifictionSlice,
  adminSlice,
  otpSlice,
  contract,
});
