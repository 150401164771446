import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import "../../assets/css/appModal.css";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "./AppModal";
import { getAllStatsDataAction } from "../../redux/actions/statsActions";
import { useNavigate } from "react-router-dom";
import { FaCamera, FaVideo } from "react-icons/fa";
import { uploadVideoAction } from "../../redux/actions/adminAction";
import { cleargetAllUploadStatus } from "../../redux/reducers/adminReducer";
import axios from "axios";
import axiosClient from "../../api/axios";

export default function UploadBlogVideoModal({ modal, setModal }) {
  const adminSelector = useSelector((state) => state.adminSlice);
  const [realImageFile, setRealImageFile] = useState("");

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleModal = () => {
    setModal(!modal);
  };

  const [state, setState] = useState({
    valid_id: {},
    selected: "",
    // Add more state variables as needed
  });

  const handleChange = (event) => {
    const fileList = event.target.files;
    const imageURL = URL.createObjectURL(fileList[0]);
    setRealImageFile(event.target.files[0]);

    setState((prevState) => ({
      ...prevState,
      valid_id: fileList,
      selected: imageURL,
    }));
  };

  useEffect(() => {
    if (adminSelector.adminUploadVidoStatus === "loading") {
      setLoading(true);
      return;
    }
    if (adminSelector.adminUploadVidoStatus === "completed") {
      setLoading(false);
      dispatch(cleargetAllUploadStatus());
      setModal(false);
      return;
    }
    if (adminSelector.adminUploadVidoStatus === "failed") {
      dispatch(cleargetAllUploadStatus());
      setLoading(false);
      return;
    }
  }, [adminSelector.adminUploadVidoStatus]);

  const uploadVideoSubmit = () => {
    let formData = new FormData();
    formData.append("doc", realImageFile);

    dispatch(
      uploadVideoAction({
        media: formData,
      })
    );
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const onDrop = async (files) => {
    let formData = new FormData();
    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    formData.append("file", files[0]);

    try {
      const response = await axiosClient().post(`blog`, formData);
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      {loading && <AppModal />}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="bg-[#000] w-[90%] flex flex-col items-center rounded-md content-center p-5">
              <h3>Upload Video to Omayoza Blog</h3>
              {/* <div className="upload-btn-wrapper mt-5">
                <FaVideo className="btnUpload" size={30} />

                <input type="file" name="myfile" onChange={handleChange} />
              </div> */}

              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "1px solid #85714D",
                      borderRadius: "5PX",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <FaVideo className="btnUpload" size={30} />
                    </div>
                  </section>
                )}
              </Dropzone>

              <button
                className="text-gray-300 mt-10 self-center rounded p-3 bg-[#85714D] px-8"
                // onClick={preContractSubmit}
                onClick={uploadVideoSubmit}
                // type="button"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
